import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

function GalleryHeader() {
  const [articleList, setArticleList] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://cms.swaindia.org/api/gallery/list"
        );
        console.log("API gallery response", response.data.data.gallery_list);
        const sortedArticleList = response.data.data.gallery_list.sort(
          (a, b) => {
            const idA = parseInt(a.id);
            const idB = parseInt(b.id);
            return idA - idB;
          }
        );
        console.log(sortedArticleList, "gallery");
        setArticleList(sortedArticleList);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <div>
      <div className="th-menu-wrapper">
        <div className="th-menu-area text-center">
          <button className="th-menu-toggle">
            <i className="fal fa-times"></i>
          </button>
          <div className="mobile-logo">
            <button type="button" className="th-menu-toggle d-block d-lg-none">
              <i className="far fa-bars"></i>
            </button>
          </div>
          <div
            className={`th-mobile-men ${
              menuOpen ? "show" : "d-none"
            } d-lg-inline-block`}
          >
            {articleList.map((article, index) => (
              <ul key={index} style={{ width: "50%" }}>
                <li className="list-name">
                  <a href="/">Home</a>
                </li>
                <li>
                  <Link to={`/gallery/${article.id}`}>{article.title}</Link>
                </li>
              </ul>
            ))}
          </div>
        </div>
      </div>

      <header className="th-header header-layout1">
        <div className="sticky-wrapper">
          <div className="menu-area">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto d-lg-none d-block">
                  <div className="header-logo">
                    <button
                      type="button"
                      className="menu-toggle d-block d-lg-none"
                      style={{
                        backgroundColor: "#FF1D50",
                        border: "none",
                        color: "white",
                      }}
                      onClick={toggleMenu}
                    >
                      {menuOpen ? (
                        <i class="fa-solid fa-x" style={{ color: "white" }}></i>
                      ) : (
                        <i class="fa fa-bars" aria-hidden="true"></i>
                      )}
                    </button>
                  </div>
                </div>
                <div className="col-auto">
                  <div
                    className={`main-menu ${
                      menuOpen ? "show" : "d-none"
                    } d-lg-inline-block`}
                  >
                    <ul>
                      <li>
                        <a href="/" style={{ color: "white" }}>
                          Home
                        </a>
                      </li>
                      <li>
                        <Link
                          to="/view-gallery"
                          style={{
                            color:
                              location.pathname === "/view-gallery"
                                ? "#FF1D50;"
                                : "white",
                          }}
                        >
                          Gallery
                        </Link>
                      </li>
                      {/* {articleList.map((article, index) => (
                        <li key={index}>
                          <Link
                            to={`/gallery/${article.id}`}
                            style={{
                              color:
                                location.pathname.includes(
                                  `/gallery/${article.id}`
                                ) ||
                                location.pathname.includes(
                                  `/galleryDetail/${article.id}`
                                )
                                  ? "#FF1D50"
                                  : "white",
                            }}
                          >
                            {article.title}
                          </Link>
                        </li>
                      ))} */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default GalleryHeader;
