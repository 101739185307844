import React, { useEffect, useState } from 'react'
import MediaSection from './MediaSection'
import SwaFooter from '../Screenwriters/SwaFooter'
import MediaHeader from './MediaHeader'
import SwaNavbar from '../Screenwriters/SwaNavbar'
import MobileNavbar from '../Screenwriters/MobileNavbar'

function Medias() {
    
  const [isMobile, setIsMobile] = useState(() => {
    const initialValue = localStorage.getItem("isMobile");
    return initialValue ? JSON.parse(initialValue) : false;
  });

  useEffect(() => {
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth < 970);
      localStorage.setItem("isMobile", JSON.stringify(window.innerWidth < 970));
    };

    checkScreenWidth();
    window.scrollTo(0, 0);

    window.addEventListener("resize", checkScreenWidth);

    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);
  return (
    <>
    {isMobile ? <MobileNavbar /> : <SwaNavbar />}

    <div style={{ position: "relative", zIndex: 1 }}>
      <MediaHeader />

      <div className="breadcumb-wrapper" >
        <div className="container"  >
          
          <div className="container" >
            <div className="row justify-content-center justify-content-lg-between align-items-center">
              <div className="col-lg-10 text-end" >
                <div className="header-ads" >
                  <p className="mission-statement" >
                   
                  The Media Sub- Committee of the Screenwriters Association (SWA) serves as a vital conduit between writers and the public sphere. Tasked with disseminating the perspectives, demands, and achievements of member screenwriters, the sub- committee utilizes various media channels to amplify their voices and advocate for their rights. Through strategic communication and engagement initiatives, the Media Sub- Committee ensures that the invaluable contributions of screenwriters are recognized and appreciated by a diverse audience, fostering a supportive environment for creative expression within the industry.
                  
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <MediaSection />
    </div>

    <SwaFooter />
  </>
  )
}

export default Medias
