import React, { useState, useEffect, useRef } from "react";
import {
  FaPiggyBank,
  FaHeartbeat,
  FaBookOpen,
  FaQuestionCircle,
  FaHandHoldingUsd,
  FaRegSadTear,
} from "react-icons/fa";
import "./Welfare.css"; // Make sure to create this CSS file for styling
import SwaNavbar from "../Screenwriters/SwaNavbar";
import { Link } from "react-router-dom";

import SwaFooter from "../Screenwriters/SwaFooter";
import MobileNavbar from "../Screenwriters/MobileNavbar";
function Welfare() {
  const [isMobile, setIsMobile] = useState(() => {
    const initialValue = localStorage.getItem("isMobile");
    return initialValue ? JSON.parse(initialValue) : false;
  });

  useEffect(() => {
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth < 970);
      localStorage.setItem("isMobile", JSON.stringify(window.innerWidth < 970));
    };

    checkScreenWidth();
    window.scrollTo(0, 0);

    window.addEventListener("resize", checkScreenWidth);

    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);
  return (
    <>
      {isMobile ? <MobileNavbar /> : <SwaNavbar />}
      {/* <div className="welfare-container border-container mb-cont"> */}
      <div className="breadcumb-wrapper">
        <div className="container">
          <h2 className="headings">Welfare Schemes and Support</h2>
        </div>
      </div>
      <div className="container" style={{ textAlign: "justify" }}>
        <h3 style={{ fontWeight: "bold" }}>WELFARE SCHEMES – I: For Members</h3>

        <ul className="welf-ul">
          <li>I. Pension</li>
          <li>II. Medical Aid Allowance</li>
          <li>III. Educational Allowance</li>
        </ul>
        <p>
          <strong>AUTHORITY:</strong> All Medical, Educational allowances or
          relief to members shall be managed by the Welfare Sub Committee (duly
          appointed by the Executive Committee), based on each application
          supported by relevant paperwork; except in cases where the amount to
          be disbursed exceeds the limit of INR 50,000/. In such exceptional
          cases, the Welfare Sub Committee will seek approval from the EC.
        </p>

        <p>
          This Scheme is exclusively meant for assisting the members of the
          Association who are eligible for such aid as per the rules of this
          Scheme. No member can avail of the Medical Aid scheme for his/her
          spouse or dependents. However, Education Aid can be availed by the
          Regular Member for her/his ward, as per the rules.
        </p>

        <h2>ELIGIBILITY:</h2>
        <p>
          Members who satisfy any of the following conditions can Apply for
          Medical or Educational Help:
        </p>
        <ul>
          <li>
            A Regular or Life member whose membership duration is a minimum of
            five (5) years.
          </li>
          <li>A Regular member whose subscription is not in arrears.</li>
          <li>
            In the case of Pension, members of age 70 and above only can apply
            along with:
            <ul>
              <li>Their bank statement of past one year.</li>
              <li>Family details.</li>
              <li>Self-attested Affidavit Form provided by SWA.</li>
              <li>
                Proof of having written a minimum of 2 Films or 50 hours of
                Television or 10 hours of Digital Content or 5 songs.
              </li>
            </ul>
            All Pension applications will be subject to scrutiny by SWA.
          </li>
        </ul>

        <h3>DISBURSEMENT:</h3>
        <p>
          All allowances or relief under Medical and Educational help shall be
          made by cheque in the name of the relevant health/educational
          institution. Except in special cases of medical help towards medicines
          and pension. No Cash will be disbursed under any circumstances.
        </p>

        <p>
          In the case of Members applying for Medical Help, the applicant must
          give an undertaking that s/he does not hold any health insurance
          policy. Once Medical Aid is received the member will have to submit
          relevant copies of the original bills.
        </p>

        <p>
          In case of Members applying for Medical Help, for an amount above Rs
          25,000/- The Welfare Sub Committee can seek further proof to determine
          the financial inability of the member. Medical help towards medicines
          will be provided only in case of a life-threatening disease or major
          ailments. Further, such help will be provided for a maximum of 6
          months.
        </p>

        <h4>Educational Help towards Member’s wards:</h4>
        <ul>
          <li>
            Educational Help up to Higher Secondary - Rs. 10,000/- or 50% of the
            fees, whichever is less.
          </li>
          <li>
            Educational Help for Higher Education up to Graduation – Rs 25,000/-
            or 50% of the fees, whichever is less.
          </li>
          <li>
            Educational Help for Higher Education (Technical & Medical) - Rs.
            50,000/- or 50% of the fees, whichever is less.
          </li>
          <li>
            Every application will have to be supported with a copy of the
            member’s past one-year bank statement. Also, the ward’s previous
            school/college mark sheets need to be submitted.
          </li>
          <li>
            A member cannot seek Educational Help in two consecutive years.
          </li>
          <li>
            The Welfare Sub Committee holds discretionary power for exceptional
            cases.
          </li>
        </ul>

        <p>
          In the unfortunate circumstance of a member’s death (Regular and Life
          only) Rs 5000/- will be given to their next of kin upon receiving an
          application for the same.
        </p>

        <p>
          All applications are subject to consideration by the SWA Welfare
          Sub-Committee that holds the right of rejection. The subcommittee can
          seek further documents for individual scrutiny. SWA has the right to
          further scrutinize any application at any time for verification.
        </p>

        <p>
          For any further query on welfare schemes, please contact{" "}
          <a href="mailto:contact@swaindia.org">contact@swaindia.org</a>.
        </p>

        <div className="scheme-section">
          <FaQuestionCircle className="icon eligibility" />
          <h3>Eligibility Criteria</h3>
          <ul className="welf-ul">
            <li>Membership of at least 5 years.</li>
            <li>No subscription arrears.</li>
            <li>Special conditions apply for pension eligibility.</li>
          </ul>
        </div>

        <p className="contact-info">
          For any further queries on welfare schemes, please contact{" "}
          <a href="mailto:contact@swaindia.org">contact@swaindia.org</a>.
        </p>
      </div>
      {/* </div> */}
      <div class="mbc-container">
        <Link to="/welfare">
          <button class="nice-blue-button scroll-button">
            {" "}
            <i
              class="fa-solid fa-house"
              style={{
                color: " #ffffff",
                marginLeft: "0rem",
                marginRight: "1rem",
              }}
            >
              {" "}
            </i>
            Back to Home
          </button>
        </Link>
      </div>
      <SwaFooter />
    </>
  );
}

export default Welfare;
