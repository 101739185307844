import React, { useState, useEffect, useRef } from "react";
import {
  FaPiggyBank,
  FaHeartbeat,
  FaBookOpen,
  FaQuestionCircle,
  FaHandHoldingUsd,
  FaRegSadTear,
} from "react-icons/fa";
import "./Welfare.css"; // Make sure to create this CSS file for styling
import SwaNavbar from "../Screenwriters/SwaNavbar";
import { Link } from "react-router-dom";

import SwaFooter from "../Screenwriters/SwaFooter";
import MobileNavbar from "../Screenwriters/MobileNavbar";
function Scholarship() {
  const [isMobile, setIsMobile] = useState(() => {
    const initialValue = localStorage.getItem("isMobile");
    return initialValue ? JSON.parse(initialValue) : false;
  });
  useEffect(() => {
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth < 970);
      localStorage.setItem("isMobile", JSON.stringify(window.innerWidth < 970));
    };

    checkScreenWidth();
    window.scrollTo(0, 0);

    window.addEventListener("resize", checkScreenWidth);

    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);
  return (
    <>
      {isMobile ? <MobileNavbar /> : <SwaNavbar />}
      {/* <div className="welfare-container border-container mb-cont"> */}
      <div className="breadcumb-wrapper">
        <div className="container">
          <h2 className="headings">Welfare Schemes and Support</h2>
        </div>
      </div>
      <div className="container" style={{ textAlign: "justify" }}>
        <h3 style={{ fontWeight: "bold" }}>
          WELFARE SCHEMES II: SWA SCHOLARSHIPS FOR THE STUDENTS OF PG DIPLOMA IN
          SCREENWRITING COURSE AT FTII
        </h3>

        {/* <h3 style={{ fontWeight: "bold" }}>Scholarship Schemes</h3> */}
        <div>
          <h4>A) PROPOSAL:</h4>
          <p>
            (i) Two scholarships of INR 1,00,000/- (Rupees One Lakh only) each
            shall be awarded to the students of the PG Screenwriting Course at
            FTII every year.
          </p>
          <p>
            (ii) The amount of the scholarship can be reviewed and increased to
            factor in the inflation every five (5) years with EC’s approval.
          </p>
          <p>
            (iii) Selection criteria will include both - the merit and the
            economic situation of the student.
          </p>
        </div>

        <div>
          <h4>B) PARTICULARS:</h4>
          <p>
            (i) The two scholarships will be awarded to the students of the PG
            Course in Screenwriting at FTII as follows:
          </p>
          <ul>
            <li>
              (a) SWAध्याय: ONE scholarship of Rs. 1 lac for a student enrolled
              in the 2nd Semester.
            </li>
            <li>
              (b) SWAभिमान: ONE Scholarship of Rs. 1 lac for a student enrolled
              in the 4th Semester.
            </li>
          </ul>
          <p>
            (ii) SWA will allocate a separate fund of 2 lakh rupees towards the
            said scholarship every year.
          </p>
          <p>
            (iii) The final decision will be that of the Education
            Sub-Committee, who shall make the selection in consultation with the
            faculty of the Screenplay Writing Course of FTII.
          </p>
        </div>

        <div>
          <h4>C) APPLICATION & SELECTION PROCESS:</h4>
          <p>
            (i) Initiation: Each year, the Education Subcommittee of SWA will
            invite Applications from students in the 2nd and 4th semesters of PG
            Course in Screenwriting at the FTII, at the beginning of the
            respective semesters. Since the academic calendar at FTII tends to
            change from year to year, the Screenplay Writing Dept may write to
            SWA once the results of 1st and 3rd semesters are declared.
            Accordingly, the process may be initiated by the SWA office.
          </p>
          <p>
            (ii) Application & Selection Process shall be in accordance with the
            Bye-Laws in force at the relevant time.
          </p>
        </div>

        {/* New Scholarship Content Ends Here */}

        <p className="contact-info">
          For any further queries on welfare schemes, please contact{" "}
          <a href="mailto:contact@swaindia.org">contact@swaindia.org</a>.
        </p>
      </div>
      {/* </div> */}
      <div class="mbc-container">
        <Link to="/welfare">
          <button class="nice-blue-button scroll-button">
            {" "}
            <i
              class="fa-solid fa-house"
              style={{
                color: " #ffffff",
                marginLeft: "0rem",
                marginRight: "1rem",
              }}
            >
              {" "}
            </i>
            Back to Home
          </button>
        </Link>
      </div>
      <SwaFooter />
    </>
  );
}

export default Scholarship;
