import React, { useEffect, useState } from "react";
import "./Education.css";
import SwaNavbar from "../Screenwriters/SwaNavbar";
import SwaFooter from "../Screenwriters/SwaFooter";

import MobileNavbar from "../Screenwriters/MobileNavbar";
import EducationHeader from "./EducationHeader";
import EducationSection from "./EducationSection";

function EducationThreeColumn() {
  const [activeTab, setActiveTab] = useState("previousWorkshops");

  const [isMobile, setIsMobile] = useState(() => {
    const initialValue = localStorage.getItem("isMobile");
    return initialValue ? JSON.parse(initialValue) : false;
  });

  // Mobile device size set
  useEffect(() => {
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth < 970);
      localStorage.setItem("isMobile", JSON.stringify(window.innerWidth < 970));
    };

    checkScreenWidth();
    window.scrollTo(0, 0);

    window.addEventListener("resize", checkScreenWidth);

    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);

  return (
    <>
      {isMobile ? <MobileNavbar /> : <SwaNavbar />}

      <div style={{ position: "relative", zIndex: 1 }}>
        {/* Education page Header */}
        <EducationHeader />

        <div className="breadcumb-wrapper">
          <div className="container">
            <div className="container">
              <div className="row justify-content-center justify-content-lg-between align-items-center">
                <div className="col-lg-12 text-end">
                  <div className="header-ads">
                    {/* Education intro paragraph */}
                    <p className="mission-statement">
                      SWA's Education Sub-Committee works towards fulfilling
                      SWA's continuous effort to encourage and educate emerging
                      writers and help them to improve their craft. This
                      committee organises various workshops for new writers to
                      hone their skills. It has also been organising one of the
                      most successful event, SWA Script Lab and Pitch Fest that
                      provides mentoring and pitching opportunity to
                      screenwriters. Additionally, this Sub-committee grants
                      Scholarships for the students of the Post-Graduate Diploma
                      in Screenwriting Course at Film & Television Institute of
                      India, Pune of INR 1,00,000/- Rupees each to the students
                      of 1st year and 2nd year students of the Institute every
                      year based on the merit and economic status of the
                      Students.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Education page header when Go to - Education */}
        <EducationSection />
      </div>

      <SwaFooter />
    </>
  );
}

export default EducationThreeColumn;
