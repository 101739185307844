import "./App.css";
// import Homepage from './Components/Homepage/Home';
// import Workpage from './Components/Workpage/Workpage';
import Indexpage from "./Components/Indexpage/Index";
// import Aboutpage from './Components/Aboutpage/About';
// import Page1 from './Components/Workpage/Page1';
// import Page2 from './Components/Workpage/Page2';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScreenWriters from "./Components/Screenwriters/Screenwriters";
import Event from "./Components/Screenwriters/Events";
import AboutSwa from "./Components/AboutSwa/AboutSwa";
import FAQ from "./Components/Faq/Faq";
import Downloads from "./Components/Downloads/Dowloads";
import ContactPage from "./Components/Contact/Contact";
import ArchiveData from "./Components/ArchiveData/ArchiveData";
import ExecutiveCommittee from "./Components/AboutSwa/ExecutiveCommittee/ExcecutiveCommittee";
import HistorySwa from "./Components/AboutSwa/HistorySwa/HistorySwa";
import MembershipRules from "./Components/Membership/MenbershipRules";
import ByeLaws from "./Components/ByeLaws/ByeLaws";
import AskLawyer from "./Components/AskOurLawyer/AskLawyer";
import MBC from "./Components/MBC/MBC";
import Appointment from "./Components/Appointment/Appointment";
import DSC from "./Components/DSC/DSC";
import WritersCharter from "./Components/Footer/WriterCharacter";
import DosAndDontsPage from "./Components/Footer/DoDont";
import TermsAndConditions from "./Components/Footer/Terms";
import Members from "./Components/AboutSwa/ExecutiveCommittee/Members";
import SubCommittee from "./Components/AboutSwa/ExecutiveCommittee/SubCommittee";
import Refund from "./Components/Footer/Refund";
import Policy from "./Components/Footer/PrivacyPolicy";
import Welfare from "./Components/Screenwriters/Welfare";
import Welfarem from "./Components/Screenwriters/Welfarem";
import Scholarship from "./Components/Screenwriters/Scholarship";
import LegalAid from "./Components/Screenwriters/LegalAid";
import Blog from "./Components/Screenwriters/Blog";
//  import Education from './Components/Education/Education';
import EducationThreeColumn from "./Components/Education/EducationThreeColumn";
import EducationDetail from "./Components/Education/EducationDetail";

import ArchivePage from "./Components/ArchiveData/ArchivePage";
import Media from "./Components/Media/Media";
// import Workshop from "./Components/Education/Workshop";

// import Awards from "./Components/Screenwriters/Awards";
import Awards from "./Components/Awards/Awards";

import Medias from "./Components/Media/Medias";
import AwardsDetails from "./Components/Awards/AwardsDetails";
import MediaDetail from "./Components/Media/MediaDetail";
import ViewGallery from "./Components/ViewGallery/ViewGallery";
import GalleryDetail from "./Components/ViewGallery/GalleryDetail";
import AwardsBlogs from "./Components/Awards/AwardsBlogs";
import EducationBlog from "./Components/Education/EducationBlog";
import MediaBlogs from "./Components/Media/MediaBlogs";
import Events from "./Components/Events/Events";
import EventsDetails from "./Components/Events/EventsDetails";
import EventsBlog from "./Components/Events/EventsBlog";
import GalleryBlog from "./Components/ViewGallery/GalleryBlog";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/mbc" element={<MBC />} />
          <Route path="/dsc" element={<DSC />} />
          <Route path="/awards" element={<Awards />} />

          <Route path="/view-gallery" element={<ViewGallery />} />
          <Route path="/galleryDetail/:id" element={<GalleryDetail />} />
          <Route path="/gallery/:id" element={<GalleryBlog />} />
          <Route path="/award-subDetail/:id" element={<AwardsBlogs />} />
          <Route path="/awardsDetail/:id" element={<AwardsDetails />} />
          <Route path="/welfare" element={<Welfarem />} />
          <Route path="/scholarship" element={<Scholarship />} />
          <Route path="/legal-aid" element={<LegalAid />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/events" element={<Events />} />
          <Route path="/eventDetail/:id" element={<EventsDetails />} />
          <Route path="/event-subDetail/:id" element={<EventsBlog />} />
          <Route path="/educationDetail/:id" element={<EducationDetail />} />
          <Route path="/edicatiion-subDetail/:id" element={<EducationBlog />} />
          <Route path="/education" element={<EducationThreeColumn />} />

          {/* <Route path="/media" element={<Media />} /> */}
          <Route path="/media" element={<Medias />} />
          <Route path="/mediaDetail/:id" element={<MediaDetail />} />
          <Route path="/media-subDetail/:id" element={<MediaBlogs />} />
          {/* <Route path="/workshops" element={<Workshop />} /> */}

          <Route path="/dodont" element={<DosAndDontsPage />} />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/members" element={<Members />} />
          <Route path="/refund" element={<Refund />} />
          <Route path="/policy" element={<Policy />} />
          <Route path="/welfare-scheme" element={<Welfare />} />

          <Route path="/writercharacter" element={<WritersCharter />} />
          <Route path="/Indexpage" element={<Indexpage />} />
          <Route path="/appointment" element={<Appointment />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/askourlawyer" element={<AskLawyer />} />
          <Route path="/byelaws" element={<ByeLaws />} />
          <Route path="/" element={<ScreenWriters />} />
          <Route path="/mission" element={<AboutSwa />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/downloads" element={<Downloads />} />
          <Route path="/archive" element={<ArchiveData />} />
          <Route path="/archivepage/:id" element={<ArchivePage />} />

          <Route path="/executiveCommittee" element={<ExecutiveCommittee />} />
          <Route path="/subCommittee" element={<SubCommittee />} />

          <Route path="/history" element={<HistorySwa />} />
          <Route path="/membershiprules" element={<MembershipRules />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
export default App;
