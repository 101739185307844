import React, { useState, useEffect } from "react";
import { Input, Card, Space, Dropdown, Menu } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { EnglishLang } from "../AboutSwa/Translation/English";
import { HindiLang } from "../AboutSwa/Translation/Hindi";
// import "./ScreenWriters.css";
import layer88 from "../../img/Layer 88.png";
import layer86 from "../../img/Layer 86.png";
import layer87 from "../../img/Layer 87.png";
import layer100 from "../../img/Layer 100.png";
import logo from "../../img/logo.png";
import myAccount from "../../img/myaccount.png";
import "./Swares.css";
import { FaInstagram, FaYoutube, FaTwitter, FaBloggerB } from "react-icons/fa";
import TranslateIcon from "./Translate";

const { Search } = Input;

const SwaNavbar = () => {
  const [selectedCard, setSelectedCard] = useState(null);

  const menuItems1 = [
    {
      key: "1",
      title: "Apply Online",
      link: "https://swaindia.org/swa_account/register",
    },
    { key: "2", title: "Membership Rules", link: "/membershiprules" },
    { key: "3", title: "FAQ", link: "/faq" },
  ];

  const menuItems2 = [
    {
      key: "9",
      title: "My Account",
      link: "https://swaindia.org/swa_account/login",
    },
    { key: "10", title: "FAQ", link: "/faq" },
    // { key: "11", title: "Option 6" },
  ];

  const menuItems3 = [
    { key: "11", title: "MBC", link: "/mbc" },
    { key: "12", title: "DSC", link: "/dsc" },
    { key: "13", title: "Legal FAQs", link: "/faq" },
    { key: "14", title: "Blogs", link: "/blog" },
    { key: "15", title: "Legal Aid", link: "/legal-aid" },
    {
      key: "16",
      title: "Legal Aid Undertaking Form",
      link: "https://cms.swaindia.org/storage/uploads/Legal%20aid%20undertaking%20form.pdf",
    },
    {
      key: "17",
      title: "Book Appointment",
      // link: "https://nexzenworks.in/swa_account/login",
    },
  ];

  const menuItems4 = [
    {
      key: "5",
      title: (
        <span style={{ fontSize: "16px", fontFamily: "sans-serif" }}>
          Our Mission
        </span>
      ),
      link: "/mission",
    },
    {
      key: "6",
      title: (
        <span style={{ fontSize: "16px", fontFamily: "sans-serif" }}>
          Our Constitution
        </span>
      ),
      link: "https://cms.swaindia.org/storage/uploads/CONSTITUTION%202019.pdf",
    },
    {
      key: "7",
      title: (
        <span style={{ fontSize: "16px", fontFamily: "sans-serif" }}>
          SWA Bye laws
        </span>
      ),
      link: "https://cms.swaindia.org/storage/uploads/SWA%20Bye%20laws%20-2022.pdf",
    },
    {
      key: "8",
      title: (
        <span style={{ fontSize: "16px", fontFamily: "sans-serif" }}>
          People of SWA
        </span>
      ),
      submenuKey: "submenu-3",
    },
    {
      key: "9",
      title: (
        <span style={{ fontSize: "16px", fontFamily: "sans-serif" }}>
          History At SWA
        </span>
      ),
      link: "/history",
    },
  ];

  const submenus = [
    {
      key: "submenu-3",
      title: "Submenu",
      items: [
        { key: "7-1", title: "Executive Committee", link: "/members" },
        { key: "7-2", title: "Sub Committee", link: "/subCommittee" },
        { key: "7-3", title: "Office Team", link: "/executiveCommittee" },
      ],
    },
  ];
  const renderMenu = (menuItems) => (
    <Menu>
      {menuItems.map((menuItem) => {
        if (menuItem.submenuKey) {
          const submenu = submenus.find(
            (submenu) => submenu.key === menuItem.submenuKey
          );
          return (
            <Menu.SubMenu
              key={menuItem.key}
              title={menuItem.title}
              className="submenu-item"
            >
              {submenu.items.map((subItem) => (
                <Menu.Item key={subItem.key} className="submenu-item">
                  {subItem.link ? (
                    subItem.link.startsWith("http") ? (
                      <a
                        href={subItem.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="a submenu-link"
                      >
                        {subItem.title}
                      </a>
                    ) : (
                      <Link to={subItem.link} className="a submenu-link">
                        {subItem.title}
                      </Link>
                    )
                  ) : (
                    subItem.title
                  )}
                </Menu.Item>
              ))}
            </Menu.SubMenu>
          );
        } else {
          return (
            <Menu.Item key={menuItem.key} className="submenu-item">
              {menuItem.link ? (
                menuItem.link.startsWith("http") ? (
                  <a
                    href={menuItem.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="a submenu-link"
                  >
                    {menuItem.title}
                  </a>
                ) : (
                  <Link to={menuItem.link} className="a submenu-link">
                    {menuItem.title}
                  </Link>
                )
              ) : (
                menuItem.title
              )}
            </Menu.Item>
          );
        }
      })}
    </Menu>
  );

  const handleCardClick = (cardNumber) => {
    setSelectedCard(cardNumber === selectedCard ? null : cardNumber);
  };

  return (
    <>
      <div className="blue__navbar">
        <div className="container d-flex justify-content-between align-items-center">
          <div className="col">{/* Left side space */}</div>

          <div className="col">{/* Centered content */}</div>

          <div className="col d-flex justify-content-end align-items-center">
            {/* <Search
              placeholder="Search"
              onSearch={(value) => console.log(value)}
              prefix={<SearchOutlined />}
              style={{
                width: "293px",
                height: "35px",
                marginTop: "0.6rem",
                borderStartStartRadius: "50px"
              }}
            /> */}
            <div
              className="social-icons"
              style={{
                marginLeft: "1rem",
                marginRight: "1rem",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Link to="/media-subDetail/2">
                <button class="news_button">NEWS & UPDATES</button>
              </Link>
              <a
                href="https://www.facebook.com/swaindiaorg"
                target="_blank"
                rel="noopener noreferrer"
                style={{ margin: "0 10px" }}
              >
                <img
                  src={require("../../img/fb-removebg-preview(1).png")}
                  alt="Facebook"
                  style={{ width: "40px", height: "40px" }}
                />
              </a>
              <a
                href="https://www.youtube.com/screenwritersassociation"
                target="_blank"
                rel="noopener noreferrer"
                style={{ margin: "0 10px" }}
              >
                <img
                  src={require("../../img/youtube-removebg-preview.png")}
                  alt="YouTube"
                  style={{ width: "30px", height: "30px" }}
                />
              </a>
              <a
                href="https://www.instagram.com/swaindiaorg/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ margin: "0 10px" }}
              >
                <img
                  src={require("../../img/Untitled-removebg-preview.png")}
                  alt="Blog"
                  style={{ width: "30px", height: "30px" }}
                />
              </a>
              <a
                href="https://twitter.com/i/flow/login?redirect_after_login=%2Fswaindiaorg"
                target="_blank"
                rel="noopener noreferrer"
                style={{ margin: "0 10px" }}
              >
                <img
                  src={require("../../img/icons8-twitter-50.png")}
                  alt="Twitter"
                  style={{ width: "30px", height: "30px" }}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        className="sticky_navbar"
        style={{ display: "flex", alignItems: "center" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-1">
              <div className="logo" style={{ height: "6px", width: "3rem" }}>
                <Link to="/">
                  <div className="img-div">
                    <img src={logo} className="nav-img" alt="Logo" />
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-md-9 swaNavbar">
              <div
                className="d-flex bo-box swa_menu"
                style={{ flexWrap: "wrap" }}
              >
                {/* Card-1 Start */}
                <Dropdown
                  key={1}
                  overlay={renderMenu(menuItems1)}
                  placement="bottomCenter"
                  visible={selectedCard === 1}
                  onVisibleChange={(visible) =>
                    setSelectedCard(visible ? 1 : null)
                  }
                >
                  <Card
                    className={`${
                      selectedCard === 1 ? "selected-card" : ""
                    } card-hover-effect`}
                    onClick={() => handleCardClick(1)}
                    style={{
                      height: "7.5rem",
                      borderRadius: 5,
                      border: "1px solid #d3a221",
                      marginRight: "1rem",
                      cursor: "pointer",
                      width: "19.8rem",
                    }}
                  >
                    <Space align="start">
                      <img src={layer88} alt={`Layer 1`} />
                      <Space direction="vertical">
                        <Card.Meta
                          title={
                            <div className="meta_title">Become a Member</div>
                          }
                          description={
                            <div className="meta_description">
                              {EnglishLang.navbar_link_sub_head_1}
                            </div>
                          }
                        />
                      </Space>
                    </Space>
                  </Card>
                </Dropdown>
                {/* Card-1 end */}

                {/* Card-2 Start */}
                <Dropdown
                  key={1}
                  overlay={renderMenu(menuItems2)}
                  placement="bottomCenter"
                  visible={selectedCard === 2}
                  onVisibleChange={(visible) =>
                    setSelectedCard(visible ? 2 : null)
                  }
                >
                  <Card
                    className={`${
                      selectedCard === 2 ? "selected-card" : ""
                    } card-hover-effect `}
                    onClick={() => handleCardClick(2)}
                    style={{
                      height: "7.5rem",
                      borderRadius: 5,
                      border: "1px solid #d3a221",
                      marginRight: "1rem",
                      cursor: "pointer",
                      width: "19.8rem",
                    }}
                  >
                    <Space align="start">
                      <img src={layer86} alt={`Layer 2`} />
                      <Space direction="vertical">
                        <Card.Meta
                          title={
                            <div className="meta_title">Register Your Work</div>
                          }
                          description={
                            <div className="meta_description">
                              Submit Your Best
                            </div>
                          }
                        />
                      </Space>
                    </Space>
                  </Card>
                </Dropdown>
                {/* Card-2 End */}

                {/* Card-3 Start */}
                <Dropdown
                  key={1}
                  overlay={renderMenu(menuItems3)}
                  placement="bottomCenter"
                  visible={selectedCard === 3}
                  onVisibleChange={(visible) =>
                    setSelectedCard(visible ? 3 : null)
                  }
                >
                  <Card
                    className={`${
                      selectedCard === 3 ? "selected-card" : ""
                    } card-hover-effect  swa_card`}
                    onClick={() => handleCardClick(3)}
                    style={{
                      height: "7.5rem",
                      borderRadius: 5,
                      border: "1px solid #d3a221",
                      marginRight: "1rem",
                      cursor: "pointer",
                      width: "19.8rem",
                    }}
                  >
                    <Space align="start" style={{ marginLeft: "0rem" }}>
                      <img src={layer87} alt={`Layer 3`} />
                      <Space direction="vertical">
                        <Card.Meta
                          title={<div className="meta_title">Legal Help</div>}
                          description={
                            <div className="meta_description">
                              Advice For You
                            </div>
                          }
                        />
                      </Space>
                    </Space>
                  </Card>
                </Dropdown>
                {/* Card-3 End */}

                {/* Card-4 Start */}
                <Dropdown
                  key={1}
                  overlay={renderMenu(menuItems4)}
                  placement="bottomCenter"
                  visible={selectedCard === 4}
                  onVisibleChange={(visible) =>
                    setSelectedCard(visible ? 4 : null)
                  }
                >
                  <Card
                    className={`${
                      selectedCard === 4 ? "selected-card" : ""
                    } card-hover-effect swa_card`}
                    onClick={() => handleCardClick(4)}
                    style={{
                      height: "7.5rem",
                      borderRadius: 5,
                      border: "1px solid #d3a221",
                      marginRight: "1rem",
                      cursor: "pointer",
                      width: "19.8rem",
                    }}
                  >
                    <Space align="start" style={{ marginLeft: "0rem" }}>
                      <img src={layer100} alt={`Layer 4`} />
                      <Space direction="vertical">
                        <Card.Meta
                          title={<div className="meta_title">About Us</div>}
                          description={
                            <div className="meta_description">Know Us</div>
                          }
                        />
                      </Space>
                    </Space>
                  </Card>
                </Dropdown>
                {/* Card-4 End */}
              </div>
            </div>

            {/* My Account Button Start */}
            <div className="col-md-2 my_account swa_account">
              <div className="account">
                <a
                  href="https://swaindia.org/swa_account/login"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={myAccount} alt="My Account" />
                </a>
              </div>
            </div>
            {/* My Account Button End */}
          </div>
        </div>
      </div>
    </>
  );
};

export default SwaNavbar;
