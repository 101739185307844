import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import layer103 from "../../img/Layer 103.png";
import FRT1 from "../../img/F RT 1.jpg";
import HomePage from "../../img/HOME PAGE UPDATES office.jpeg";
import scripLab from "../../img/ScriptLab&Pitchfest_winners.jpg";
import pic3 from "../../img/3.jpg";
import pic4 from "../../img/4.jpg";
import pic5 from "../../img/5.jpg";
import pic6 from "../../img/6.jpg";
import pic7 from "../../img/7.jpg";
import pic8 from "../../img/8.jpg";
import pic9 from "../../img/9.jpg";

import pic11 from "../../img/11.jpg";
import pic12 from "../../img/12.jpg";
import pic13 from "../../img/13.jpg";

import "./ScreenWriters.css";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import * as FeatherIcons from "react-icons/fi";
console.log(FeatherIcons, "FeatherIcons");

function SwaPhotoGallery() {
  const [currentImage, setCurrentImage] = useState(0);
  const images = [
    layer103,
    FRT1,
    HomePage,
    scripLab,
    pic11,
    pic12,
    pic13,
    pic3,
    pic4,
    pic5,
    pic6,
    pic7,
    pic8,
    pic9,
  ];

  // Slide transition interval and delay
  const slideInterval = 5000; // Interval between slides in milliseconds
  const slideDelay = 2000; // Delay before transitioning to the next slide in milliseconds

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImage((prevImage) =>
        prevImage === images.length - 1 ? 0 : prevImage + 1
      );
    }, slideInterval);

    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, [images.length, slideInterval]);

  useEffect(() => {
    const delayId = setTimeout(() => {
      setCurrentImage((prevImage) =>
        prevImage === images.length - 1 ? 0 : prevImage + 1
      );
    }, slideDelay);

    return () => clearTimeout(delayId); // Clear delay timeout on component unmount or image change
  }, [currentImage, images.length, slideDelay]);

  const handlePrevClick = () => {
    setCurrentImage((prevImage) => (prevImage === 0 ? 0 : prevImage - 1));
  };

  const handleNextClick = () => {
    setCurrentImage((prevImage) =>
      prevImage === images.length - 1 ? prevImage : prevImage + 1
    );
  };

  return (
    <>
      {/* Photo Gallery Start */}
      <div className="photo_gallery">
        <div className="box">
          <p>
            Photo Gallery
            {/* <p style={{textAlign:"center"}}>Photo Gallery</p> */}
            <span>
              <SlArrowLeft
                style={{
                  marginRight: "2rem",
                  cursor: "pointer",
                  color: "#d3a221",
                  opacity: currentImage === 0 ? 0.3 : 1,
                }}
                onClick={handlePrevClick}
              />
              <SlArrowRight
                className="arrowright"
                style={{
                  cursor: "pointer",
                  color: "#d3a221",
                  opacity: currentImage === images.length - 1 ? 0.3 : 1,
                }}
                onClick={handleNextClick}
              />
            </span>
          </p>
          {/* 2px golden border added here */}
          <img src={images[currentImage]} alt="error_image" />
          <div class="mbc-container" style={{marginBottom:'0.5rem',marginTop:'0.5rem'}}>
            <Link to="/view-gallery">
              <button class="nice-blue-button scroll-button">View more</button>
            </Link>
          </div>
        </div>
      </div>

      {/* Photo Gallery End */}
    </>
  );
}

export default SwaPhotoGallery;
