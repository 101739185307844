import React, { useState, useEffect } from "react";
import SwaNavbar from "../../Screenwriters/SwaNavbar";
import MobileNavbar from "../../Screenwriters/MobileNavbar";
import SwaFooter from "../../Screenwriters/SwaFooter";
import "./HistorySwa.css"; // Link to your CSS file
import { Pagination } from "antd"; // Assuming you're using Ant Design
import ProfileImage from "../../../img/kp.jpg";

function HistorySwa() {
  const [isMobile, setIsMobile] = useState(() => {
    const initialValue = localStorage.getItem("isMobile");
    return initialValue ? JSON.parse(initialValue) : false;
  });
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 1; // Assuming you want to show one article per page for simplicity

  // Placeholder data for articles

  let content_h2 =
    "On 29th May, 1954 another meeting of writers was held at Shree Sound Studios. Nearly 80 writers had enrolled as members by then and appeal was made to all film writers to join the association.24 writers were present at the meeting. The constitution of the association was adopted in the general body meeting held thereafter. The membership enrolment drive had been a success. On formation of the association it was in the fitness of things that one of its main initiators was elected as its General Secretary. There was no post of President or Chairperson as the constitution did not have any provision for the post (s). Mr. Vishwamitra Adil and Mr. C.L. Kavish were elected as Jt. Secretaries while Pt. Sudarshan became the Treasurer.The council of members included stalwarts such as M/s K.A. Abbas, D.N. Madhok, P.L. Santoshi, Mehesh Kaul, I.S. Johar, Rajinder Singh Bedi, Majrooh Sultanpuri, Sahir Ludhianvi, Shailendra, V.P. Sathe, Shakeel Badayuni, Krishna Chandra, Kamal Amrohi, Rajendra Krishna, Ali Reza, and Nabendu Ghosh.";
  let content_h2_ =
    " Though the Constitution was adapted in 1956, Film Writers’ Association, Bombay got registered as a trade union under the Trade Union Act 1926 with Registration No.3726 only on 13.05. 1960. ";
  let content_h3 =
    " The association also took the initiative to convince other organizations/unions to interact with one another on regular basis and more important focused on the need to have a Central Organization of all workers organizations. Mr. C. L. Kavish, Convener of the Liaison Committee in his communication dated 18th August 1954 to Presidents of other organizations drew their attention to the need of regular interaction between members of the Liaison Committee and at least two representatives of associations to explore the possibility of forming a central organization. All associations complied and sent two representatives to a meeting held on 6th December 1954 at the office of Film Writers Association, Famous Cine Studios. It was in this meeting that a decision to have a Film Workers Federation was taken. A committee was formed to draft a constitution. The same was drafted but whether the proposed Federation functioned or not is not established. However it can be said that it was precursor to the formation of Federation of Western India Cine Employees which was formed a few years later.";
  let content_h3_ =
    " It should also be mentioned here the records available with the Association of Film & TV Editors are at variance with those of Film Writers Association. The idea of having a parent body of film employees to ensure co-ordination and co-operation amongst various craft unions finds mention in the minutes of the Executive Committee of the union held on September 17, 1954. The proposal to have a Federation of all craft unions found enough support and it was decided to take it to next level with convening of an Extra Ordinary General Body meeting that took place on December 12, 1954. The meeting took note of progress made in this respect and decided to await receipt of draft constitution of the proposed federation. Mr. Krishna Gopal, affectionately addressed as KG by all was the prime mover of its formation and in the year 1956 the Federation of Western India Cine Employees was formed. Further in the meeting held on February 26, 1956, presided over by Mr. Goverdhanbhai Patel, an eminent cinematographer and special effects wizard Mr. Shivaji Avdhut announced amidst cheers the registration of the Editors union under the Indian Trade Union Act, 1926. It was in this meeting that the constitution of Federation of Western India Cine Employees was adopted. In all fairness credit for initiating the formation of a Central body should go to both the associations.";
  let content_h3__ =
    " Members of Writers union were divided on the question of registering the body under the Trade Union Act. In the meeting of the Organization Committee held on 15th May, 1955, this matter was taken up for discussion. However it took five years to get it registered under the said Act. This decision was taken in the General Body meeting held on 26th February, 1960. M/s K.A. Abbas, Qamar Jalalabadi, Sahir Ludhianvi, Shashi Bhushan, Vrajendra Gaur, C.L. Kavish, and S.R. Basar were authorized to complete all the formalities for registration. Application for registration was submitted on 17th March, 1960 and the Registrar of Trade Unions registered the union on 13th May, 1960. It is interesting to note that the association was affiliated with Federation of Western India Cine Employees earlier that it has been registered as a trade union. ";
  //  article2
  let content = `The late Pt. Girish went on record to state that Dr. Safdar Aah was the founder and father of the Film Writers Association. However Mr Abbas opined that it was the dynamic initiative of Mr Ramanand Sagar who shot to literary fame when he wrote the Urdu novel "Aur Insaan Mar Gaya" that ultimately worked. During his struggle he happened to ask Mr Abbas if he had any future in the industry. "I don’t think it is an industry. But you have certainly got a future in it. You might even help organize it as an industry", Mr Abbas told Mr Sagar. A year later, Mr Sagar arrived in Bombay and began his endless round of studios to gain foothold in an industry where the struggle for securing the dignity and rights of the writers had still to begin. It is recorded that Mr Sagar took the initiative to form the Film Writers’ Association in 1954. `;

  let con_1 = `Retracing the history of the association Pt. Girish in commemorative volume published on the occasion of celebration of Silver Jubilee of the association in 1980 mentions in his article titled FWA-Growth & Development that during the initial period the association’s office was at Famous Studio, Mahalaxmi. The office shifted to a small room adjoining the Masjid (Mosque) near Roop Tara Studios in Dadar. Thereafter it shifted to Shree Sound Studios and finally to Ranjit Studios. He further mentions that Dr. Safdar Aah was helped in the task of association- building by Mr. Ramanand Sagar. Thereafter it was Qamar Jalalabadi who shouldered the responsibility of General Secretary Ship for several years. Other office bearers changed from to time but Mr. Jalalabadi continued to remain General Secretary for several years.`;

  let con2 = `The official record available with the associated mentions that it was mainly through the efforts of Mr. Ramanand Sagar and Mr. C.L. Kavish that it was revived after couple of years. Amongst those who helped in its revival were M/s G.D. Mudgulkar, Pt. Sudarshan, Shailendra, Krishna Chandra, and Mahendra Nath. It is interesting to note that Mr. C.L. Kavish prevailed up on Mahendra Sing Bedi and Mr. Z. D. Lari to give a donation of Rs.25/- (Rupees twenty five) to issue postal invitation to all the writers to attend a meeting convened to revive the association. Some of the not eworthy invitees were M/s Sahir Ludhianvi, Sarshar Sailani, Dhruv Chatterji, Kaif Irfani, Manohar Singh Sehrai, Shahid Lateef, Ismat Chughtai, Inder Raj Anand, Balkrishan Mauj, and Pt. Mukh Ram Sharma amongst many others.`;

  let con3 = `Parts of early years of existence of the association with focus on issues relating to writers and initiatives launched are available in several issues of Screen of that period. They were primarily news items. The appointment of a committee consisting of M/s Sahir Ludhianvi, Narendra Sharma, and Vishwa Mitra Adil to organize and conduct Mushairas for collecting funds for the association finds mention in news item section of Screen. However officially as per the official hand-out available it was towards the end of the sixties when Mr. Sahir Ludhianvi was the President, that the first program was held to raise funds for the association. About Rs.15,000/- (Rupees fifteen thousand only) were collected and it was only thereafter that the financial association began to improve. Another news item contains information about formation of a committee to prepare and finalize a plan for giving awards. It consisted of M/s K.A. Abbas, Ramanand Sagar, Krishna Chandra, Hasrat Jaipuri, Shailendra, Indivar, and C.L. Kavish. A reference to convening of meeting by Film Writers Association held on Saturday, May 29, 1954 is mentioned in Screen in its issue dated 24th May, 1954. Invitations were sent o Story, Script, Song and Dialogue writers of all languages of the Bombay Film Industry. `;
  const para = `Abbas Tyrewala, the writer of acclaimed and box office hit Munnabhai and critically acclaimed Maqbool advocated complete and unilateral commitment to Intellectual Copyright. "We should make it illegal for people to buy writers right to script", he opined during a conference organized by the association. He suggested that in order to fix a reasonable remuneration for scriptwriters a fee should be fixed as a percentage of the films budget.`;
  const para1 = `It is yet to be seen whether the concerned craft unions are able to join hands together to fight for implementation of amendments. As of now no official press release or reaction has been handed out or published. If no concrete action is taken by unions they will be losing a golden opportunity to enforce all the benefits that will accrue to their members by way of amendments. They will no longer be able to accuse the Government of apathy and indifference.`;
  const para2 = `Mr. Swanand Kirkire, the Bollywood lyricist who wrote the songs of the biggest box office hits of the year 3 Idiots and much acclaimed Mr. Bachchan’s film Paa amongst others, concurs with Mr. Akhtar in the same report by stating, “Today, there are multiple revenue streams available for commercial exploitation. There are ringtones and other avenues. Much more money is made than it was earlier. When the money is there, what is the problem in paying us? It is high time we got paid and the interests of the people, protected”. However, when the tabloid approached the law firm DSK Legal which is reported to have been roped in by the anti-amendment lobby for consultation, the firm’s Managing Partner Mr. Anang Desai is reported to have said, "There is hardly any independent music production happening in India. Almost all the music produced here is merely part of movies in which producers invest".`;
  const IssuesList = ({ issues }) => (
    <div>
      <h3>Issues at Stake</h3>
      <ul>
        {issues.map((issue) => (
          <li key={issue.id}>{issue.text}</li>
        ))}
      </ul>
    </div>
  );

  const articles = [
    {
      title: "The Beginning",
      content:
        "It amuses me no end and in fact thrills me that back in 1950 when most of us were not even born and a few of us like me were still in our nappies, on a Sunday afternoon in the humble Matunga flat of music director Anil Biswas during an open house of their weekly gathering of Cultural and Literary meeting, K.A.Abbas, Ramanand Sagar, Dr.Safdar Shah, Mahesh Kaul, Narendra Sharma, Chnadrashekhar, Madhusudan, P.N.Rangeen and Amritlal Nagar, the seed of Film Writers’ Association, Bombay was planted. What is important to note here is the fact that the names associated with the birth of Film Writers’ Association more or less defined its DNA—social concern, progressive outlook, literary mindset, high professional standards, awareness of a writers’ fundamental rights and creative excellence in whatever a writer and lyricist is expected to deliver.",
      h_1: "Back Story",
      h_1_content:
        "Till the early fifties most of the craftsmen and technicians worked with studios. There were no freelancers. In fact they were considered as permanent employees of the studios. The need to have a trade union body was never felt.It was only when Producer Mr Chimanlal Trivedi started contract system in his Production Company, cracks developed in the relationship between the Writers and Directors who were considered as two most important two wheels on which the industry moved. The change in system led to dispute between the Producers and Directors on one hand and between Directors and Writers on the other. Apparently the Director could no longer choose a Writer of his choice. The same held true for Writers. They now felt the need to have their own trade union bodies.",
      h_2: "Adoption of Constitution",
      h2_date: "29th May 1954",
      h_2_content: `${content_h2}`,
      h_2_content_: `${content_h2_}`,
      h_3: "Precursor to formation of Federation of Western India Cine Employees",
      h_3_content: `${content_h3}`,
      h_3_content_: `${content_h3_}`,
      content_h3__: `${content_h3__}`,
    },

    {
      title: "Enrolling members",
      SUB_HEAD: "Studio to Studio",
      sub_head:
        "It is interesting to quote the late Mr K.A. Abbas has recorded that",
      quote: `Someone got forms printed in the ‘thaila’ (bag) of Qamar Jalalabadi, who during his professional work as song-cum-dialogue-writer, went from Studio to Studio enrolling members. For one or two years the association remained not in the womb, but in a bag which Qamar Sahib was carrying with him.`,
      content: `${content}`,
      content_: `As per the official version provided by the association it was towards the end of 1950’s that a meeting of the film writer’s was held in Shree Sound Studios to form a film writers association. In that meeting Mr D.N. Madhok was elected as the President, Mr. Mahesh Kaul and Pt. Sudarshan were elected as Vice Presidents. Mr. Madhusudan was elected as the General Secretary and Mr. Shakeel Badayuni as Treasurer. Others who formed part of the Executive Committee were M/s Kamal Amrohi, Dr. Safdar Aah, Narendra Sharma, Inder Raj Anand, Arjun Deo Rashk, and Ramanand Sagar. A Sub-Committee consisting of Mr. Mahesh Kaul (Chairperson), Mr. Madhusudan and Mr. S. Banaji were set up to draft the constitution of the association.`,
      content_2: `An account was opened with Punjab National Bank. Since the association had no office of its own, finding appropriate venue for meetings was a big concern. Generally meetings were held at Kardar Studios. The constitution was drafted and approved.",h_1_content:"An account was opened with Punjab National Bank. Since the association had no office of its own, finding appropriate venue for meetings was a big concern. Generally meetings were held at Kardar Studios. The constitution was drafted and approved.`,
      h_2_content: `Ironically despite the efforts and commitment the association became defunct after a year or two. That the association did exist in 1951 is corroborated by news item published in Screen in its issue of November 9, 1951. The issue was published immediately after the release of Film Enquiry Committee Report. It is important to reproduce the said news item which stated- "The Film Writers Association, Bombay, has passed a resolution deploring the lack of representation for Screen writers on the proposed Film Council. It asked the Central Government to provide adequate representation to them". After this no written record about the existence of the association is available.`,
      head1: "Growth & Development",
      sub: "The initial years",
      h_2_content: `${con_1}`,
      h_2_content_: `${con2}`,
      h_3_content_: `Parts of early years of existence of the association with focus on issues relating to writers and initiatives launched are available in several issues of Screen of that period. They were primarily news items. The appointment of a committee consisting of M/s Sahir Ludhianvi, Narendra Sharma, and Vishwa Mitra Adil to organize and conduct Mushairas for collecting funds for the association finds mention in news item section of Screen. However officially as per the official hand-out available it was towards the end of the sixties when Mr. Sahir Ludhianvi was the President, that the first program was held to raise funds for the association. About Rs.15,000/- (Rupees fifteen thousand only) were collected and it was only thereafter that the financial association began to improve. Another news item contains information about formation of a committee to prepare and finalize a plan for giving awards. It consisted of M/s K.A. Abbas, Ramanand Sagar, Krishna Chandra, Hasrat Jaipuri, Shailendra, Indivar, and C.L. Kavish. A reference to convening of meeting by Film Writers Association held on Saturday, May 29, 1954 is mentioned in Screen in its issue dated 24th May, 1954. Invitations were sent o Story, Script, Song and Dialogue writers of all languages of the Bombay Film Industry. `,
    },

    {
      title: "Issues at stake",
      SUB_HEAD:
        "A few pertinent issues/matters which the association took up immediately on its formation can be categorized as under:-",
      content: IssuesList,
      issues: [
        {
          id: 1,
          text: "To institute 20 Annual Awards-five for stories, five for screen plays, five for songs and five for dialogues.",
        },
        {
          id: 2,
          text: "Arrange informal meetings for interaction between prominent technicians of various crafts to debate on problems affecting them.",
        },
        {
          id: 3,
          text: "To fight for dignity and rights of writers and to secure representation on National and International organizations and develop fraternal relations with them.",
        },
        {
          id: 4,
          text: "To seek representation of writers on panels/committees instituted by Government to debate on cinema and issues connected with it.",
        },
        {
          id: 5,
          text: "To set up branches of the association in Poona (Pune) and Kolhapur.",
        },
        {
          id: 6,
          text: "To submit memorandum to Government to enact comprehensive copyright law for safeguarding the rights of writers.",
        },
        {
          id: 7,
          text: "A committee set up to formulate four types of standard contracts. One for the person who writes the story, second for the screen play and the third for dialogue writer and the fourth for lyricist.",
        },
        {
          id: 8,
          text: "To have a Members Assignment Register to maintain details of registration of current assignments of members. A drafting committee consisting of M/s Arjun Dev Rashk (Convener), I.S. Johar, Inder Raj Anand, Mehesh Kaul, Vishwamitra Adil, and Madhusudan as members was set up.",
        },
        {
          id: 9,
          text: "Formation of Dispute Settlement Committee to settle disputes between writers and producers and also between writers. (The committee started functioning from 11th July 1954 and had settled two dispute matters by 6th August 1954).",
        },
        {
          id: 10,
          text: "A Liaison committee to organize and settle various functions assigned to different committees constituted by the association.",
        },
        // ... add other points as needed
      ],
      h_1: "Minimum Wages & Standard Contracts",
      h_2_content: `It is interesting to note that Mr. Mahesh Kaul sought the approval of the general body for fixing the minimum wage level for writers in their contracts with producers. A debate on the issue resulted in inclusion of this in the standard contract form.`,
      h_2_content_: `As reported in Screen, in its issue of 26th November 1954, the association also formed Film Writers Club to provide recreational facilities for writers. Jogeshwari Caves was the first venue for a picnic organized on Christmas.`,
      c_1: `In the annual general body meeting held on 15th May, 1955 and presided over by Pt. Sudarshan an organizing committee was formed. It had M/s K.A. Abbas, Mahesh Kaul, Majrooh Sultanpuri, Pt. Sudarshan, J.C. Solanki, Balraj Mehta, C.L. Kavish, K.B. Pathak, P.N. Rangin, and V.S. Shastri. No record of amended constitution is available with the office now. The posts of President and two Vice Presidents were created as a result of the amendment. Although names of office bearers for the years 1955-56 and 1956-57 are not available in official records but a printed letter head of the association of the said period shows that in the yea 1958-59 Mr. K.A. Abbas was the President while M/s Kamal Amrohi and G.D. Mudgulkar were the Vice Presidents of the association. The first serious efforts to make standard contracts mandatory was made by the association when Mr. K.A. Abbas, President in his letter dated 3rd January 1961 addressed to IMPPA (Indian Motion Picture Producers Association)fixed the minimum wages as under:`,
      minimum_wages: [
        {
          category: "Story",
          amount: "Rs.2, 000/-",
          description: "Rupees two thousand only",
        },
        {
          category: "Screenplay",
          amount: "Rs.2,500/-",
          description: "Rupees two thousand five hundred",
        },
        {
          category: "Dialogue",
          amount: "Rs.3,000/-",
          description: "Rupees three thousand only",
        },
        {
          category: "Songs",
          amount: "Rs. 500/-",
          description: "Rupees five hundred only",
        },
      ],
      c1_: `Keeping in view the ever increasing budgets, price index, standard of living the rates have been revised from time to time. The first such revision came into force in August 1962 after protracted struggle and efforts by writers and the association.`,
      h_3: "Registration",
      slog: "Stories, Screenplays, Songs",
      h_3_content: `By late sixties the association undertook the task of registering stories, screenplays, songs etc. The first recorded reference to such registration is contained in the minutes of the Managing Committee meeting held on 11th June, 1968. It was felt that registration would protect the rights of writers in case of any dispute. The registration, with the passage of time, is one activity undertaken by the association that has withstood the test of time and continues to draw hordes of writers, professional as well as amateurs.`,
      h4: "The first and only initiative",
      slog1: "Housing for members",
      h_3_content_: `At it’s meeting held on 10th February, 1977, the Managing Committee resolved to form Film Writers Cooperative Housing Society. It authorized Mr. Vishnu Mehrortra and Mr. Ghafil Harnalvi to operate a bank account for this purpose. In respect of providing shelter to its members belonging to the lower income group, the association can take credit for not only approaching the Government of Maharashtra during 1975/76 but succeeding in getting tenements for members in the suburb of Kandivali.`,
    },

    {
      title: "Authors Information",
      SUB_HEAD: "The FIRST Periodical",
      content: `'Authors Information', the first periodical published by the association to provide a platform to writers to air their views as well as their problems, began its short lived journey on 17th May, 1976. The first issue was edited by Mr. Anil Kavish but from the second issue Mr. Madhusudan took over as Editor.Unfortunately it was discontinued. Although in the intervening period, the need for a mouthpiece as well as spokesman for writers has been acutely felt, no revival of its publication has taken place till to date.In it’s meeting held on 27th April, 1977 it was decided to approach Filmfare for giving awards to best story and best lyric writer as well. Except for one exception year i.e. 1988, the year the association selected the best story, the Association’s efforts did not yield any positive results.`,
      h_1: "Library",
      h_1_content:
        "Right at the time of its formation in 1954, the association felt the need to have a library and approached all the members for donation. Wali Saheb was the first to contribute Rs.201/- (Rupees two hundred one only) and with his contribution the Library Fund was established. Over the years the association has regularly upgraded its library.",
      hcontent_: "Admission fee: Rs. 5000/- (for all categories)",
      h_2: "Annual Subscription:",
      membershipFees: [
        { type: "Associate & Fellow members", amount: "Rs. 72/-" },
        { type: "Regular Member", amount: "Rs. 120/-" },
        { type: "Life Membership", amount: "Rs. 10,000/-" },
      ],
      h_3: "Welfare measures:",
      fundsAndSchemes: [
        {
          id: 1,
          text: "Paravarik Sahaetya Kosh (Family Welfare Fund)",
        },
        {
          id: 2,
          text: "Hrishikesh Mukherjee Educational Fund for Bedi Rahi Scholarships (Education Fund)",
        },
        {
          id: 3,
          text: "Emergency Relief Fund (Medical Assistance)",
        },
        {
          id: 4,
          text: "Pension Scheme",
        },
      ],
      head: "1980-The Landmark Year",
      slog: "Silver Jubilee celebrations",
      h_3_content: `1980 was a landmark year in the history of the association. It was the Silver Jubilee year and a commemorative volume was published. The Silver Jubilee Celebration Committee, in it’s meeting held on 12th November, 1979 decided to honour distinguished writers each year. It wanted to implement the provision in the constitution drafted in the year 1954 for giving 20 awards in various categories. M/s Benoy Chatterji, D.N. Madhok, Dr. Safdar Aah, Kidar Sharma, Pt. Mathur, Pt. Narottam Vyas, Pragji Dossa, Rajinder Singh Bedi, Vishram Bedekar, and Wajahat Mirza were selected for their dedicated and distinguished contribution in the evolution of art of film writing. From then on at least three prominent writers are being honoured annually in the General Body meeting. Such members are presented with a trophy, shawl, and a citation. From 1990-91 onwards, a token amount of Rs.1,101/-(Rupees eleven hundred only)has been added to the above three as humble token of appreciation.`,
      h4: "Copyright",
      slog1: "Issues at stake",
      h_3_content_: `It is a well-known fact the writers of story, screenplay, and lyrics are not entitled to have copyright on their creation. They are deprived of their share of monetary gains. Yet we have not witnessed sustained agitation to get these rights. Of late the World Intellectual Property Rights Organization (WIPO) and the WIPO Copyright Treaty to which India is a signatory has made our HRD (Human Resources Development) Union Ministry to propose amendment in the Copyright Act 1957 which is likely to give Writers, Directors, Music Directors and Lyricists control and ownership over copyright. It is reported in 14th February 2010 Sunday edition of Mid-Day, a leading tabloid of Mumbai, that “producers have allegedly been quick to take legal help to protect their turfs and ensure that there is no amendment to the present law. It further states that “Predictably, writers and lyricists seem to be elated”. Quoting veteran and much acclaimed writer and lyricist Mr. Javed Akhtar, it conveys the dismal scenario that leads to writers, lyricists, and composers being highly underpaid. “If you see their contracts, you’ll realize that they are as good as bonded labourers. Even the moral rights, which are not transferable, are taken away from them. This is a situation of total exploitation and repression”, Mr. Akhtar is quoted to have said.`,
      content_h3__: `${para2}`,
      c31_: `${para}`,
      c32_: `${para1}`,
    },
    {
      title: "Conference & Workshops",
      SUB_HEAD: "Re defining the role of a writer for films & television",
      content: `Acclaimed writers mingled with those who aspired to become one on the occasion of conference organized at the Film Institute of India, Pune by the association. The assembly of leading lights deliberated on various issues plaguing the scriptwriters. A few pertinent points that emerged from the deliberations could be summed up as under:`,
      opinions: [
        {
          id: 1,
          text: "A screenplay of a film would be better if writers didn’t write what is acceptable to audience. They should write about what they believe in.",
        },
        {
          id: 2,
          text: "Make Producers break with the stranglehold of star system",
        },
        {
          id: 3,
          text: "Contemporary films have something for everyone except the writer",
        },
        {
          id: 4,
          text: "Existence of a system that inflicts self-morality as a result of which characters in our films don’t even speak the way normal people do",
        },
        {
          id: 5,
          text: "Despite the existence of a rich tradition of folklore, the film industry has not cared to even tap a small percentage of treasure trove of stories. Hence the need to find original way to tell stories",
        },
        {
          id: 6,
          text: "It is not just the content but also the way it is being told that is getting changed",
        },
        {
          id: 7,
          text: "The need to allow personal creativity take over to make things change.",
        },
      ],
      h_2: "The Elevation",
      h2_date: "Contemporary scenario",
      sub: "A few major developments during the last decade have brought about a welcome change. After a long hiatus, acclaimed writers took the initiative to contest elections to the Managing Committee of the Association. They took the initiative in organizing series of seminars/conferences and workshops to enable the fraternity to focus its attention on key issues plaguing the writers. Exciting developments like the arrival of multiplexes and positive response from the audience to unprecedented themes led to the elevation of a writer to a major player in the scheme of film making. A well-structured screenplay is not looked down upon by Producers but considered to be a key element in making a good film. Today every Bollywood Professional has a bound script that he or she wants to make it to the screen. It is now an acceptable fact that no matter how good or great your story is a bad screenplay can ruin it.",
      h_3: "Special Events so far:",
      eventsAndPublications: [
        {
          id: 1,
          text: "In 1980 Silver Jubilee of the association celebrated and a Commemorative Volume is published.",
        },
        {
          id: 2,
          text: "Author’s Information, the periodical of the Association was first published on 17th May, 1976. The periodicity was uneven and eventually closed after few years.",
        },
        {
          id: 3,
          text: "The 1st Indian Screen Writers Conference held in 2008 was a huge success and has continued every two years in 2010, 2013, and now 2016. In the Indian Screenwriters Conference held in 2013, Writers Guild Of America, West was represented by Oscar winning screenwriter and ex-President Tom Schulman and Vice President Rebecca Kissenger.",
        },
        {
          id: 4,
          text: "Screenwriting Workshop for film and TV writers, sometimes in collaboration with Whistling Woods International and often at the office premises of Film Writers’ Association had been conducted not only in Mumbai but in other cities too.",
        },
        {
          id: 5,
          text: "Seminar on Cinema and Literature-The Question of adaptation organized in 2009.",
        },
        {
          id: 6,
          text: "A hands-on Interactive TV Writers workshop held in May, 2009 in collaboration with Whistling Woods International.",
        },
        {
          id: 7,
          text: "Regular events are held every month at the office premises of Film Writers Association. In the last two years, it has held events on Saadat Hasan Manto, Sahir Ludhiyanavi, Bhojpuri Legends Bhikhari Thakur and Mahender Misir, a workshop on adaptation for Marathi screenwriters, documentaries of Achut Patvardhan, etc.",
        },
        {
          id: 8,
          text: "A session of poetry recital for Hindi and Urdu poets is held regularly to give a platform to member poets to share their work with each other.",
        },
      ],
    },
    {
      title: "The Way Forward",
      organizationalPoints: [
        {
          id: 1,
          text: "Organizational changes within to make FWA more professional, recruit more staff, not depending on honorary busy, practicing members to give their time for routine office work but hire professionals to execute the vision of the honorary members elected by the FWA members. While elected EC supervises, the work will be done by professionals.",
        },
        {
          id: 2,
          text: "Redefining roles of office bearers in the light of new organizational changes.",
        },
        {
          id: 3,
          text: "Formation of Copyright Society to monitor member’s work, collect royalties and distribute.",
        },
        {
          id: 4,
          text: "Bring the producers’ associations like IMPAA, the Gild, the IFTPC and others to the negotiating table to sign on the model contract for the Minimum Basic Contract.",
        },
        {
          id: 5,
          text: "Bring the Indian Broadcasting Federation, and through it, the satellite channels to the negotiating table to sign on the model contract for the Minimum Basic Contract for television screenwriters and come to a mutually acceptable terms for royalties from TV channels for the interim period till the Government forms the Copyright Board and decides the percentages for royalties for both film and television, and for both, screenwriters and lyricists.",
        },
        {
          id: 6,
          text: "Increase interaction with WGA for cooperation for mutual benefit on various areas—creative and commercial. Workshops, seminars, protection of Indian screenwriters rights in USA, protection of WGA members’ rights in India, exchange of writers, participation in conferences, workshops, seminars. Increased participation and cooperation with International Association of Writers Guild-IAWG and the World Conference Of Screenwriters-WCOS.",
        },
        {
          id: 7,
          text: "For Welfare Committee, allocate more funds to increase the base, increase number of beneficiaries of pension, medical help, educational help.",
        },
        {
          id: 8,
          text: "Find new ways to get resources within the limitations of the trade union status.",
        },
        {
          id: 9,
          text: "More workshops and events for film, television screenwriters and lyricists to help them improve their craft, interact with the professional screenwriters. For example select a well-known screenwriter dead or alive and analyze their work for the benefit of our members. Hold it in a bigger place than the office in necessary and charge a nominal fee.",
        },
        {
          id: 10,
          text: "Mentorship by senior screenwriters for a fee which goes to FWA funds.",
        },
        {
          id: 11,
          text: "Make the library user-friendly for screenwriters by adding more books, periodicals, and good screenplays from film and television shows from all around the world. Start an archive of memorable screenplays.",
        },
        {
          id: 12,
          text: "Events. Screening and discussions of films with the filmmaker.",
        },
        {
          id: 13,
          text: "Increase the scope of the Constitution to include the changing roles and responsibilities of FWA.",
        },
        {
          id: 14,
          text: "Expand the services of the website. More columns, more features. Make it more interactive. Make it self-supporting, get ads on the website.",
        },
        {
          id: 15,
          text: "Bring writers of regional languages like Marathi, Bhojpuri, Punjabi, Gujarati, and even Bengali under FWA umbrella and address their grievances.",
        },
        {
          id: 16,
          text: "Reach out to members beyond Mumbai. Open branches in other towns where films are made if FWA resources permit.",
        },
        {
          id: 17,
          text: "Get TV writers better contractual deals whether it is release forms or agreements, grievances or welfare.",
        },
        {
          id: 18,
          text: "Make DSC stronger, give it more teeth. Follow up with FWICE to be more regular, assertive.",
        },
        {
          id: 19,
          text: "Relook at the election process and reform the AGM for better, more transparent, and peaceful elections.",
        },
        {
          id: 20,
          text: "Drive to rope in non-members who are working in the industry.",
        },
        {
          id: 21,
          text: "Appoint lawyers and law firms which understand the problems of our members better.",
        },
        {
          id: 22,
          text: "Bring the writer to the center stage in the industry, make his presence felt everywhere—industry events, award functions, media, internet, in fact in all public domain.",
        },
        {
          id: 23,
          text: "Formulate a media policy and make conscious effort for PR and visibility in the media.",
        },
        {
          id: 24,
          text: "Hold a pitch-fest for FWA members.",
        },
        {
          id: 25,
          text: "Implement the newly Amended Constitution.",
        },
      ],
    },
    // ...more articles
  ];

  const currentArticle = articles[currentPage - 1];
  // const ProfileComponent = () => {
  //   return (
  //     <div className="profile-container">
  //       <img
  //         src={ProfileImage}
  //         alt="Kamlesh Pandey"
  //         className="profile-image"
  //       />
  //       <div className="profile-name">Kamlesh Pandey</div>
  //     </div>
  //   );
  // };
  // Determine the current article to display based on the current page

  const onPageChange = (page) => {
    setCurrentPage(page);
    // Optionally, scroll to the top of the page or content section on page change
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  useEffect(() => {
    // Check screen width on mount and resize
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth < 970); // Set breakpoint according to your design
      localStorage.setItem("isMobile", JSON.stringify(window.innerWidth < 970));
    };

    checkScreenWidth(); // Initial check

    window.addEventListener("resize", checkScreenWidth); // Add event listener for resize

    return () => {
      window.removeEventListener("resize", checkScreenWidth); // Remove event listener on component unmount
    };
  }, []);

  return (
    <>
      {/* Navbar Component */}
      <div className="page">
        {isMobile ? <MobileNavbar /> : <SwaNavbar />}
        <section className="page-container" style={{padding:'0px'}}>
          {/* <Header /> */}
          <HeroSection />
          {/* <ProfileComponent/> */}
        </section>
        <Content article={currentArticle} />
        <Pagination
          current={currentPage}
          onChange={onPageChange}
          pageSize={pageSize}
          total={articles.length}
        />
        <SwaFooter />
      </div>
    </>
  );
}

const Header = () => (
  <header className="header">
    {/* Add your navigation bar content here */}
  </header>
);

const HeroSection = () => (
  <>
    <div className="breadcumb-wrapper">
      <div
        className="
  container"
      >
        <h1 className="headings">
          FILM WRITERS ASSOCIATION: THE DIAMOND YEARS
        </h1>
        <p className="author" style={{ textAlign: "center" }}>
          Based on facts courtesy of Opender Chanana
        </p>
      </div>
    </div>
    {/* Add a background image or more content if needed */}
  </>
);

const Content = ({ article }) => (
  <div
    className="
  container"
  >
    {" "}
    <Article
      title={article.title}
      content={article.content}
      h_1={article.h_1}
      h_1_content={article.h_1_content}
      h_2={article.h_2}
      h2_date={article.h2_date}
      h_2_content={article.h_2_content}
      h_2_content_={article.h_2_content_}
      h_3={article.h_3}
      h_3_content={article.h_3_content}
      h_3_content_={article.h_3_content_}
      content_h3__={article.content_h3__}
      content_={article.content_}
      content_2={article.content_2}
      SUB_HEAD={article.SUB_HEAD}
      sub_head={article.sub_head}
      quote={article.quote}
      head1={article.head1}
      sub={article.sub}
      issues={article.issues}
      c_1={article.c_1}
      c1_={article.c1_}
      minimum_wages={article.minimum_wages}
      head={article.head}
      slog={article.slog}
      h4={article.h4}
      slog1={article.slog1}
      hcontent_={article.hcontent_}
      membershipFees={article.membershipFees}
      fundsAndSchemes={article.fundsAndSchemes}
      c32_={article.c32_}
      c31_={article.c31_}
      opinions={article.opinions}
      organizationalPoints={article.organizationalPoints}
      eventsAndPublications={article.eventsAndPublications}
    />
  </div>
);

const Article = ({
  title,
  content,
  h_1,
  h_1_content,
  h_2,
  h2_date,
  h_2_content,
  h_2_content_,
  h_3,
  h_3_content,
  h_3_content_,
  content_h3__,
  content_,
  content_2,
  SUB_HEAD,
  sub_head,
  quote,
  head1,
  sub,
  issues,
  c_1,
  c1_,
  minimum_wages,
  slog,
  h4,
  slog1,
  hcontent_,
  membershipFees,
  fundsAndSchemes,
  head,
  c31_,
  c32_,
  opinions,
  eventsAndPublications,
  organizationalPoints,
}) => (
  <>
    <h2 className="heading">{title}</h2>
    <p className="para">
      {organizationalPoints && (
        <ol>
          {organizationalPoints.map((point) => (
            <li className="list" key={point.id}>
              {point.text}
            </li>
          ))}
        </ol>
      )}
    </p>

    <h3 className="heading2">{SUB_HEAD}</h3>
    <h3 className="heading3">{sub_head}</h3>
    <p className="para">
      {issues && (
        <ul>
          {issues.map((issue) => (
            <li className="list" key={issue.id}>
              {issue.text}
            </li>
          ))}
        </ul>
      )}
    </p>

    <p className="quote">{quote}</p>
    <p className="para">{content}</p>
    <p className="para">
      {opinions && (
        <ul>
          {opinions.map((issue) => (
            <li className="list" key={issue.id}>
              {issue.text}
            </li>
          ))}
        </ul>
      )}
    </p>
    <p className="para">{content_}</p>

    <h2 className="heading">{h_1}</h2>
    <p className="para">{h_1_content}</p>
    <p className="para">{hcontent_}</p>

    <h2 className="heading">{h_2}</h2>
    {membershipFees && (
      <section>
        <table className="table-wages">
          <tbody>
            {membershipFees.map((fee, index) => (
              <tr key={index}>
                <td>{fee.type}</td>
                <td>:</td>
                <td>{fee.amount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
    )}

    <h3 className="">{h2_date}</h3>
    <h2 className="heading">{head1}</h2>
    <p className="para">{sub}</p>
    <p className="para">{h_2_content}</p>
    <p className="para">{h_2_content_}</p>
    <p className="para">{c_1}</p>

    {minimum_wages && (
      <section>
        <table className="table-wages">
          <tbody>
            {minimum_wages.map((wage, index) => (
              <tr key={index}>
                <td>{wage.category}</td>
                <td>:</td>
                <td>{wage.amount}</td>
                <td>({wage.description})</td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
    )}
    <p className="para">{c1_}</p>
    <h2 className="heading">{h_3}</h2>
    <p className="para">
      {eventsAndPublications && (
        <ul>
          {eventsAndPublications.map((event) => (
            <li className="list" key={event.id}>
              {event.text}
            </li>
          ))}
        </ul>
      )}
    </p>

    <p className="para">
      {fundsAndSchemes && (
        <ul>
          {fundsAndSchemes.map((fund) => (
            <li className="list" key={fund.id}>
              {fund.text}
            </li>
          ))}
        </ul>
      )}
    </p>
    <h2 className="heading">{head}</h2>

    <h3 className="heading4">{slog}</h3>

    <p className="para">{h_3_content}</p>
    <h2 className="heading">{h4}</h2>
    <h3 className="heading4">{slog1}</h3>
    <p className="para">{h_3_content_}</p>
    <p className="para">{content_h3__}</p>
    <p className="para">{c31_}</p>
    <p className="para">{c32_}</p>

    {/* <h2 className="heading">{head1}</h2>
      <p className="para">{sub}</p> */}
  </>
);

export default HistorySwa;
