import React, { useState, useEffect } from 'react';
import "../DSC/DSC.css"
import { Link } from 'react-router-dom';

import SwaNavbar from "../Screenwriters/SwaNavbar";
import SwaFooter from "../Screenwriters/SwaFooter";
import MobileNavbar from "../Screenwriters/MobileNavbar";
import './ByeLaws.css';
// import { Document, Page } from 'react-pdf';
// import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// Setting up workerSrc


const ByeLaws = () => {
  const [isMobile, setIsMobile] = useState(() => {
    const initialValue = localStorage.getItem("isMobile");
    return initialValue ? JSON.parse(initialValue) : false;
  });

  const [numPages, setNumPages] = React.useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth < 970);
      localStorage.setItem("isMobile", JSON.stringify(window.innerWidth < 970));
    };

    checkScreenWidth();

    window.addEventListener("resize", checkScreenWidth);

    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);
  const downloadPDF = () => {
    // URL of the PDF file to download
    const pdfUrl = "http://cms.swaindia.org/uploads/SWA%20Bye%20laws%20-2022.pdf";
    // Create a temporary anchor element
    const anchorElement = document.createElement("a");
    anchorElement.href = pdfUrl;
    anchorElement.download = "bye-laws.pdf";
    anchorElement.click();
  };
  const pdfPath = require('http://cms.swaindia.org/uploads/SWA%20Bye%20laws%20-2022.pdf');
  return (
    <>
      {isMobile ? <MobileNavbar /> : <SwaNavbar />}
      <div className="bye-laws-container">
        <div className="">
          <h1 className='headings'>SCREENWRITERS ASSOCIATION BYE-LAWS</h1>
        </div>
        <div class="mbc-container">
          <button className="bye nice-blue-button scroll-button" onClick={downloadPDF}>Download PDF</button>
        </div>

        <div>
          <div style={{ overflow: 'auto', border: '1px solid black', width: '100%', height: '800px' }}>
            <iframe src="https://cms.swaindia.org/byelaw.html" title="bye-laws" width="100%" height="680px"></iframe>

          </div>
        </div>
        <div class="mbc-container">
          <button className="bye nice-blue-button scroll-button" onClick={downloadPDF}>Download PDF</button>
        </div>
      </div>
      <div class="mbc-container">
        <Link to="/dsc">
          <button className="bye nice-blue-button scroll-button" >Back to DSC</button>
        </Link>
      </div>
      <SwaFooter />
    </>
  );
};

export default ByeLaws;
