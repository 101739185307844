import React, { useState, useEffect } from "react";
import axios from "axios";
import { Card, Row, Col } from "antd";
import MobileNavbar from "../Screenwriters/MobileNavbar";
import SwaNavbar from "../Screenwriters/SwaNavbar";
import SwaFooter from "../Screenwriters/SwaFooter";
import "./Downloads.css";
import { DownloadOutlined } from "@ant-design/icons";
const { Meta } = Card;

function Downloads() {
  const [downloads, setDownloads] = useState([]);
  const [isMobile, setIsMobile] = useState(() => {
    const initialValue = localStorage.getItem("isMobile");
    return initialValue ? JSON.parse(initialValue) : false;
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // Check mobile device
  useEffect(() => {
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth < 970); // Set breakpoint according to your design
      localStorage.setItem("isMobile", JSON.stringify(window.innerWidth < 970));
    };

    checkScreenWidth(); // Initial check

    window.addEventListener("resize", checkScreenWidth); // Add event listener for resize

    return () => {
      window.removeEventListener("resize", checkScreenWidth); // Remove event listener on component unmount
    };
  }, []);

  // API call for downloads data
  useEffect(() => {
    // Function to fetch downloads from API
    const fetchDownloads = async () => {
      try {
        const response = await axios.get(
          "https://cms.swaindia.org/api/downloads"
        );
        console.log("API Response:", response.data.data.downloads); // Logging API response

        // Extracting title and file from the API response
        const formattedDownloads = response.data.data.downloads.map(
          (download) => ({
            title: download.title,
            file: download.file,
          })
        );
        console.log(formattedDownloads, "formattedDownloads");
        setDownloads(formattedDownloads); // Setting downloads state with formatted data
      } catch (error) {
        console.error("Error fetching downloads:", error);
      }
    };

    fetchDownloads(); // Call fetchDownloads function on component mount
  }, []);

  return (
    <>
      {isMobile ? <MobileNavbar /> : <SwaNavbar />}
      <div className="content-container">
        <div className="row">
          <div className="col-md-12">
            <div className="breadcumb-wrapper">
              <div className="container" style={{ marginTop: "3.5rem" }}>
                <h1 className="title_download bold line text-center">
                  All Downloads
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="container" style={{ marginTop: "3.5rem" }}>
          <Row gutter={[16, 16]}>
            {/* Map API downloads Data */}
            {downloads.map((download, index) => (
              <Col key={index} xs={24} sm={12} md={8} lg={6}>
                <div
                  style={{
                    height: "100%",
                    marginTop: "0rem",
                    marginBottom: "1rem",
                  }}
                >
                  {/* <Card
                                        hoverable
                                        style={{ width: '100%', height: '100%', border: '1px solid #d3a221' }}
                                        actions={[
                                            <a
                                                style={{ textDecoration: 'none' }}
                                                href={`https://cms.swaindia.org/uploads/${download.file}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <DownloadOutlined /> Download
                                            </a>
                                        ]}
                                    > */}

                  {/* Card content goes here. Consider adding a Card.Meta for title/description or even a Card cover image. */}
                  <Card
                    hoverable
                    style={{
                      width: "300px",
                      margin: "auto",
                      height: "173px", // Height is sqrt(3)/2 * width to maintain the hexagon aspect ratio
                      backgroundColor: "#f0f2f5",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      transition:
                        "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
                      clipPath:
                        "polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onMouseOver={(e) => {
                      e.currentTarget.style.transform = "scale(1.05)";
                      e.currentTarget.style.boxShadow =
                        "0 6px 12px rgba(0, 0, 0, 0.2)";
                    }}
                    onMouseOut={(e) => {
                      e.currentTarget.style.transform = "scale(1)";
                      e.currentTarget.style.boxShadow =
                        "0 4px 8px rgba(0, 0, 0, 0.1)";
                    }}
                  >
                    <div
                      style={{
                        margin: "auto",
                        width: "90%", // Slightly smaller to ensure content fits well inside the hexagon
                        height: "90%", // Adjusting height similarly
                        textAlign: "center", // Center-align text
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center", // Centers content vertically in the available space
                        alignItems: "center", // Centers content horizontally
                      }}
                    >
                      <div
                        style={{
                          maxWidth: "100%",
                          padding: "0 10px",
                          boxSizing: "border-box",
                        }}
                      >
                        {/* Use a div to wrap the Meta component and text, providing padding to prevent overflow */}
                        <Meta
                          title={
                            <div
                              style={{
                                whiteSpace: "normal",
                                wordBreak: "break-word",
                              }}
                            >
                              {download.title}
                            </div>
                          }
                        />
                      </div>
                      <a
                        href={`https://cms.swaindia.org/storage/uploads/${download.file}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          textDecoration: "none",
                          color: "#d3a221",
                          fontWeight: "bold",
                          marginTop: "10px", // Add some space above the link
                        }}
                      >
                        <DownloadOutlined style={{ marginRight: "8px" }} />{" "}
                        Download
                      </a>
                    </div>
                  </Card>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </div>

      <SwaFooter />
    </>
  );
}

export default Downloads;
