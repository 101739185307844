import React, { useState, useEffect } from "react";
import SwaNavbar from "../../Screenwriters/SwaNavbar";
import MobileNavbar from "../../Screenwriters/MobileNavbar";
import SwaFooter from "../../Screenwriters/SwaFooter";
import "./ExcecutiveCommitee.css";
import male from "../../../img/male.png";
import female from "../../../img/female.png";

function ExecutiveCommittee() {
  const [isMobile, setIsMobile] = useState(() => {
    const initialValue = localStorage.getItem("isMobile");
    return initialValue ? JSON.parse(initialValue) : false;
  });
  useEffect(() => {
    // Check screen width on mount and resize
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth < 970); // Set breakpoint according to your design
      localStorage.setItem("isMobile", JSON.stringify(window.innerWidth < 970));
    };

    checkScreenWidth(); // Initial check

    window.addEventListener("resize", checkScreenWidth); // Add event listener for resize

    return () => {
      window.removeEventListener("resize", checkScreenWidth); // Remove event listener on component unmount
    };
  }, []);

  // State to hold staff members data
  const [staffMembers, setStaffMembers] = useState([]);

  useEffect(() => {
    // Mock data for demonstration
    const mockData = [
      {
        id: 1,
        name: "Varshaa A Jainn",
        image: female,
        specialty: "COO",
      },
      {
        id: 2,
        name: "Pramila Kadam",
        image: female,
        specialty: "Office Manager",
      },
      {
        id: 3,
        name: "Sanchit Dahake",
        image: male,
        specialty: "Event Manager",
      },

      {
        id: 4,
        name: "Sumant Prajapati",
        image: male,
        specialty: "Assistant Executive Secretary",
      },
      {
        id: 5,
        name: "Dhiraj Jain",
        image: male,
        specialty: "Senior Accounts Executive",
      },
      {
        id: 6,
        name: "Pravin Mandavkar",
        image: male,
        specialty: "Junior Assistant",
      },
      {
        id: 7,
        name: "Yashwant Shelar",
        image: male,
        specialty: "Junior Assistant",
      },
      { id: 8, name: "Amar Jadhav", image: male, specialty: "Office Boy" },
      { id: 9, name: "Santosh Patil", image: male, specialty: "Office Boy" },
    ];
    // Set mock data into state
    setStaffMembers(mockData);
  }, []);

  return (
    <>
      {/* Navbar Component */}
      {isMobile ? <MobileNavbar /> : <SwaNavbar />}

      {/* content start */}
      <div className="content-container ">
        {/* col-md-12 screen image */}
        {/* <div className="col-md-12">
                    <img src="./bg.jpg" alt="Screen" style={{ width: "100%", height: "auto" }} />
                </div> */}
        <div className="breadcumb-wrapper">
          <div className="container">
            <h1 className="txt-ecenter headings">
              Let's meet with our Official Office Team
            </h1>
          </div>
        </div>
        {/* <div
          className="container border-container margin-bt"
          style={{ marginTop: "3.5rem" }}
        > */}
        <div className="row">
          <div className="col-md-12">
            <section className="section-team">
              <div className="container">
                <div className="row justify-content-center text-center">
                  <div className="col-md-8 col-lg-6">
                    <div className="header-section"></div>
                  </div>
                </div>
                <div className="row">
                  {/* Map through staff members and render cards */}
                  {staffMembers.map((member) => (
                    <div key={member.id} className="col-sm-6 col-lg-4 col-xl-3">
                      <div className="single-person">
                        <div className="person-image">
                          <img src={member.image} alt={member.name} />
                        </div>
                        <div className="person-info">
                          <h3 className="full-name">{member.name}</h3>
                          <span className="speciality">{member.specialty}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </div>
        {/* </div> */}

        {/* footer component */}
        <SwaFooter />
      </div>
      {/* content end */}
    </>
  );
}

export default ExecutiveCommittee;
