import React, { useEffect, useState } from "react";
import "./Events.css";
import SwaFooter from "../Screenwriters/SwaFooter";
import EventsSection from "./EventsSection";
import EventsHeader from "./EventsHeader";
import MobileNavbar from "../Screenwriters/MobileNavbar";
import SwaNavbar from "../Screenwriters/SwaNavbar";

function Events() {
  const [isMobile, setIsMobile] = useState(() => {
    const initialValue = localStorage.getItem("isMobile");
    return initialValue ? JSON.parse(initialValue) : false;
  });

  useEffect(() => {
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth < 970);
      localStorage.setItem("isMobile", JSON.stringify(window.innerWidth < 970));
    };

    checkScreenWidth();
    window.scrollTo(0, 0);

    window.addEventListener("resize", checkScreenWidth);

    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);
  return (
    <>
      {isMobile ? <MobileNavbar /> : <SwaNavbar />}

      <div style={{ position: "relative", zIndex: 1 }}>
        <EventsHeader />

        <div className="breadcumb-wrapper">
          <div className="container">
            <div className="container">
              <div className="row justify-content-center justify-content-lg-between align-items-center">
                <div className="col-lg-10 text-end">
                  <div className="header-ads">
                    <p className="mission-statement">
                      The Events Subcommittee of the Screenwriters Association
                      (SWA) organises roundtables, panel discussions, one-on-one
                      Vartalaaps, honorary events and several other community
                      mixers on a regular basis.By engaging and bringing
                      together new entrants and working professionals, the
                      Subcommittee hopes to inspire, inform, and encourage
                      screenwriters and lyricists to work on their craft, and
                      also give them opportunities to meet in person, ultimately
                      building solidarity and camaraderie among the community.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <EventsSection />
      </div>

      <SwaFooter />
    </>
  );
}

export default Events;
