import React, { useEffect, useState } from "react";
import GallerySection from "./GallerySection";
import SwaFooter from "../Screenwriters/SwaFooter";
import GalleryHeader from "./GalleryHeader";
import MobileNavbar from "../Screenwriters/MobileNavbar";
import SwaNavbar from "../Screenwriters/SwaNavbar";

function ViewGallery() {
  const [isMobile, setIsMobile] = useState(() => {
    const initialValue = localStorage.getItem("isMobile");
    return initialValue ? JSON.parse(initialValue) : false;
  });

  useEffect(() => {
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth < 970);
      localStorage.setItem("isMobile", JSON.stringify(window.innerWidth < 970));
    };

    checkScreenWidth();
    window.scrollTo(0, 0);

    window.addEventListener("resize", checkScreenWidth);

    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);
  return (
    <>
      {isMobile ? <MobileNavbar /> : <SwaNavbar />}

      <div style={{ position: "relative", zIndex: 1 }}>
        <GalleryHeader />

        <div className="breadcumb-wrapper">
          <div className="container">
            <ul class="breadcumb-menu">
              <li>
                <a href="/">Home</a>
              </li>
              <li>Gallery</li>
            </ul>
          </div>
        </div>

        <GallerySection />
      </div>

      <SwaFooter />
    </>
  );
}

export default ViewGallery;
