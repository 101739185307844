import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MediationFormPDF from "../../pdf/DSC Mediation Form Latest.pdf";
import MobileNavbar from "../Screenwriters/MobileNavbar";
import SwaNavbar from "../Screenwriters/SwaNavbar";
import SwaFooter from "../Screenwriters/SwaFooter";
import ImageContainer from "../../img/bg.jpg";

const Blog = () => {
  const [isMobile, setIsMobile] = useState(() => {
    const initialValue = localStorage.getItem("isMobile");
    return initialValue ? JSON.parse(initialValue) : false;
  });

  useEffect(() => {
    // Check screen width on mount and resize
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth < 970); // Set breakpoint according to your design
      localStorage.setItem("isMobile", JSON.stringify(window.innerWidth < 970));
    };

    checkScreenWidth(); // Initial check

    window.addEventListener("resize", checkScreenWidth); // Add event listener for resize

    return () => {
      window.removeEventListener("resize", checkScreenWidth); // Remove event listener on component unmount
    };
  }, []);

  return (
    <>
      {isMobile ? <MobileNavbar /> : <SwaNavbar />}

      <div className="col-md-12">
        <div className="breadcumb-wrapper">
          <div className="container">
            <h1 className="title_download bold text-center mbc">Blogs</h1>
          </div>
        </div>
      </div>
      <div className="dsc-page margin-bt">
        {isMobile ? (
          <>
            <code style={{ color: "black", marginBottom: "3vh" }}>
              (In this column, SWA's Legal Officer addresses some of the recent
              and most frequent legal queries of SWA members.){" "}
            </code>

            <img
              className="img"
              src={"./blogs/blog-m1.jpg"}
              alt="Blog Document"
              style={{
                width: "100%",
                display: "block",
                marginBottom: "1vh",
              }}
            />
            <img
              className="img"
              src={"./blogs/blog-m2.jpg"}
              alt="Blog Document"
              style={{
                width: "100%",
                display: "block",
                marginBottom: "1vh",
              }}
            />
            <img
              className="img"
              src={"./blogs/blog-m3.jpg"}
              alt="Blog Document"
              style={{
                width: "100%",
                display: "block",
                marginBottom: "1vh",
              }}
            />
            <img
              className="img"
              src={"./blogs/blog-m4.jpg"}
              alt="Blog Document"
              style={{
                width: "100%",
                display: "block",
                marginBottom: "1vh",
              }}
            />
          </>
        ) : (
          <div className="image-container">
            <img
              className="img"
              src={ImageContainer}
              alt="Blog Document"
              style={{
                maxWidth: "1400px",
                height: "auto",
                display: "block",
                marginLeft: "120px",
                marginRight: "auto",
              }}
            />
          </div>
        )}
      </div>

      <div className="mbc-container">
        <Link to="/">
          <button className="nice-blue-button scroll-button">
            <i
              class="fa-solid fa-house"
              style={{
                color: " #ffffff",
                marginLeft: "0rem",
                marginRight: "1rem",
              }}
            >
              {" "}
            </i>
            Back to Home
          </button>
        </Link>
      </div>
      <SwaFooter />
    </>
  );
};

export default Blog;
