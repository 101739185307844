import React from "react";
import IndexNavbar from "./IndexNavbar";
import Indexdata from "./indexdata";
export default function Indexpage() {
    return (
        <>
            <IndexNavbar />
            <Indexdata />
        </>
    )
}