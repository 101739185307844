import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";

import { Link } from "react-router-dom";

function AwardSection() {
  const [articleList, setArticleList] = useState([]);
  const [offset, setOffset] = useState(0);
  const [perPage] = useState(12);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const image_path = "https://cms.swaindia.org/storage/Blog";
  const thumbnail_images_path =
  "https://cms.swaindia.org/storage/Blog/thumbnail";
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://cms.swaindia.org/api/blog/list/7"
        );
        console.log("API ARchive response", response.data.data.blog_list[0]);
        const sortedArticleList = response.data.data.blog_list.sort(
          (a, b) => a.subcategory_id - b.subcategory_id
        );

        setArticleList(sortedArticleList);
        setPageCount(Math.ceil(sortedArticleList.length / perPage));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handlePageClick = (selectedPage) => {
    const newOffset = selectedPage * perPage;
    setCurrentPage(selectedPage);
    setOffset(newOffset);
  };

  // const formatDate = (dateString) => {
  //   const options = { day: "2-digit", month: "short", year: "numeric" };
  //   return new Date(dateString).toLocaleDateString("en-US", options);
  // };

  return (
    <div>
      <section className="space-top space-extra-bottom">
        <div className="container">
          <div className="row gy-30 mb-30">
            {articleList
              .slice(currentPage * perPage, (currentPage + 1) * perPage)
              .map((article) => (
                <div key={article.subcategory_id} className="col-xl-4 col-sm-6">
                  <div className="blog-style1">
                    <div className="blog-img">
                      <Link to={`/awardsDetail/${article.id}`}>
                        {" "}
                        <img
                          // src={`${image_path}/${article.image}`}
                          src={`${thumbnail_images_path}/${article.thumbnail_image}`}
                          alt="blogimage"
                        />
                      </Link>

                      {/* <Link
                        to={`/awardsDetail/${article.subcategory_id}`}
                        className="category"
                        data-theme-color="#4E4BD0"
                      >
                        {article.title}
                      </Link> */}
                    </div>
                    <h5 className="box-title-24">
                      <Link
                        to={`/awardsDetail/${article.id}`}
                        className="hover-line"
                      >
                        {article.title}
                      </Link>
                    </h5>
                    {/* <div className="blog-meta">
                      <div>
                        <i className="fal fa-calendar-days"></i>
                        {formatDate(article.created_at)}
                      </div>
                    </div> */}
                  </div>
                </div>
              ))}
          </div>
          {pageCount > 1 && (
            <div className="th-pagination mt-40 text-center">
              <ReactPaginate
                previousLabel={"prev"}
                nextLabel={"next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={({ selected }) => handlePageClick(selected)}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
          )}
        </div>
      </section>
    </div>
  );
}

export default AwardSection;
