import axios from "axios";
import React, { useEffect, useState } from "react";
import MobileNavbar from "../Screenwriters/MobileNavbar";
import SwaNavbar from "../Screenwriters/SwaNavbar";
import GalleryHeader from "./GalleryHeader";
import { Link, useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import SwaFooter from "../Screenwriters/SwaFooter";

function GalleryBlog() {
  const { id } = useParams();
  const [articleList, setArticleList] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogImage, setDialogImage] = useState("");
  const [isMobile, setIsMobile] = useState(() => {
    const initialValue = localStorage.getItem("isMobile");
    return initialValue ? JSON.parse(initialValue) : false;
  });

  useEffect(() => {
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth < 970);
      localStorage.setItem("isMobile", JSON.stringify(window.innerWidth < 970));
    };

    checkScreenWidth();
    window.scrollTo(0, 0);

    window.addEventListener("resize", checkScreenWidth);

    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);

  const image_path = "https://cms.swaindia.org/storage/Gallery/Image";
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://cms.swaindia.org/api/gallery/show/${id}`
        );
        console.log(
          "API detail response",
          response.data.data.gallery[0].gallery_image
        );

        const sortedArticleList =
          response.data.data.gallery[0].gallery_image.sort(
            (a, b) => a.gallery_id - b.gallery_id
          );

        setArticleList(sortedArticleList);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id]);

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleImageClick = (imageUrl) => {
    setDialogImage(imageUrl);
    setIsDialogOpen(true);
  };

  // const formatDate = (dateString) => {
  //   const options = { day: "2-digit", month: "short", year: "numeric" };
  //   return new Date(dateString).toLocaleDateString("en-US", options);
  // };

  return (
    <div>
      {isMobile ? <MobileNavbar /> : <SwaNavbar />}
      <div
        style={{
          position: "relative"
          // , zIndex: 1
        }}
      >
        <GalleryHeader />
        <section className="space-top space-extra-bottom">
          <div className="container">
            <div className="row gy-30 mb-30">
              {isDialogOpen && (
                <div
                  className="modal fade show"
                  id="exampleModal"
                  tabIndex=""
                  role="dialog"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                  style={{
                    display: "block",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    bottom: "0",
                    left: "0",
                    right: "0",
                    zIndex: "1500" // Ensure dialog appears above both navbars
                  }}
                >
                  <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                      <div className="modal-header">
                        <button
                          type="button"
                          className="btn-close"
                          aria-label="Close"
                          onClick={handleDialogClose}
                        ></button>
                      </div>
                      <div className="modal-body">
                        <img
                          src={dialogImage}
                          className="img-fluid"
                          alt="full-screen-image" />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {articleList.map((article) => (
                <div key={article.id} className="col-xl-4 col-sm-6">
                  <div className="blog-style1">
                    <div className="blog-img"  style={{border:"2px solid #d3a221"}}>
                      <img
                        src={`${image_path}/${article.image}`}
                        alt="blogimage"
                       
                        onClick={() =>
                          handleImageClick(`${image_path}/${article.image}`)
                        }
                      />
                      {/* <div className="category" data-theme-color="#4E4BD0">
                        {article.title}
                      </div> */}
                      award
                    </div>
                    <h3 className="box-title-24">
                      <div className="hover-line">{article.title}</div>
                    </h3>
                    {/* <div className="blog-meta">
                      <div>
                        <i className="fal fa-calendar-days"></i>
                        {formatDate(article.created_at)}
                      </div>
                    </div> */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <SwaFooter />
      </div>
    </div>
  );
}

export default GalleryBlog;
