// AppointmentPage.js

import React, { useState, useEffect ,useRef} from "react";
import './Appointment.css';
import SwaNavbar from "../Screenwriters/SwaNavbar";
import SwaFooter from "../Screenwriters/SwaFooter";
import MobileNavbar from "../Screenwriters/MobileNavbar"; // Importing CSS for styling

const Appointment = () => {
    const [isMobile, setIsMobile] = useState(() => {
        const initialValue = localStorage.getItem("isMobile");
        return initialValue ? JSON.parse(initialValue) : false;
      });
      useEffect(() => {
        const checkScreenWidth = () => {
          setIsMobile(window.innerWidth < 970);
          localStorage.setItem("isMobile", JSON.stringify(window.innerWidth < 768));
        };
    
        checkScreenWidth();
    
        window.addEventListener("resize", checkScreenWidth);
    
        return () => {
          window.removeEventListener("resize", checkScreenWidth);
        };
      }, []);
  return (
    <>
    {isMobile ? <MobileNavbar /> : <SwaNavbar />}
    <div className="appointment-container border-container margin-bt">
      <h1 className='headings'>Book Your Appointment</h1>
      <p>Please check the calendar for available slots and use the form below to request an appointment.</p>
      
      {/* Embedded Google Calendar */}
      <div className="iframe-container">
        <iframe
          src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ3sOJ2aiacjSVfuskrbziFXYUPdbWjypgi0UVc59RAq_kQnJKp5ILq0rvM2Z5Iw8nXC1jd4x6ry?gv=true"
          style={{ border: 0 }}
          width="800"
          height="600"
          frameBorder="0"
          scrolling="no"
          title="Appointment Calendar"
        ></iframe>
      </div>
      
      {/* Example Contact Form for Booking */}
      <form className="appointment-form">
        <input type="text" placeholder="Your Name" required />
        <input type="email" placeholder="Your Email" required />
        <textarea placeholder="Message (Optional)"></textarea>
        <button className="dsc-btn scroll-button" type="submit">Request Appointment</button>
      </form>
    </div>
    <SwaFooter/>
    </>
  );
};

export default Appointment;
