import React, { useState, useEffect } from "react";
import axios from "axios";
import SwaNavbar from "../../Screenwriters/SwaNavbar";
import MobileNavbar from "../../Screenwriters/MobileNavbar";
import SwaFooter from "../../Screenwriters/SwaFooter";

const SubCommittee = () => {
  const [subCommittees, setSubCommittees] = useState([]);
  const [isMobile, setIsMobile] = useState(() =>
    JSON.parse(localStorage.getItem("isMobile") || "false")
  );
  const [error, setError] = useState("");

  useEffect(() => {
    const checkScreenWidth = () => {
      const isMobileScreen = window.innerWidth < 970;
      setIsMobile(isMobileScreen);
      localStorage.setItem("isMobile", JSON.stringify(isMobileScreen));
    };

    checkScreenWidth();
    window.addEventListener("resize", checkScreenWidth);

    return () => window.removeEventListener("resize", checkScreenWidth);
  }, []);

  // API for fetch SUb Committee
  useEffect(() => {
    const fetchSubCommittees = async () => {
      try {
        const response = await axios.get(
          "https://cms.swaindia.org/api/get_committee"
        );
        if (
          response.data &&
          response.data.data &&
          response.data.data.committee_list
        ) {
          // Find the "SUB COMMITTEES" entry
          const subCommitteesData = response.data.data.committee_list.find(
            (committee) => committee.name === "SUB COMMITTEES"
          );
          if (subCommitteesData && subCommitteesData.children) {
            setSubCommittees(subCommitteesData.children);
          } else {
            setError("No sub-committees found");
          }
        } else {
          setError("Invalid data structure from API");
        }
      } catch (error) {
        setError("Failed to fetch sub-committees");
        console.error("Error fetching sub-committees:", error);
      }
    };

    fetchSubCommittees();
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      {isMobile ? <MobileNavbar /> : <SwaNavbar />}
      <div className="content-container ">
        {/* <div
          className="container border-container"
          style={{ marginTop: "3.5rem", marginBottom: "3.5rem" }}
        > */}
        <div className="breadcumb-wrapper">
          <div className="container">
            <h1 className="txt-ecenter headings">Sub Committees</h1>
          </div>
        </div>
        <div className="container">
          {subCommittees.map((committee, index) => (
            <div key={index} className="committee">
              <h2 className="h1">{committee.name}</h2>
              <div dangerouslySetInnerHTML={{ __html: committee.about }} />
              {/* If there were members to display, you would iterate over committee.members here */}
            </div>
          ))}
        </div>
      </div>
      {/* </div> */}
      <SwaFooter />
    </>
  );
};

export default SubCommittee;
