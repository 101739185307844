import React, { useEffect, useState } from "react";
import axios from "axios";
import DOMPurify from "dompurify";
import { useParams } from "react-router-dom";
import "./Media.css";
import MediaHeader from "./MediaHeader";
import MobileNavbar from "../Screenwriters/MobileNavbar";
import SwaNavbar from "../Screenwriters/SwaNavbar";
import SwaFooter from "../Screenwriters/SwaFooter";

function MediaDetail() {
  const { id } = useParams();
  const [articleList, setArticleList] = useState([]);
  const [subcategory, setSubcategory] = useState([]);
  const [isMobile, setIsMobile] = useState(() => {
    const initialValue = localStorage.getItem("isMobile");
    return initialValue ? JSON.parse(initialValue) : false;
  });

  useEffect(() => {
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth < 970);
      localStorage.setItem("isMobile", JSON.stringify(window.innerWidth < 970));
    };

    checkScreenWidth();
    window.scrollTo(0, 0);

    window.addEventListener("resize", checkScreenWidth);

    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);

  const image_path = "https://cms.swaindia.org/storage/Blog";
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://cms.swaindia.org/api/blog/show/${id}`
        );
        console.log("API detail response", response.data.data.blog[0]);
        const sortedArticleList = response.data.data.blog[0];
        const subcategories = response.data.data.blog[0].subcategory;
        setSubcategory(subcategories);

        // const slice = sortedArticleList.slice(offset, offset + perPage);
        setArticleList(sortedArticleList);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  const sanitizedDescription = DOMPurify.sanitize(articleList.description);
  // const formatDate = (dateString) => {
  //   const options = { day: "2-digit", month: "short", year: "numeric" };
  //   return new Date(dateString).toLocaleDateString("en-US", options);
  // };
  return (
    <div>
      {isMobile ? <MobileNavbar /> : <SwaNavbar />}
      <div style={{ position: "relative", zIndex: 1 }}>
        <MediaHeader />

        <section className="th-blog-wrapper blog-details space-top space-extra-bottom">
          <div className="container">
            <div className="row">
              <div className="col-xxl-9 col-lg-8">
                <div className="th-blog blog-single">
                  <div data-theme-color="#4E4BD0" className="category">
                    {subcategory.subcategory}
                  </div>
                  <h2 className="blog-title"> {articleList.title}</h2>
                  {/* <div className="blog-meta">
                    <a href="/workshops">
                      <i className="fal fa-calendar-days" />
                      {formatDate(articleList.created_at)}
                    </a> */}
                  {/* <a href="/educationDetail">
                  <i className="far fa-comments"></i>Comments (3)
                </a> */}
                  {/* <span>
                      <i className="far fa-book-open" />5 Mins Read
                    </span>
                  </div> */}
                  <div className="blog-img" style={{ width: "fit-content" }}>
                    {/* for this img css given in Media.css */}
                    <img
                      src={`${image_path}/${articleList.image}`}
                      alt="BlogImage"
                      // style={{ maxWidth:"40vw"}}
                    />
                  </div>
                  {/* <div className="blog-content-wrap">
                    <div className="blog-content">
                      <div className="blog-info-wrap"> */}
                  {/* <button className="blog-info print_btn">
                          Print :<i className="fas fa-print" />
                        </button> */}
                  {/* <a className="blog-info" href="mailto:">
                          Email :<i className="fas fa-envelope" />
                        </a> */}
                  {/* <button className="blog-info ms-sm-auto">
                          15k <i className="fas fa-thumbs-up" />
                        </button>
                        <span className="blog-info">
                          126k <i className="fas fa-eye" />
                        </span>
                        <span className="blog-info">
                          12k <i className="fas fa-share-nodes" />
                        </span> */}
                  {/* </div> */}

                  {/* <div className="content "></div> */}
                </div>
              </div>
              {/* </div>
              </div> */}
              {/* blog content */}
              <div className="my-4 py-lg-2">
                <div className="content-container">
                  {React.createElement("div", {
                    dangerouslySetInnerHTML: {
                      __html: sanitizedDescription,
                    },
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>
        <SwaFooter />
      </div>
    </div>
  );
}

export default MediaDetail;
