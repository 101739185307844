import React, { useState, useEffect } from "react";
import axios from "axios";
import MobileNavbar from "../Screenwriters/MobileNavbar";
import SwaNavbar from "../Screenwriters/SwaNavbar";
import SwaFooter from "../Screenwriters/SwaFooter";
import "./ArchiveData.css";
import { Link } from "react-router-dom";

function ArchiveData() {
  const [isMobile, setIsMobile] = useState(() => {
    const initialValue = localStorage.getItem("isMobile");
    return initialValue ? JSON.parse(initialValue) : false;
  });

  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get(
          "https://cms.swaindia.org/api/archive/list"
        );
        console.log("Response from API articles:", response.data);

        setArticles(response.data.data.article_list);
      } catch (error) {
        console.error("Error fetching articles:", error);
      }
    };

    fetchArticles();
  }, []);

  return (
    <>
      {isMobile ? <MobileNavbar /> : <SwaNavbar />}
      <div className="row">
        <div className="col-md-12">
          <div className="breadcumb-wrapper">
            <div className="container">
              <h1 className="title_download bold line text-center">
                Archive-Articles
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="table-responsive">
          {" "}
          {/* Add this wrapper for responsiveness */}
          <table className="articles-table table">
            <thead>
              <tr>
                <th>Title</th>
                <th>Author</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {articles.map((article, index) => (
                <tr key={index}>
                  <td>
                    <Link
                      to={`/archivepage/${article.id}`}
                      // target="_blank"
                      // rel="noopener noreferrer"
                    >
                      {article.art_main_heading}
                    </Link>
                  </td>
                  <td>{article.author_name}</td>
                  <td>{article.art_date}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <SwaFooter />
    </>
  );
}

export default ArchiveData;
