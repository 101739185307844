import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useLocation, useParams } from "react-router-dom";

function AwardsHeader() {
  const [articleList, setArticleList] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  console.log(location, "locationnnn");
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const { id } = useParams();
  console.log(id, "fgegsdhrftgh id2");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://cms.swaindia.org/api/blog/category/show/7"
        );
        console.log("API Archive response", response);
        const sortedArticleList =
          response.data.data.blog_category[0].subcategory.sort((a, b) => {
            const idA = parseInt(a.id);
            const idB = parseInt(b.id);
            return idA - idB;
          });

        setArticleList(sortedArticleList);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    console.log(location.pathname, "eerfgetgt");
  }, [location.pathname]);

  return (
    <div>
      <div className="th-menu-wrapper">
        <div className="th-menu-area text-center">
          <button className="th-menu-toggle">
            <i className="fal fa-times"></i>
          </button>
          <div className="mobile-logo">
            <button type="button" className="th-menu-toggle d-block d-lg-none">
              <i className="far fa-bars"></i>
            </button>
          </div>
          <div
            className={`th-mobile-men ${
              menuOpen ? "show" : "d-none"
            } d-lg-inline-block`}
          >
            <ul style={{ width: "50%" }}>
              <li className="list-name">
                <a href="/">Home</a>
              </li>
              {articleList.map((article, index) => (
                <li key={index}>
                  <Link to={`/awardsDetail/${article.id}`}>
                    {article.subcategory}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <header className="th-header header-layout1">
        <div className="sticky-wrapper">
          <div className="menu-area">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto d-lg-none d-block">
                  <div className="header-logo">
                    <button
                      type="button"
                      className="menu-toggle d-block d-lg-none"
                      style={{
                        backgroundColor: "#FF1D50",
                        border: "none",
                        color: "white",
                      }}
                      onClick={toggleMenu}
                    >
                      <i className="far fa-bars"></i>
                    </button>
                  </div>
                </div>
                <div className="col-auto">
                  <div
                    className={`main-menu ${
                      menuOpen ? "show" : "d-none"
                    } d-lg-inline-block`}
                  >
                    <ul>
                      <li className="list-name">
                        <a href="/" style={{ color: "white" }}>
                          Home
                        </a>
                      </li>
                      <li>
                        <Link
                          to="/awards"
                          style={{
                            color:
                              location.pathname === "/awards"
                                ? "#FF1D50;"
                                : "white",
                          }}
                        >
                          Awards
                        </Link>
                      </li>
                      {articleList.map((article, index) => (
                        <li key={index}>
                          {console.log(
                            location.pathname,
                            `/award-subDetail/${article.id}`,
                            "geghsetgh"
                          )}
                          <Link
                            to={`/award-subDetail/${article.id}`}
                            style={{
                              color:
                                location.pathname.includes(
                                  `/award-subDetail/${article.id}`
                                ) ||
                                location.pathname.includes(
                                  `/awardsDetail/${article.id}`
                                )
                                  ? "#FF1D50"
                                  : "white",
                            }}
                          >
                            {article.subcategory}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default AwardsHeader;
