import React, { useState, useEffect, useRef } from "react";
import "../Screenwriters/ScreenWriters.css";
import "../Screenwriters/Contactform.css";
import "../Screenwriters/Contacttitle.css";
import "../Screenwriters/Work.css";
import "../Screenwriters/indexdata.css";
import "./AboutSwa.css";
import SwaNavbar from "../Screenwriters/SwaNavbar";
import SwaFooter from "../Screenwriters/SwaFooter";
import MobileNavbar from "../Screenwriters/MobileNavbar";

function AboutSwa() {
  const constitutionRef = useRef(null);
  const [isMobile, setIsMobile] = useState(() => {
    const initialValue = localStorage.getItem("isMobile");
    return initialValue ? JSON.parse(initialValue) : false;
  });
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const section = document.querySelector(hash);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);
  useEffect(() => {
    // Scroll to top when component mounts
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    // Check screen width on mount and resize
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth < 970); // Set breakpoint according to your design
      localStorage.setItem("isMobile", JSON.stringify(window.innerWidth < 970));
    };

    checkScreenWidth(); // Initial check

    window.addEventListener("resize", checkScreenWidth); // Add event listener for resize

    return () => {
      window.removeEventListener("resize", checkScreenWidth); // Remove event listener on component unmount
    };
  }, []);
  return (
    // <>
    //     {/* Navbar Component */}
    //     {isMobile ? <MobileNavbar /> : <SwaNavbar />}

    //     {/* content start */}
    //     <div className="content-container">
    //         {/* col-md-12 screen image */}
    //         <div className="col-md-12">
    //             <img src="./aboutus.jpg" alt="Screen" style={{ width: "100%", height: "auto" }} />
    //         </div>

    //         <div className="container" style={{ marginTop: '3.5rem' }}>
    //             <div className="row">
    //                 <div className="col-md-12">
    //                     <h1 className="title bold text-center">Our Mission</h1>
    //                     <p className='about_p'>The Screenwriters Association (SWA, formerly Film Writers' Association - Regd. Under the Trade Union Act 1926, Regd. No. 3726) is a Trade Union of screenwriters and lyricists who work for Films, TV and Digital Media in India. Authors, novelists, playwrights, journalists who aspire for to diversify or join fulltime the mediums of films, TV or Digital entertainment, are also members of the SWA.</p>
    //                     <p className='about_p'>The Screenwriters’ Association (SWA) is a body of the Writers, for the writers and by the Writers. It’s an autonomous organisation having the following aims and objectives:</p>
    //                     <ol className='about_ol'>
    //                         <li>To foster a feeling of fraternity, sorority and unity amongst its members</li>
    //                         <li>To regulate the relationship of its members with producer bodies and other assignees through collective bargaining via Minimum Basic Contracts for film and TV and new media (digital)</li>
    //                         <li>To secure and safeguard the interests, rights, compensation and privileges of its members in all matters relating to their professional engagement and working conditions. (However, the Association is not responsible for securing employment or contracts/assignments for its members.)</li>
    //                         <li>To promote and encourage high standards of professional conduct and integrity amongst its members.</li>
    //                         <li>To also provide appropriate learning opportunities to members to upgrade their scriptwriting and lyric-writing skills, by organizing seminars and workshops for writers at subsidized cost.</li>
    //                         <li>To address, mediate and settle disputes such as breach of contractual obligations, copyright infringement and refutation of credits and/or remuneration, between members and producer bodies.</li>
    //                         <li>To provide legal assistance to its members in respect of matters arising out of/or incidental to their profession, including legal consultancy.</li>
    //                         <li>To secure representation of its members on delegations, commissions, committees etc. Set-up by the government or other bodies where issues concerning screenwriters or screenwriting are to be discussed.</li>
    //                         <li>To promote the aims and objects of the Screenwriters Association that will help SWA members as well as other screenwriters to further their craft and help them build their writing careers.</li>

    //                     </ol>
    //                     <h2>Ancillary Functions of the FWA</h2>
    //                     <ol className='about_ol'>
    //                         <li>The Screenwriters Association also registers, through its office & website - the scripts, lyrics, stories written by its members. This service safeguards the Copyright of a SWA member.</li>
    //                         <li>SWA also run welfare activities for needy and elderly SWA members in the form of medial-aid, pension and scholarships (to their children).</li>

    //                     </ol>
    //                     <h1 className="title bold text-center">SWA Constitution</h1>
    //                     <p className='about_p'>SWA’s Executive Committee functions as per the guidelines and authority accorded to it in the Constitution of SWA. The overseeing General Body, comprising of Regular, Life and Associate members of the Association also adheres to the Constitution of SWA.

    //                     </p>
    //                 </div>
    //             </div>
    //         </div>

    //         {/* footer component */}
    //         <SwaFooter />
    //     </div>
    //     {/* content end */}
    // </>
    <div className="app-container">
      {/* Navbar Component */}
      {isMobile ? <MobileNavbar /> : <SwaNavbar />}

      {/* Hero Section with Image and Title */}
      {/* <section className="hero-section">
          <img src="./aboutus.jpg" alt="Our Mission" className="hero-image" />
          <h1 className="hero-title">Our Mission</h1>
        </section> */}

      {/* Main Content Section */}
      {/* <section className="main-content ">
        <div className="content-wrapper border-container"> */}
      <section className="content-container">
        <div className="breadcumb-wrapper">
          <div className="container">
            <h1 className="headings"> Our Mission </h1>
            <p className="mission-statement">
              The Screenwriters Association (SWA, formerly Film Writers'
              Association - Regd. Under the Trade Union Act 1926, Regd. No.
              3726) is a Trade Union of screenwriters and lyricists who work for
              Films, TV and Digital Media in India. Authors, novelists,
              playwrights, journalists who aspire for to diversify or join
              fulltime the mediums of films, TV or Digital entertainment, are
              also members of the SWA.{" "}
            </p>
          </div>
        </div>
        <div className="container">
          <p className="sub">
            The Screenwriters’ Association (SWA) is a body of the Writers, for
            the writers and by the Writers. It’s an autonomous organisation
            having the following aims and objectives:{" "}
          </p>
          {/* Objectives Section */}
          <div className="objectives">
            <ul className="objectives-list">
              <li>
                1. To foster a feeling of fraternity, sorority and unity amongst
                its members.
              </li>
              <li>
                2. To regulate the relationship of its members with producer
                bodies and other assignees through collective bargaining via
                Minimum Basic Contracts for film and TV and new media (digital).
              </li>
              <li>
                3. To secure and safeguard the interests, rights, compensation
                and privileges of its members in all matters relating to their
                professional engagement and working conditions. (However, the
                Association is not responsible for securing employment or
                contracts/assignments for its members.)
              </li>
              <li>
                4. To promote and encourage high standards of professional
                conduct and integrity amongst its members.
              </li>
              <li>
                5. To also provide appropriate learning opportunities to members
                to upgrade their scriptwriting and lyric-writing skills, by
                organizing seminars and workshops for writers at subsidized
                cost.
              </li>
              <li>
                6. To address, mediate and settle disputes such as breach of
                contractual obligations, copyright infringement and refutation
                of credits and/or remuneration, between members and producer
                bodies.
              </li>
              <li>
                7. To provide legal assistance to its members in respect of
                matters arising out of/or incidental to their profession,
                including legal consultancy.
              </li>
              <li>
                8. To secure representation of its members on delegations,
                commissions, committees etc. Set-up by the government or other
                bodies where issues concerning screenwriters or screenwriting
                are to be discussed.
              </li>
              <li>
                9. To promote the aims and objects of the Screenwriters
                Association that will help SWA members as well as other
                screenwriters to further their craft and help them build their
                writing careers.
              </li>

              {/* Add other objectives */}
            </ul>
          </div>

          {/* Ancillary Functions Section */}
          <div className="ancillary-functions">
            <h2 className="sub">Ancillary Functions of the SWA</h2>
            <ul className="functions-list">
              <li>
                1. The Screenwriters Association also registers, through its
                office & website - the scripts, lyrics, stories written by its
                members. This service safeguards the Copyright of a SWA member.
              </li>
              <li>
                2. SWA also run welfare activities for needy and elderly SWA
                members in the form of medial-aid, pension and scholarships (to
                their children).
              </li>
              {/* Add other functions */}
            </ul>
          </div>

          {/* SWA Constitution Section */}
          <div id="constitution-section" className="constitution-section">
            <h2 className="headings">SWA Constitution</h2>

            <p className="mission-statement">
              SWA’s Executive Committee functions as per the guidelines and
              authority accorded to it in the Constitution of SWA. The
              overseeing General Body, comprising of Regular, Life and Associate
              members of the Association also adheres to the Constitution of
              SWA.
            </p>

            {/* Add other objectives */}
          </div>
        </div>
      </section>

      {/* Footer Component */}
      <SwaFooter />
    </div>
  );
}

export default AboutSwa;
