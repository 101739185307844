import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./MBC.css"; // Your CSS file
import MobileNavbar from "../Screenwriters/MobileNavbar";
import SwaNavbar from "../Screenwriters/SwaNavbar";
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap"; // Import OverlayTrigger and Tooltip
import SwaFooter from "../Screenwriters/SwaFooter";
import bhavnex_MBC from "../../img/bhavnex_MBC.png";
const MBC = () => {
  const [isMobile, setIsMobile] = useState(() => {
    const initialValue = localStorage.getItem("isMobile");
    return initialValue ? JSON.parse(initialValue) : false;
  });

  useEffect(() => {
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth < 970);
      localStorage.setItem("isMobile", JSON.stringify(window.innerWidth < 970));
    };

    checkScreenWidth();
    window.scrollTo(0, 0);

    window.addEventListener("resize", checkScreenWidth);

    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);

  const items = [
    {
      title: "Film MBC",
      //link: 'link-to-film-mbc' // Replace with your actual link or route
      description:
        "Standardise contracts and minimum rates for film writers to establish industry norms through collective bargaining.",
    },
    {
      title: "Lyric MBC",
      // link: 'link-to-lyric-mbc' // Replace with your actual link or route
      description:
        "Standardise contracts and minimum rates for lyricists contributing to various platforms to establish industry norms through collective bargaining.",
    },
    {
      title: "TV MBC",
      //link: 'link-to-tv-mbc' // Replace with your actual link or route
      description:
        "Standardise contracts and minimum rates for TV writers to establish industry norms through collective bargaining..",
    },
    {
      title: "Web MBC",
      //link: 'link-to-web-mbc' // Replace with your actual link or route
      description:
        "Standardise contracts and minimum rates for writers contributing to OTT platforms to establish industry norms through collective bargaining.",
    },
    {
      title: "Model NDA",
      link: "http://cms.swaindia.org/storage/uploads/Model%20NDA%20(recommended%20by%20SWA).pdf",
      target: "_blank",
      description:
        "Standardise contracts and minimum rates for writers contributing to OTT platforms to establish industry norms through collective bargaining.",
    },
    {
      title: "Standard Release Form",
      link: "https://cms.swaindia.org/storage/uploads/Standard_Release_Form_2020_(Proposed%20by%20SWA).pdf",
      target: "_blank",
      description: "The Standard Release Form proposed by SWA.",
    },
  ];
  // set styling for card
  function MbcCard({ item, index }) {
    return (
      <div className="serviceBox">
        <div className="service-icon">
          <span>
            <img src={bhavnex_MBC} style={{ height: "5rem" }} alt="mbc_img" />
          </span>
        </div>
        <h3 className="title">
          {/* <a href={item.link} target={item.target} className="read-more">
            {item.title}
          </a> */}
          {/* <Link to={item.link} className="read-more">
            {item.title}
          </Link> */}
          {item.link ? (
            <Link to={item.link} target={item.target} className="read-more">
              {item.title}
            </Link>
          ) : (
            <OverlayTrigger
              key={index}
              placement="top"
              overlay={
                <Tooltip id={`tooltip-${index}`} style={{ fontSize: "1.8rem" }}>
                  Coming Soon
                </Tooltip>
              }
              delay={{ show: 100, hide: 0 }}
            >
              <span style={{ cursor: "pointer" }}>{item.title}</span>
            </OverlayTrigger>
          )}
        </h3>
        <p className="description">{item.description}</p>
        {/* {item.link ? (
          <a href={item.link} target={item.target} className="read-more">
            Read More
          </a>
        ) : (
          <Tooltip id={`tooltip-${index}`} style={{ fontSize: "1.8rem" }}>
            Coming Soon
          </Tooltip>
        )} */}
      </div>
    );
  }

  return (
    <>
      {isMobile ? <MobileNavbar /> : <SwaNavbar />}

      {/* <div className="dsc-page margin-bt"> */}
      <div className="col-md-12">
        <div className="breadcumb-wrapper">
          <div className="container">
            <h1 className="title_download bold text-center mbc">
              {" "}
              MBC (Minimum Basic Contract){" "}
            </h1>
            <p className="mission-statement">
              The aim of all four MBC sub-committees (Film, TV, Lyric & Web) is
              to draft the Minimum Basic Contracts (model contracts) and the
              minimum rate cards, and ensure, via collective bargain and
              negotiations, that they are accepted as standard industry
              practices. The Minimum Basic Contracts safeguard the fees, credit,
              copyright, etc. of screenwriters.
            </p>
          </div>
        </div>
      </div>
      <div className="dsc-page">
        <div className="container">
          {/* First Row */}
          <div
            className="row"
            style={{ marginTop: "0.5rem", justifyContent: "end" }}
          >
            {items.slice(0, 3).map((item, index) => (
              <div key={index} className="col-md-4 col-sm-6">
                <MbcCard item={item} />
              </div>
            ))}
          </div>

          {/* Second Row */}
          <div
            className="row"
            style={{ marginTop: "0.5rem", justifyContent: "end" }}
          >
            {items.slice(3, 6).map((item, index) => (
              <div key={index} className="col-md-4 col-sm-6">
                <MbcCard item={item} />
              </div>
            ))}
          </div>
        </div>
        {/* {items.map((item, index) => (
            item.link ? (
              <Link to={item.link}  target={item.target} className="dsc-card-link" key={index} style={{ textDecoration: 'none' }}>
                <Card className="dsc-card2"
                  hoverable
                  style={{
                    width: '300px',
                    height: '173px', // Height is sqrt(3)/2 * width to maintain the hexagon aspect ratio
                    backgroundColor: '#f0f2f5',
                    marginBottom: '20px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                    clipPath: 'polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%)',
                    display: 'flex',
                    color: '#000',
                    justifyContent: 'center',
                    alignItems: 'center',

                  }}
                  onMouseOver={(e) => {
                    const card = e.currentTarget;
                    card.style.transform = 'scale(1.05)';
                    card.style.boxShadow = '0 6px 12px rgba(0, 0, 0, 0.2)';
                    card.style.backgroundColor = 'rgb(211, 162, 33)';
                    card.style.cursor = 'pointer' // Change background color on hover
                    const textElements = card.querySelectorAll('.card-title'); // Use the correct selector for your text elements
                    textElements.forEach(el => el.style.color = '#fff');

                  }}
                  onMouseOut={(e) => {
                    const card = e.currentTarget;
                    const textElements = card.querySelectorAll('.card-title'); // Use the correct selector for your text elements
                    textElements.forEach(el => el.style.color = '#000');

                    card.style.transform = 'scale(1)';
                    card.style.backgroundColor = '#f0f2f5'; // Change background color on hover
                    card.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)';
                  }}
                >
                  <div className="card-content">
                    <h2 className="card-title">{item.title}</h2>
                  </div>
                </Card>
              </Link>
            ) : (
              <OverlayTrigger
                key={index}
                placement="top"
                overlay={<Tooltip id={`tooltip-${index}`} style={{fontSize:"1.8rem"}}>Coming Soon</Tooltip>}
                delay={{ show: 100, hide: 0 }}
              >
                <div className="dsc-card-link" style={{ textDecoration: 'none' }}>
                  <Card className="dsc-card2"
                    hoverable
                    style={{
                      width: '300px',
                      height: '173px', // Height is sqrt(3)/2 * width to maintain the hexagon aspect ratio
                      backgroundColor: '#f0f2f5',
                      marginBottom: '20px',
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                      transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                      clipPath: 'polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%)',
                      display: 'flex',
                      color: '#000',
                      justifyContent: 'center',
                      alignItems: 'center',

                    }}
                    onMouseOver={(e) => {
                      const card = e.currentTarget;
                      card.style.transform = 'scale(1.05)';
                      card.style.boxShadow = '0 6px 12px rgba(0, 0, 0, 0.2)';
                      card.style.backgroundColor = 'rgb(211, 162, 33)';
                      card.style.cursor = 'pointer' // Change background color on hover
                      const textElements = card.querySelectorAll('.card-title'); // Use the correct selector for your text elements
                      textElements.forEach(el => el.style.color = '#fff');

                    }}
                    onMouseOut={(e) => {
                      const card = e.currentTarget;
                      const textElements = card.querySelectorAll('.card-title'); // Use the correct selector for your text elements
                      textElements.forEach(el => el.style.color = '#000');

                      card.style.transform = 'scale(1)';
                      card.style.backgroundColor = '#f0f2f5'; // Change background color on hover
                      card.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)';
                    }}
                  >
                    <div className="card-content">
                      <h2 className="card-title">{item.title}</h2>
                    </div>
                  </Card>
                </div>
              </OverlayTrigger>
            )
          ))} */}
      </div>
      {/* </div> */}
      <div className="mbc-container">
        <Link to="/">
          <button className="nice-blue-button scroll-button">
            <i
              class="fa-solid fa-house"
              style={{
                color: " #ffffff",
                marginLeft: "0rem",
                marginRight: "1rem",
              }}
            >
              {" "}
            </i>
            Back to Home
          </button>
        </Link>
      </div>
      <SwaFooter />
    </>
  );
};

export default MBC;
