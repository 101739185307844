import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card } from "antd";
import "./ScreenWriters.css";
import Lawyer from "../../img/lawyer.png";
import Media from "../../img/media.png";
import Mbc from "../../img/mbc.png";
import Dsc from "../../img/dsc.png";
import Education from "../../img/education.png";
import Awards from "../../img/awards.png";
import bhavnex_Lawyer from "../../img/bhavnex_Lawyer.png";
import bhavnex_MBC from "../../img/bhavnex_MBC.png";
import bhavnex_DSC from "../../img/bhavnex_DCS.png";
import bhavnex_Education from "../../img/bhavnex_Education.png";
import bhavnex_Media from "../../img/bhavnex_Media.png";
import bhavnex_Awards from "../../img/bhavnex_Awards.png";

const { Meta } = Card;

function SwaCards() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [hoverIndex, setHoverIndex] = useState(-1); // New state to keep track of which card is hovered

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const iconSize = windowWidth < 768 ? "40px" : "60px"; // Smaller icons on smaller screens
  const fontSize = windowWidth < 768 ? "14px" : "16px"; // Smaller font size for smaller screens
  const cardPadding = windowWidth < 768 ? "10px" : "20px"; // Less padding for smaller screens

  const cards = [
    {
      path: "/askourlawyer",
      imgSrc: bhavnex_Lawyer,
      hoverImgSrc: Lawyer,
      title: "LAWYER",
      description: "Legal counsel appointment (exclusively for members).",
    },
    {
      path: "/mbc",
      imgSrc: bhavnex_MBC,
      hoverImgSrc: Mbc,
      title: "MBC",
      description:
        "(Minimum Basic Contract) Outlining key terms and conditions for writers' agreements.",
    },
    {
      path: "/dsc",
      imgSrc: bhavnex_DSC,
      hoverImgSrc: Dsc,
      title: "DSC",
      description:
        "(Dispute Settlement Committee) Addressing and resolving conflicts through a structured resolution process.",
    },
    {
      path: "/education",
      imgSrc: bhavnex_Education,
      hoverImgSrc: Education,
      title: "EDUCATION",
      description: "Enhancing writing skills and knowledge.",
    },
    {
      path: "/media",
      imgSrc: bhavnex_Media,
      hoverImgSrc: Media,
      title: "MEDIA",
      description: "The latest news and updates.",
    },
    {
      path: "/events",
      imgSrc: bhavnex_Awards,
      hoverImgSrc: Awards,
      title: "EVENTS",
      description: "Gatherings for wordsmiths & storytellers.",
    },
    // ... add other cards as needed
  ];

  return (
    <>
      <div>
        <Row gutter={[16, 16]} style={{ marginTop: "5rem" }}>
          {cards.map((card, index) => (
            <Col key={index} xs={24} sm={12} lg={8}>
              <Link className="a" to={card.path}>
                <Card
                  className="Card"
                  style={{ padding: cardPadding }}
                  onMouseEnter={() => setHoverIndex(index)}
                  onMouseLeave={() => setHoverIndex(-1)}
                >
                  <img
                    alt={`${card.title} Icon`}
                    src={hoverIndex === index ? card.hoverImgSrc : card.imgSrc}
                    className="embossed-icon"
                    style={{
                      width: iconSize,
                      height: iconSize,
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                  />
                  <Meta
                    title={
                      <div
                        className="card_title"
                        style={{ fontSize: fontSize }}
                      >
                        {card.title}
                      </div>
                    }
                    description={
                      <div
                        className="card_description"
                        style={{
                          fontSize: fontSize,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: "3",
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {card.description}
                      </div>
                    }
                  />
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
}

export default SwaCards;
