import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components"; // Assuming styled-components for CSS-in-JS
// import { Card, Button, Badge, Container, Row, Col } from 'react-bootstrap';
import SrishtiMallickImage from "../../img/WhatsApp Image 2024-03-27 at 5.04.48 PM.jpeg";
import "./AskLawyer.css";
import {
  Card,
  Button,
  Badge,
  Container,
  Row,
  Col,
  Image,
} from "react-bootstrap"; // Assuming Bootstrap for styling
import SwaNavbar from "../Screenwriters/SwaNavbar";
import SwaFooter from "../Screenwriters/SwaFooter";
import MobileNavbar from "../Screenwriters/MobileNavbar";

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 2000,
};
const AskLawyer = () => {
  const [isMobile, setIsMobile] = useState(() => {
    const initialValue = localStorage.getItem("isMobile");
    return initialValue ? JSON.parse(initialValue) : false;
  });
  const sliderRef = useRef(null);

  useEffect(() => {
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth < 970);
      localStorage.setItem("isMobile", JSON.stringify(window.innerWidth < 970));
    };

    checkScreenWidth();
    window.scrollTo(0, 0);

    window.addEventListener("resize", checkScreenWidth);

    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);
  const StyledContainer = styled(Container)`
    margin-top: 50px;
  `;

  const StyledCard = styled(Card)`
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
  `;

  const StyledCardHeader = styled(Card.Header)`
    background-color: #007bff;
    color: white;
  `;

  const StyledCardFooter = styled(Card.Footer)`
    background-color: #f8f9fa;
    color: #6c757d;
  `;

  const StyledImageCol = styled(Col)`
    display: flex;
    justify-content: center;
  `;

  const StyledTextCol = styled(Col)`
    padding-right: 30px;
  `;
  const qaSections = [
    {
      question:
        "What are the basic clauses to look out for while signing an agreement/contract with a producer?",
      date: "1st January 2016 LAW",
      answers: [
        "Do not sign the contract before thoroughly reading and understanding each and every covenant of the agreement. If you are unable to understand any of the clauses in your agreement, consult a legal expert to have it explained. SWA now offers this guidance to its members through its Legal Officer, free of charge.",
        "As much as possible, insist on a ‘copyright assignment agreement’ agreement rather than a ‘work-for-hire’ or ‘commissioned work’ agreement.",
        "Make sure that the amount of consideration is broken down in various stages/tranches instead of a one-time payment of the bulk amount. This would ensure that if the contract is terminated abruptly, you are at least paid a compensation due to you till the stage of such termination.",
        "Section 19(4) of the Copyright Act, 1957 gives you the right to have the rights in your work revert back to you in case of non-use by the producer of such rights within a year. However, such right is waivable through the contract. See if your contract requires you to waive your right under Section 19(4) of the Copyright Act. If it does, ensure that there is at least a clause in your agreement that the producer would have to return your rights to you or that such rights will automatically return to you if not used by the producer for a specific period. This would ensure that if the producer acquires the right in your script and later does not end up making a movie on it, he cannot keep the rights in your script in perpetuity.",
        `        Termination – make sure that your termination clause is not one-sided and unfair. Ensure that your termination clause address the question of who shall retain the rights of your script upon termination of the contract by either of the parties:

In case the producer wishes to retain the rights over the script upon termination, ensure that there is a clause to the effect that the rights over the script shall not vest with the producer till the consideration due to you till the stage of termination is paid to you.`,
        "Credit – Ensure that the credit clause survives termination of your agreement. Even if the agreement is terminated by either of the parties, you shall still be credited for your work.",
        "Royalty – Make sure that your contract addresses the percentage of royalty due to you for your copyrighted work. Your royalty is separate from the consideration and any clause which states that royalty is included in the consideration is not valid. Your royalty is and should be separate from your consideration.",
        "Make sure that your contract specifically mentions and lists down the particular rights which are being assigned to the producer rather than merely stating ‘all rights.’",
        "Union/Guild Jurisdiction – Ensure that the jurisdiction of SWA or any other writer’s union/guild is not excluded from the contract. If there is a dispute at a later date and the writer has waived the right to raise any grievance with or raise the jurisdiction of his or her union/guild, such union including SWA shall not be able to intervene to resolve the dispute on your behalf.",
        "These are only the basic clauses that one needs to look out for in the contract. However, other than ensuring the presence of these above clauses, do study your agreement thoroughly for other clauses that might pose a potential danger to you.",
      ],
    },
    {
      question:
        "The Producer has approved my script and is asking for modifications in my script/screenplay, but we do not have a contract yet. What do I do?",
      date: "15th May 2017 LAW",
      answers: [
        "Request the producer to enter into an agreement with you for assignment of copyright in your script. Do ensure that the agreement is in conformity with the basic terms and conditions as stated in these FAQs.",
        "If the producer is unable to enter into a full-fledged agreement at the moment, request the Producer to at least enter into a Memorandum of Understanding (MoU) with you for a copyright assignment/license in your script before you work on any modifications suggested by the producer. An MoU is a preliminary document crystallizing the intention of the parties to enter into a Long Form Agreement at a later date. Please note that an MoU is not sufficient by itself and is only an understanding between the parties of the basic terms and conditions of their deal. Do not sign the MoU or any other legal document to that effect without reading and thoroughly understanding it and preferably having it examined by a lawyer.",
      ],
    },
    {
      question:
        "I have signed a contract with a producer but now I wish to get out of it. Can I do that???",
      date: "     15th May 2017 LAW ",
      answers: [
        "An agreement forms a legal relationship between the parties where one party promises to deliver the goods/services (which, in your case, is the script/screenplay) and the other party promises to pay a valuable consideration for the goods/services in return. When such a legal promise is entered into, the breach of such promise (by either party) would naturally attract a penalty in the form of damages. The party breaching the promise has to compensate the party who suffered a loss (monetary or otherwise). Avoiding a contract or non-performance of a contract amounts to a breach and would entitle the producer for compensation from you for the losses suffered due to your breach.",
        "The amount for compensation for such losses would vary from case to case and would solely depend upon the contract you have entered into.",
      ],
    },
    {
      question:
        "I’m a swa member. Can the legal officer draft a contract for me?",
      date: "     15th May 2017 LAW ",
      answers: [
        "An agreement forms a legal relationship between the parties where one party promises to deliver the goods/services (which, in your case, is the script/screenplay) and the other party promises to pay a valuable consideration for the goods/services in return. When such a legal promise is entered into, the breach of such promise (by either party) would naturally attract a penalty in the form of damages. The party breaching the promise has to compensate the party who suffered a loss (monetary or otherwise). Avoiding a contract or non-performance of a contract amounts to a breach and would entitle the producer for compensation from you for the losses suffered due to your breach.",
        `

          While the SWA’s Legal Officer can certainly guide and advise its members on an existing contract, the services of drafting a contract for the writers do not fall in the dominion of the services of Legal Officer. However, we do have a database of competent advocates who can offer these services to you either for free or for a subsidized cost. Drop us an email.
      `,
      ],
    },
    // Add more sections as needed
  ];
  useEffect(() => {
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth < 970);
      localStorage.setItem("isMobile", JSON.stringify(window.innerWidth < 970));
    };

    checkScreenWidth();

    window.addEventListener("resize", checkScreenWidth);

    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);
  return (
    <>
      {isMobile ? <MobileNavbar /> : <SwaNavbar />}
      <section className="content-container">
        {/* <Container className="ask-our-lawyer-container border-container"> */}
        {/* <Row className="service-section  mb"> */}
        {/* <Col xs={12}> */}
        <div className="breadcumb-wrapper">
          <div className="container">
            <h1 className="headings">Ask Our Lawyer</h1>
            <p className="service-description">
              SWA's full-time Legal Officer is available for all SWA members to
              answer queries, and offer legal advice and guidance for contracts
              and Copyright related issues. This service is free of charge. SWA
              members can meet our Legal Officer by taking an appointment. They
              may also send their queries by email at{" "}
              <a href="mailto:legal.officer@swaindia.org">
                legal.officer@swaindia.org
              </a>
              .
            </p>
          </div>
        </div>
        <div className="container">
          <p className="service-timings">
            <strong>Appointment timings:</strong> 3:00 - 6:00 PM, Monday to
            Friday.
          </p>
          <p className="appointment-button">
            {/* <Link to="https://nexzenworks.in/swa_account/login"> */}
            <button
              className="nice-blue-button scroll-button blink-button"
              style={{ cursor: "auto" }}
            >
              Book an Appointment
            </button>
            {/* </Link> */}
          </p>
          {/* </Col> */}
          {/* </Row> */}

          {/* <Row className="profile-section mb">
          <Col md={4} className="profile-image-col text-center">
            <Image
              src={SrishtiMallickImage}
              alt="Legal Officer"
              className="profile-image2"
            />
          </Col>
          <Col md={8} className="profile-info-col">
            <div className="profile-flex-container">
              <div className="profile-details">
                <h2 className="profile-name2">Diwakar</h2>
                <h5 className="profile-title">Legal Officer, SWA</h5>
              </div>
              <div className="appointment-button">
                <Link to="https://nexzenworks.in/swa_account/login">
                  <button className="nice-blue-button scroll-button blink-button">
                    Book an Appointment
                  </button>
                </Link>
              </div>
            </div>
            <p className="profile-description">
              Diwakar is a dynamic legal professional currently serving as the
              Legal Officer at Screenwriters Association. With over 12 years of
              experience, he has a proven track record in contracts management,
              litigation support, and legal compliance, specializing in
              copyright, intellectual property, cyber, and data privacy laws.
              Prior to joining SWA, Diwakar led the legal operations at ALTT,
              also acting as the Grievance Officer for ALTT; and has also held
              managerial role for the group companies of Balaji Telefilms Ltd,
              where he managed legal affairs, litigation management and
              statutory compliances. As the founder of Consult Your Contract, he
              provided pivotal legal advisory services to clients in the
              entertainment industry, fostering partnerships and ensuring
              regulatory compliance. Combining legal expertise with a passion
              for creative storytelling, he aims to empower and protect the
              rights of authors while contributing to the growth and diversity
              of the media industry in India.
            </p>
          </Col>
        </Row> */}
          <h1 className="headings"> Q & A with Legal Officer </h1>

          {/* Q&A Section */}
          <div className="q-and-a-container">
            <Slider {...settings}>
              {qaSections.map((qa, index) => (
                <div key={index} className="q-and-a-block">
                  <h3 className="question2">{qa.question}</h3>
                  <div className="date-and-law">{qa.date}</div>
                  {qa.answers.map((answer, ansIndex) => (
                    <p key={ansIndex} className="answer">
                      {answer}
                    </p>
                  ))}
                </div>
              ))}
            </Slider>
          </div>
          {/* <div className="q-and-a-container">
            <Slider {...settings}>
              {qaSections.map((qa, index) => (
                <div key={index} className="q-and-a-block">
                  <h1 className="headings"> Q & A with Legal Officer </h1>
                  <h3 className="question2">{qa.question}</h3>
                  <div className="date-and-law">{qa.date}</div>
                  {qa.answers.map((answer, ansIndex) => (
                    <p key={ansIndex} className="answer">
                      {answer}
                    </p>
                  ))}
                </div>
              ))}
            </Slider>
          </div> */}
        </div>
        {/* </Container> */}
      </section>
      <SwaFooter />
    </>
  );
};

export default AskLawyer;
