import React, { useState, useEffect } from "react";
import axios from "axios";
import MobileNavbar from "../Screenwriters/MobileNavbar";
import SwaNavbar from "../Screenwriters/SwaNavbar";
import SwaFooter from "../Screenwriters/SwaFooter";
import Faqcategory from "./FaqCateggory"; // Fix the import typo
import "./Faq.css";

function FAQ() {
  const [selectedcategory, setSelectedcategory] = useState(null);
  const [isMobile, setIsMobile] = useState(() => {
    const initialValue = localStorage.getItem("isMobile");
    return initialValue ? JSON.parse(initialValue) : false;
  });
  useEffect(() => {
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth < 970);
      localStorage.setItem("isMobile", JSON.stringify(window.innerWidth < 970));
    };

    checkScreenWidth();
    window.scrollTo(0, 0);

    window.addEventListener("resize", checkScreenWidth);

    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);
  const [faqData, setFaqData] = useState([]);

  // handlecategorySelect function
  const handlecategorySelect = (category) => {
    setSelectedcategory(category);
  };

  // API call for FAQs and match category title
  useEffect(() => {
    const fetchFaqs = async () => {
      try {
        const response = await axios.get(
          "https://cms.swaindia.org/api/faqs"
        );
        console.log("FAQs:", response.data);
        const faqs = response.data.data.faqs;
        const matchedcategory = faqs.find(
          (faq) => faq.title === selectedcategory
        );
        console.log(matchedcategory, faqs, selectedcategory, "matchedcategory");
        if (matchedcategory) {
          // If category matches, fetch faq_data for that category
          const faqData = matchedcategory.faq_data;
          console.log("FAQ Data:", faqData);
          setFaqData(faqData);
        }
      } catch (error) {
        console.error("Error fetching FAQs:", error);
      }
    };

    if (selectedcategory) {
      fetchFaqs();
    }
  }, [selectedcategory]);

  // Initial selection of the first category
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          "https://cms.swaindia.org/api/faqs"
        );
        console.log("Categories:", response.data);
        const categories = response.data.data.faqs.map(
          (category) => category.title
        );
        if (categories.length > 0) {
          setSelectedcategory(categories[0]); // Set the first category initially
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);
  console.log(selectedcategory, "selectedCatgeiryfruf");

  return (
    <>
      {isMobile ? <MobileNavbar /> : <SwaNavbar />}

      <div className="content-container">
        {/* <div
          className="container margin-bt border-container"
          style={{ marginTop: "3.5rem", marginBottom: "3.5rem" }}
        >   */}
         
         <div className="breadcumb-wrapper">
          <div className="container">
          <h1 className="headings">Frequently Asked Questions</h1>
            {/* <p className="service-description">
              SWA's full-time Legal Officer is available for all SWA members to
              answer queries, and offer legal advice and guidance for contracts
              and Copyright related issues. This service is free of charge. SWA
              members can meet our Legal Officer by taking an appointment. They
              may also send their queries by email at{" "}
              <a href="mailto:legal.officer@swaindia.org">
                legal.officer@swaindia.org
              </a>
              .
            </p> */}
          </div>
        </div>
        <div
          className="container margin-bt"
          style={{ marginTop: "3.5rem", marginBottom: "3.5rem" }}
        >
          <div className="row" style={{ marginBottom: "3.rem" }}>
            {/* left side category titles */}
            <div className="col-md-4">
              <h1 className="title_faq bold text-center">FAQs</h1>
              <Faqcategory setSelectedcategory={handlecategorySelect} />
            </div>

            {/* right side map FAQs data from API */}
            <div className="col-md-8">
              {faqData.length > 0 && (
                <>
                  <h1 className="title_faq bold text-center">
                    {selectedcategory}
                  </h1>
                  {faqData.map((item, index) => (
                    <div key={index}>
                      <input
                        type="checkbox"
                        id={`question${index}`}
                        name="q"
                        className="questions"
                      />
                      <div className="plus">+</div>
                      <label htmlFor={`question${index}`} className="question">
                        {item.quetions}
                      </label>
                      <div
                        className="answers"
                        dangerouslySetInnerHTML={{ __html: item.answer }}
                      ></div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>

        <SwaFooter />
      </div>
    </>
  );
}

export default FAQ;
