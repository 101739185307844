import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MediationFormPDF from "../../pdf/DSC Mediation Form Latest.pdf";
import "./DSC.css"; // Your CSS file for styling
import MobileNavbar from "../Screenwriters/MobileNavbar";
import SwaNavbar from "../Screenwriters/SwaNavbar";
import { Card, Table, ListGroup } from "react-bootstrap";
import {
  FaTimes,
  FaCheck,
  FaCreditCard,
  FaRegCalendarAlt,
} from "react-icons/fa";
import SwaFooter from "../Screenwriters/SwaFooter";
import bhavnex_DSC from "../../img/bhavnex_DCS.png";

const DSC = () => {
  const [isMobile, setIsMobile] = useState(() => {
    const initialValue = localStorage.getItem("isMobile");
    return initialValue ? JSON.parse(initialValue) : false;
  });
  useEffect(() => {
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth < 970);
      localStorage.setItem("isMobile", JSON.stringify(window.innerWidth < 970));
    };

    checkScreenWidth();
    window.scrollTo(0, 0);

    window.addEventListener("resize", checkScreenWidth);

    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);
  const items = [
    {
      title: "DSC Bye-Laws",
      link: "https://cms.swaindia.org/storage/uploads/SWA%20DSC%20Bye%20Laws.pdf", // You'll need to convert the PDF to an HTML page or component and route it here
      // "http://cms.swaindia.org/uploads/SWA%20Bye%20laws%20-2022.pdf"
      target: "_blank",
      description:
        "Guidelines for resolving disputes involving members where Association involvement is necessary.",
    },
    {
      title: "Mediation Form",
      link: "https://cms.swaindia.org/storage/uploads/MEDIATION%20FORM.pdf", // Link to your mediation form page or component
      target: "_blank",
      description:
        "Approach the Dispute Settlement Committee (DSC) of the Screenwriters Association (SWA) to mediate a dispute.",
    },
    {
      title: "Copyright Act",
      link: "https://cms.swaindia.org/storage/uploads/Copyright%20Amendment%20Act%202012%20(GoI).pdf",
      target: "_blank",
      description:
        "Safeguarding creators' original works, granting them exclusive rights and protection against unauthorised use.",
    },
    {
      title: "Copyright Rules",
      link: "https://cms.swaindia.org/storage/uploads/Copyright%20Rules%202013%20(GoI).pdf",
      target: "_blank",
      description: "Rules governing fair and legal usage of creative works.",
    },
    {
      title: "SWA Bye-Laws",
      link: "https://cms.swaindia.org/uploads/SWA%20Bye%20laws%20-2022.pdf",
      target: "_blank",
      description:
        "Guiding force for the Executive Committee to govern the SWA as per the Constitution.",
    },
    {
      title: "Ask Your DSC FAQ",
      link: "/faq", // Link to your FAQ page or section
      description:
        "Swiftly address common inquiries, access relevant information and find solutions to your questions.",
    },
  ];

  // set styling for card
  function DscCard({ item }) {
    return (
      <div className="serviceBox">
        {/* <div className="service1"> */}
        <div className="service-icon">
          <span>
            {/* <i className="fa fa-globe"></i> */}
            <img src={bhavnex_DSC} style={{ height: "6rem" }} alt="dsc_icon" />
          </span>
        </div>
        {/* </div> */}

        <h3 className="title">
          {/* <a href={item.link} target={item.target} className="read-more">
            {item.title}
          </a> */}
          <Link to={item.link}  target={item.target} className="read-more">
            {item.title}
          </Link>
        </h3>
        <p className="description">{item.description}</p>
        {/* <a href={item.link} target={item.target} className="read-more">
          Read More
        </a> */}
        {/* </div> */}
      </div>
    );
  }
  return (
    <>
      {isMobile ? <MobileNavbar /> : <SwaNavbar />}

      {/* <div className="dsc-page  margin-bt"> */}
      <div className="col-md-12">
        <div className="breadcumb-wrapper">
          <div className="container">
            <h1 className="title_download bold text-center mbc">
              {" "}
              DSC (Dispute Settlement Committee){" "}
            </h1>
            <p className="mission-statement">
              The Dispute Settlement Sub-Committee (DSC) is a QUASI-LEGAL body
              set up by the Screenwriters Association for the sole purpose of
              MEDIATION and CONCILIATION between its members and members of
              other craft associations, including producers/employers.ur
              industry, serving as esteemed markers of accomplishment.
            </p>
          </div>
        </div>
      </div>
      <div className="dsc-page">
        <div className="container">
          {/* First Row */}
          <div
            className="row"
            style={{ marginTop: "0.5rem", justifyContent: "end" }}
          >
            {items.slice(0, 3).map((item, index) => (
              <div key={index} className="col-md-4 col-sm-6">
                <DscCard item={item} />
              </div>
            ))}
          </div>

          {/* Second Row */}
          <div
            className="row"
            style={{ marginTop: "0.5rem", justifyContent: "end" }}
          >
            {items.slice(3, 6).map((item, index) => (
              <div key={index} className="col-md-4 col-sm-6">
                <DscCard item={item} />
              </div>
            ))}
          </div>
        </div>

        {/* new */}
        {/* <div class="container"> */}
        {/* first Row start*/}
        {/* <div className="row">
              <div className="col-md-3 col-sm-6"></div>
              <div className="col-md-3 col-sm-6">
                {items.length > 0 && <DscCard item={items[0]} />}
              </div>
            </div> */}
        {/* Second Row */}
        {/* <div className="row" style={{ marginTop: "0.5rem" }}>
              {items.slice(1, 5).map((item, index) => (
                <div className="col-md-3 col-sm-6">
                  <DscCard key={index} item={item} />
                </div>
              ))}
            </div> */}
        {/* Third Row */}
        {/* <div className="row">
              <div className="col-md-3 col-sm-6"></div>
              <div className="col-md-3 col-sm-6"></div>
              <div className="col-md-3 col-sm-6">
                {items.length > 5 && <DscCard item={items[5]} />}
              </div>
            </div>
          </div> */}
        {/* {items.map((item, index) => (
            <Link
              to={item.link}
              target={item.target}
              className="dsc-card-link"
              key={index}
              style={{ textDecoration: "none" }}
            >
              <Card
                className="dsc-card2"
                hoverable
                style={{
                  width: "300px",
                  height: "173px",
                  backgroundColor: "#f0f2f5",

                  border: "1px solid rgb(211, 162, 33)",
                  marginBottom: "20px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  transition:
                    "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
                  clipPath:
                    "polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%)",
                  display: "flex",
                  color: "#000",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onMouseOver={(e) => {
                  const card = e.currentTarget;
                  e.currentTarget.style.transform = "scale(1.05)";
                  e.currentTarget.style.boxShadow =
                    "0 6px 12px rgba(0, 0, 0, 0.2)";
                  e.currentTarget.style.cursor = "pointer"; // Change background color on hover
                  e.currentTarget.style.backgroundColor = "rgb(211, 162, 33)"; // Change background color on hover
                  const textElements = card.querySelectorAll(".card-title"); // Use the correct selector for your text elements
                  textElements.forEach((el) => (el.style.color = "#fff"));
                }}
                onMouseOut={(e) => {
                  const card = e.currentTarget;
                  const textElements = card.querySelectorAll(".card-title"); // Use the correct selector for your text elements
                  textElements.forEach((el) => (el.style.color = "#000"));

                  e.currentTarget.style.transform = "scale(1)";
                  e.currentTarget.style.backgroundColor = "#f0f2f5"; // Change background color on hover
                  e.currentTarget.style.boxShadow =
                    "0 4px 8px rgba(0, 0, 0, 0.1)";
                }}
              >
                <div className="card-content">
                  <h2 className="card-title">{item.title}</h2>
                </div>
              </Card>
            </Link>
          ))} */}
      </div>
      {/* </div> */}
      <div class="mbc-container">
        <Link to="/">
          <button class="nice-blue-button scroll-button">
            {" "}
            <i
              class="fa-solid fa-house"
              style={{
                color: " #ffffff",
                marginLeft: "0rem",
                marginRight: "1rem",
              }}
            >
              {" "}
            </i>
            Back to Home
          </button>
        </Link>
      </div>
      <SwaFooter />
    </>
  );
};

export default DSC;
