import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShieldAlt,
  faCopy,
  faGavel,
  faUserCog,
  faTimesCircle,
  faCheckCircle,
  faHandshake,
  faCogs,
  faUserSecret,
  faPencilAlt,
  faBan,
  faExclamationTriangle,
  faComments,
} from "@fortawesome/free-solid-svg-icons";
import "./Terms.css"; // Make sure to create a corresponding CSS file
import SwaNavbar from "../Screenwriters/SwaNavbar";
import SwaFooter from "../Screenwriters/SwaFooter";
import MobileNavbar from "../Screenwriters/MobileNavbar"; // Importing CSS for styling

const TermsAndConditions = () => {
  const [isMobile, setIsMobile] = useState(() => {
    const initialValue = localStorage.getItem("isMobile");
    return initialValue ? JSON.parse(initialValue) : false;
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth < 970);
      localStorage.setItem("isMobile", JSON.stringify(window.innerWidth < 970));
    };

    checkScreenWidth();

    window.addEventListener("resize", checkScreenWidth);

    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);
  return (
    <>
      {isMobile ? <MobileNavbar /> : <SwaNavbar />}
      <div className="breadcumb-wrapper">
        <div className="container">
          <h1 className="headings"> Terms and Conditions for Website Use</h1>
        </div>
      </div>

      <div className="container" style={{marginTop:'1rem'}}>
        <section className="term-section"  style={{textAlign:'justify'}}>
          <h1>
            {" "}
            <FontAwesomeIcon icon={faCheckCircle} /> 1. Acceptance of Terms and
            Conditions
          </h1>
          <ul>
            <li className="li-p">
              The https://www.swaindia.org/ website ("Website") is an
              internet-based informative portal owned and operated by
              Screenwriters Association ("SWA", “We”, “Us”), a Trade Union for
              the rights of Screenwriters...
            </li>
            <li className="li-p">
              Through the Website, SWA provides you ("User", “You”, “Your”)
              information related to SWA, its function, membership, work
              registration, contact information, standard agreements and policy
              drafts, etc. SWA also provides Users a platform for online
              registration of their work, booking tickets for SWA Events
              including workshops, conferences, summits, etc. and other
              functions as the time to time announced by SWA.
            </li>
            <li className="li-p">
              This User Agreement ("Agreement") sets out the general terms and
              conditions regarding the use of the Website by any User. In
              addition to this Agreement and depending on the Services (defined
              below) opted for by the User, the User shall be required to read
              and accept the relevant Terms of Service (TOS) for each Service
              given SWA Constitution and Bylaws, which may be updated or
              modified by SWA from time to time. It is clarified that different
              Services may different have TOS. Such TOS shall be deemed to be a
              part of this Agreement.
            </li>
            <li>
              Use of the Website is offered to the User conditioned on
              acceptance without modification of all the terms, conditions and
              notices contained in this Agreement and the TOS, as may be posted
              on the Website from time to time. For the removal of doubts, it is
              clarified that the use of the Website by the User constitutes an
              acknowledgment and acceptance by the User of this Agreement and
              the TOS. If the User does not agree with any part of such terms,
              conditions, and notices, the User must not use the Website.
            </li>
          </ul>
        </section>

        <section className="term-section"  style={{textAlign:'justify'}}>
          <h1>
            {" "}
            <FontAwesomeIcon icon={faCogs} /> 2. Services Provided by the
            Website and Payment Terms and Conditions
          </h1>
          <ul>
            <li className="li-p">
              SWA in its sole discretion can provide any service through the
              Website including but not limited to conducting events/workshops
              for members and nonmembers, providing SWA membership, providing
              online script registration facilities, providing facilities for
              readmission, renewal, upgradation of membership, accepting
              donations, transporting the documents like SWA membership card,
              Constitution book, etc. (Hereinafter referred as ‘Service’).
            </li>
            <li className="li-p">
              SWA is entitled to accept the payment from the User for any
              Service (Hereinafter referred as Fee by any valid Debit and Credit
              Cards and other authorized method. The SWA reserves right to alter
              the Fee from time to time.
            </li>
            <li className="li-p">
              The User shall be liable to pay all applicable charges including
              internet handling charges, fees, duties, taxes, levies, and
              assessments for availing the Services, whenever the same is
              applicable (Applicable Charges).
            </li>
            <li className="li-p">
              User shall pay the Fee and Applicable Charges as specified by SWA.
              Except as otherwise specified herein or in any specific case by
              SWA (i) Fee are based on purchase and not actual usage, (ii)
              payment obligations are non-cancellable and Fee paid are
              non-refundable.
            </li>
            <li className="li-p">
              Members agree that if due to any technical reason, payment is done
              but the intended outcome (i.e. script-registration/membership
              renewable/readmission/up-gradation/any similar activities) remains
              unsuccessful/incomplete, the member shall inform SWA on
              contact@swaindia.org within twenty-one (21) days of such issue.
              Any information received after twenty-one (21) days or at any
              other email ID shall not be entertained.
            </li>
            <li className="li-p">
              The Users will provide SWA with a valid and updated card details
              with a valid purchase order for the Service or any document
              reasonably acceptable to Us. You have authorized Us to deduct the
              Fee and Applicable Charges (if any) as per the payment mode chosen
              by You. You are responsible for providing SWA complete and
              accurate billing information and notify Us of any changes to such
              information.
            </li>
            <li className="li-p">
              SWA shall be entitle to terminate or suspend the Service at the
              ground of the nonpayment of the Fee and Applicable Charges.
            </li>
            <li className="li-p">
              Please note that the ‘SWAIND header’ is used to send transactional
              updates to You vide Our Accounts Portal.
            </li>
            <li className="li-p">
              The user can access and get informed by the Cancellation and
              Refund FAQ given on Website.
            </li>
          </ul>
        </section>
        <section className="term-section"  style={{textAlign:'justify'}}>
          <h1>
            {" "}
            <FontAwesomeIcon icon={faPencilAlt} className="icon" /> 4.
            Modification of Terms and Conditions
          </h1>
          <ul>
            <li className="li-p">
              SWA reserves the right to change the terms, conditions, and
              notices under which the Services are offered through the Website,
              including but not limited to the charges for the Services provided
              through the Website. The User shall be responsible for regularly
              reviewing these terms and conditions.
            </li>
          </ul>
        </section>
        <section className="term-section"  style={{textAlign:'justify'}}>
          <h1>
            <FontAwesomeIcon icon={faUserSecret} /> 5. Privacy Policy
          </h1>
          <ul>
            <li className="li-p">
              The User hereby consents, expresses and agrees that he has read
              and fully understands the Privacy Policy of SWA in respect of the
              Website, The User further consents that the terms and contents of
              such Privacy Policy are acceptable to him/her.
            </li>
          </ul>
        </section>
        <section className="term-section"  style={{textAlign:'justify'}}>
          <h1>
            <FontAwesomeIcon icon={faBan} /> 6. Limited User
          </h1>
          <li className="li-p">
            The User agrees and undertakes not to sell, trade, or resell or
            exploit for any commercial purposes, any portion of the Service. For
            the removal of doubt, it is clarified that the Website is not for
            commercial use but is specifically meant for personal use only.
          </li>
        </section>
        <section className="term-section"  style={{textAlign:'justify'}}>
          <h1>
            <FontAwesomeIcon icon={faExclamationTriangle} /> 7. Limitation of
            Liability
          </h1>
          <ul>
            <li className="li-p">
              SWA has endeavored to ensure that all the information on the
              website is accurate and up-to-date. However, information on the
              Website pages may contain inaccuracies or typographical errors and
              may be changed or updated without notice.
            </li>
            <li className="li-p">
              We are not liable for any loss arising out of the use of the
              information on this site. The information is provided without
              warranty of any kind, either express or implied, and the
              contributors do not accept any liability or responsibility for any
              loss which may arise from any reliance on the content, including,
              without limitation, any lost profits, business interruption, loss
              of programs or other data, nor for typographical or other errors
              or omissions on any page.
            </li>
            <li className="li-p">
              The content of this website is for general guidance only and
              should not be regarded as constituting legal or business advice or
              opinion. Legal and business advice should be sought and taken
              about the specific circumstances of each case, and nothing
              appearing on this Website is intended to be a substitute for
              obtaining specific legal and/or business advice from a competent
              professional.
            </li>
            <li className="li-p">
              This website contains links to other websites. SWA is not
              responsible and cannot be held liable for the content and/or for
              the privacy policies of such sites.
            </li>
          </ul>
        </section>
        <section className="term-section"  style={{textAlign:'justify'}}>
          <h1>
            <FontAwesomeIcon icon={faComments} /> 8. Use of Communication
            Service
          </h1>

          <ul>
            <p className="li-p">
              The Website may contain services such as email, chat, WhatsApp,
              information related to recommendations, and other Service-related
              messages (hereinafter collectively referred to as "Communication
              Services"). The User agrees and undertakes to use the
              Communication Services only to send and receive messages and
              material that are proper and related to the particular
              Communication Service.
            </p>
            <p className="li-p">
              By way of example, and not as a limitation, the User agrees and
              undertakes that when using a Communication Service, the User will
              not:
            </p>
            <li className="li-p">
              Defame, abuse, harass, stalk, threaten or otherwise violate the
              legal rights of others including SWA.
            </li>
            <li className="li-p">
              Send/upload files that contain software or other material
              protected by intellectual property laws unless the User owns or
              controls the rights thereto or have received all necessary
              consents.
            </li>
            <li className="li-p">
              Send/upload or distribute files that contain viruses, corrupted
              files, or any other similar software or programs that may damage
              the operation of the Website or computer.
            </li>
            <li className="li-p">
              Advertise or offer to sell or buy any goods or services for any
              business purpose, unless such Communication Service specifically
              allows such messages.
            </li>
            <li className="li-p">
              Conduct or forward surveys, contests, pyramid schemes, or chain
              letters.
            </li>
            <li className="li-p">
              Falsify or delete any author attributions, legal or other proper
              notices or proprietary designations or labels of the origin or
              source of software or other material contained in a file that is
              uploaded.
            </li>
            <li className="li-p">
              Violate any code of conduct or other guidelines, which may apply
              for or to any particular Communication Service.
            </li>
            <li className="li-p">
              Violate any applicable laws or regulations for the time being in
              force in or outside India, and violate any of the terms and
              conditions of this Agreement or any other terms and conditions for
              the use of the Website contained elsewhere herein.
            </li>
          </ul>
        </section>

        <section className="term-section"  style={{textAlign:'justify'}}>
          <h1>
            <FontAwesomeIcon icon={faHandshake} /> 9. No Liability in the Case
            of Third-Party Collaboration
          </h1>
          <p className="li-p">
            It is understood that under no circumstances shall SWA assume any
            responsibility for or obligation or duty with respect to any part or
            all, on behalf of any third party that it collaborates with for any
            event/workshop/contest, of any nature or kind whatsoever, or any
            matter or proceedings arising out of or relating thereto. SWA shall
            not be required to take any action of any kind to collect or protect
            any interest of its members/nonmember in relation to such an
            event/workshop/contest, and in no event shall SWA be liable to its
            member/nonmember for any loss of intellectual property, anticipated
            profits or other consequential, special or indirect loss or damage
            of any nature arising at any time, whether arising under contract,
            tort (including negligence), strict liability or dispute situation
            or for any other cause whatsoever.
          </p>
          <p className="li-p">
            However, SWA members can always approach its Dispute Settlement
            Committee (DSC) with their grievances, if any, related to Copyright
            infringement, credits, and remuneration.
          </p>
        </section>
        <section className="term-section"  style={{textAlign:'justify'}}>
          <h1>
            <FontAwesomeIcon icon={faTimesCircle} /> 10. Termination/Access
            Restriction
          </h1>
          <p>
            SWA reserves the right, in its sole discretion, to terminate the
            access to the website and the related services or any portion
            thereof at any time, without notice.
          </p>
        </section>
        <section className="term-section"  style={{textAlign:'justify'}}>
          <h1>
            <FontAwesomeIcon icon={faUserCog} /> 11. User’s Obligation and
            User’s Account
          </h1>
          <ul>
            <li className="li-p">
              In consideration of the use of the Website, the User represents
              and confirms that the user is of legal age to enter into a binding
              contract and is not a person barred from using the Website and/or
              receiving the Services under the laws of India or other applicable
              law.
            </li>
            <li className="li-p">
              To avail a Service, the user has and must continue to maintain at
              his sole cost: (i) all the necessary equipment including a
              computer and modem, etc. to access the Website/avail Services;
              (ii) own access to the World Wide Web. The user shall be
              responsible for accessing the Services and that access may involve
              third-party fees including, airtime charges or internet service
              provider charges which are to be exclusively borne by the User.
            </li>
            <li className="li-p">
              The user also understands that the services may include certain
              communications from SWA as announcements and administrative
              messages. User understands and agrees that the services are
              provided on an "as is" basis and that SWA does not assume any
              responsibility for deletions, non-delivery, or failure to store
              any user communications or personalized settings.
            </li>
            <li className="li-p">
              The Sign-in of a user on the Website is optional. A user has to
              become an SWA member as per the Constitution and Bylaws of SWA and
              then only a User (member) shall be able to Sign In to the Website.
              A User (member) shall receive a permanent membership number after
              becoming a member of SWA. Accordingly, the User (member) can log
              in to the Website using the membership number and password.
              Subsequently, the User (member) can register his/her work and
              carry out other permitted activities.
            </li>
            <li className="li-p">
              The User (member) is responsible for the security of the user id
              and password and for all activity/transactions undertaken using
              the password on SWA website. The Password entered by the User
              (member) is transmitted in one-way encrypted form to our database
              and stored as such. Thus, the Password will not be known even to
              SWA. The user (member or non-member) confirms that he or she is
              the original account holder/authorized holder of any card used in
              the transactions made to avail of SWA Services. SWA will not be
              responsible for any financial loss, inconvenience, or mental agony
              resulting from the misuse of the ID/password/credit card
              number/account details number for using Services. The User
              (member) also agrees and undertakes to immediately notify SWA of
              any unauthorized use of the user's password or user id and to
              ensure that the user logs off at the end of each session at the
              website. SWA shall not be responsible for any, direct or indirect,
              loss or damage arising out of the user's failure to comply with
              this requirement. Further, the User (member) agrees not to use any
              other party's (member’s) user id and password for any purpose
              whatsoever without proper authorization from such a party.
            </li>
            <li className="li-p">
              The User (member) also agrees to (i) provide true, accurate, and
              complete information about himself and his beneficiaries if
              required for any purpose on the Website; and (ii) maintain and
              promptly update the Registration Data to keep it true, accurate,
              current, and complete. If the User (member) provides any
              information that is untrue, inaccurate, not current, or
              incomplete, or SWA has reasonable grounds to suspect that such
              information is untrue, inaccurate, not current, or incomplete, SWA
              has the right to suspend or terminate the User's membership and
              refuse any and all current or future use of the Website and/or any
              Service.
            </li>
            <li className="li-p">
              Furthermore, the User (member) grants SWA the right to disclose to
              third parties his or her Data to the extent necessary for carrying
              out the Services.
            </li>
            <li className="li-p">
              Users shall follow all the obligations listed in SWA’s
              Constitution, Bylaws, Rules and Regulations, and protocols.
            </li>
            <li className="li-p">
              A User (member) shall never use his/her account for registration
              of a work created by someone else (hereinafter referred to as
              “Proxy Registration”). The Proxy registration is invalid. SWA
            </li>
          </ul>
        </section>
        <section className="term-section"  style={{textAlign:'justify'}}>
          <h1>
            <FontAwesomeIcon icon={faGavel} /> 12. Breach
          </h1>
          <p>
            Without prejudice to the other remedies available to SWA under this
            Agreement, the TOS or SWA’s constitution, Bylaws or other applicable
            law, SWA may limit the user's activity, or end the user's listing,
            warn other users of the user's actions, immediately
            temporarily/indefinitely suspend or terminate the user's
            registration, and/or refuse to provide the user with access to the
            website if:
          </p>
          <ul>
            <li className="li-p">
              The User is in breach of this Agreement, the TOS and/or the
              documents it incorporates by reference;
            </li>
            <li className="li-p">
              SWA is unable to verify or authenticate any information provided
              by the User including the payment details;
            </li>
            <li className="li-p">
              SWA believes that the user's actions may infringe on any third
              party rights or breach any applicable law or SWA Constitution and
              Bylaws or otherwise result in any liability for the user, other
              users of the website and/or SWA;
            </li>
            <li className="li-p">
              SWA believes that the User has violated the Constitution, Bylaws
              and Rules and Regulation and guidance of SWA.
            </li>
          </ul>
          <p>
            SWA may at any time in its sole discretion reinstate suspended
            users. Once the user has been indefinitely suspended, the user may
            not register or attempt to register with SWA or use the website in
            any manner whatsoever until such time that the user is reinstated by
            SWA. SWA reserves the right to recover any amounts due and owing by
            the user to SWA and/or the service provider and to take strict legal
            action as SWA deems necessary.
          </p>
        </section>
        <section className="term-section"  style={{textAlign:'justify'}}>
          <h1>
            <FontAwesomeIcon icon={faCopy} /> 13. Proprietary Rights
          </h1>
          <p>
            SWA may provide the User with content such as sound, photographs,
            graphics, video or other material on its Website. This material may
            be protected by copyrights, trademarks or other intellectual
            property rights and laws. The User may use this material only as
            authorized by SWA and duly site the same.
          </p>
        </section>

        {/* "Complaints & Queries" section */}
        <section className="term-section"  style={{textAlign:'justify'}}>
          <h1>
            <FontAwesomeIcon icon={faComments} /> 14. Complaints & Queries
          </h1>
          <ul>
            <li className="li-p">
              The SWA aspires to the highest standards in relation to the
              conduct and treatment of both our staff and members in relation to
              matters concerning data protection. For this reason, we take any
              complaints we receive very seriously. Please bring to our notice
              in case of any misleading, inappropriate or unfair information
              that has been posted on our website sent or communicated to you in
              the name of SWA...
            </li>
            <li className="li-p">
              Please note that when we receive a complaint from a person we make
              up a file containing the details of the complaint. This normally
              contains the identity of the complainant and any other individuals
              involved in the complaint. We at SWA do not in any way make
              commercial use of the data given to us in any way. We will only
              use the personal information we collect to process the complaint
              and to assess the level of service we provide. We may compile and
              publish statistics showing information, such as the number of
              complaints we receive, but not in a form that identifies anyone.
              Similarly, where enquiries are submitted to us we will only use
              the information supplied to us to deal with the enquiry and any
              subsequent issues and to check on the level of service we provide.
            </li>
            <li className="li-p">
              Any email Communication from SWA shall be only through official
              email ID having domain ‘swaindia.org’. Please do not response to
              an email sent (impersonating SWA) through any personal email
              ID/unauthentic ID. SWA shall not be liable for any loss suffered
              by you while responding to any unauthentic email communications.
            </li>
          </ul>
          {/* Add more detailed content as per your document */}
        </section>

        {/* "Termination of Agreement and Services" section */}
        <section className="term-section"  style={{textAlign:'justify'}}>
          <h1>
            <FontAwesomeIcon icon={faBan} /> 15. Termination of Agreement and
            Services
          </h1>
          <p>
            SWA may terminate this Agreement and a Service with or without cause
            at any time to be effective immediately.
          </p>
          <p>
            The User agrees that SWA may under certain circumstances and without
            prior notice, immediately terminate the User's user id and access to
            the Website/Services. Causes for termination may include, but shall
            not be limited to, breach by the User of this Agreement or the TOS,
            requests by enforcement or government agencies, requests by the
            User, Constitution, and Bylaws of SWA.
          </p>
        </section>
        {/* "Indemnification" section */}
        <section className="term-section"  style={{textAlign:'justify'}}>
          <h1>
            <FontAwesomeIcon icon={faShieldAlt} /> 15. Indemnification
          </h1>
          <p>
            You shall release, indemnify, defend and hold harmless SWA, and any
            of its employees, affiliates and member’s liabilities, claims,
            damages, costs, and expenses, including reasonable attorneys' fees
            and expenses, of third parties, relating to or arising out of
            material breach of website use terms and conditions and privacy
            policy along with unauthorized use of the website.
          </p>
        </section>

        {/* "Governing Law and Jurisdiction" section */}
        <section className="term-section"  style={{textAlign:'justify'}}>
          <h1>
            <FontAwesomeIcon icon={faGavel} /> 16. Governing Law and
            Jurisdiction
          </h1>
          <p>
            This Agreement and each TOS shall be governed by and constructed in
            accordance with the laws of India without reference to conflict of
            laws principles and disputes arising in relation hereto shall be
            subject to the exclusive jurisdiction of the courts of Mumbai.
          </p>
        </section>

        {/* Add more sections as needed */}
      </div>
      <SwaFooter />
    </>
  );
};

export default TermsAndConditions;
