import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import MobileNavbar from "../Screenwriters/MobileNavbar";
import SwaNavbar from "../Screenwriters/SwaNavbar";
import SwaFooter from "../Screenwriters/SwaFooter";
import EducationHeader from "../Education/EducationHeader";

function ArticlePage() {
  const { id } = useParams();
  const [article, setArticle] = useState(null);
  const [isMobile, setIsMobile] = useState(() => {
    const initialValue = localStorage.getItem("isMobile");
    return initialValue ? JSON.parse(initialValue) : false;
  });
  useEffect(() => {
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth < 970);
      localStorage.setItem("isMobile", JSON.stringify(window.innerWidth < 970));
    };

    checkScreenWidth();
    window.scrollTo(0, 0);

    window.addEventListener("resize", checkScreenWidth);

    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);
  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await axios.get(
          `https://cms.swaindia.org/api/archive/show/${id}`
        );
        console.log(response.data.data, "response.data.data articleee");
        setArticle(response.data.data.article[0]);
      } catch (error) {
        console.error("Error fetching article:", error);
      }
    };

    fetchArticle();
  }, [id]);
  useEffect(() => {
    console.log(article, "frgtreg");
  }, []);
  const spinnerStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '50%',
    border: '5px solid #f3f3f3',
    borderTop: '5px solid #3498db',
    width: '50px',
    height: '50px',
    animation: 'spin 1s linear infinite',
  };

  const dimmerStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 9999,
  };
  if (!article) return <div style={dimmerStyle}>
    <div style={spinnerStyle}></div>
  </div>;

  return (
    <div>
      {isMobile ? <MobileNavbar /> : <SwaNavbar />}
      {/* <EducationHeader /> */}

      <div className="breadcumb-wrapper">
        <div className="container">
          <ul class="breadcumb-menu">
            <li>
              <a href="/">Home</a>
            </li>
            <li><a href="/archive">Archive</a></li>
            <li>{article.art_main_heading}</li>
          </ul>
        </div>
      </div>
      <div className="container" style={{marginTop:'1.5rem'}}>
        {/* <Link to="/archive">Back to Archive</Link> */}
        <h3>{article.art_main_heading}</h3>
        <p>
          <b>Author:</b> {article.author_name}
        </p>
        <p>
          <b>Date:</b> {article.art_date}
        </p>
        <p style={{ textAlign: "justify" }}>
          <b>Content:</b>
          <div
            className="my-4 py-lg-2"
            dangerouslySetInnerHTML={{ __html: article.art_content }}
          ></div>
        </p>
      </div>
      <SwaFooter />
    </div>
  );
}

export default ArticlePage;
