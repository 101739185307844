import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGlobe,
  faLock,
  faInfoCircle,
  faCalendarAlt,
  faClipboard,
} from "@fortawesome/free-solid-svg-icons";
import "./Terms.css"; // Make sure to create a corresponding CSS file
import SwaNavbar from "../Screenwriters/SwaNavbar";
import SwaFooter from "../Screenwriters/SwaFooter";
import MobileNavbar from "../Screenwriters/MobileNavbar"; // Importing CSS for styling

const Policy = () => {
  const [isMobile, setIsMobile] = useState(() => {
    const initialValue = localStorage.getItem("isMobile");
    return initialValue ? JSON.parse(initialValue) : false;
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth < 970);
      localStorage.setItem("isMobile", JSON.stringify(window.innerWidth < 970));
    };

    checkScreenWidth();

    window.addEventListener("resize", checkScreenWidth);

    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);
  return (
    <>
      {isMobile ? <MobileNavbar /> : <SwaNavbar />}
      <div className="breadcumb-wrapper">
        <div className="container">
          <h1 className="headings"> Privacy Policy</h1>
        </div>
      </div>
      <div className="container" style={{marginTop:'1rem'}}>
        <section className="term-section" style={{textAlign:'justify'}}>
          <p className="li-p">
            The Screenwriters Association’s (SWA) website ("Website"),
            https://www.swaindia.org/ is copyrighted by SWA. All rights are
            reserved with us. Neither the Website nor any part thereof may be
            reproduced or copied in any form or by any means without the express
            written consent of SWA.
          </p>

          <h1>
            {" "}
            <FontAwesomeIcon icon={faClipboard} /> Links to Other Internet Sites
          </h1>
          <ul>
            <li className="li-p">
              At certain places on the Website, there may be live "links" to
              other Internet addresses. Such external Internet addresses contain
              information created, published or maintained by institutions or
              organizations independent of SWA. The SWA does not necessarily
              endorse, approve, certify, or control these external Internet
              addresses. It does not guarantee the accuracy, completeness,
              efficacy, timeliness, or correct sequencing of information located
              at such addresses. Use of any information obtained from such
              addresses is voluntary. The reliance on the same should only be
              undertaken, after an independent review of its accuracy,
              completeness, efficacy, and timeliness.{" "}
            </li>
          </ul>
        </section>
        <section className="term-section" style={{textAlign:'justify'}}>
          <h1 className="bold">
            <FontAwesomeIcon icon={faLock} /> Privacy Policy
          </h1>
          <p className="li-p">
            Thank you for visiting our Website. Your privacy is important to us.
            We provide this notice describing our online information practices
            to keep you informed about how your information is collected and
            used and to better protect your privacy. This Privacy Policy applies
            only to information collected by SWA through the Website. This
            policy may differ in some respects from the SWA’s policy concerning
            personal information collected from its members through other
            sources.{" "}
          </p>
          <h3>How We Collect Personal Information and How It Is Used:</h3>
          <ul>
            <li className="li-p">
              In general, if you visit our website to access information, you do
              so anonymously. We do not require you to register or provide
              personal information to view our Site. However, you need to be our
              member and login to our website in case you want to register your
              work.
            </li>
            <li className="li-p">
              Our Web server will not automatically recognize any visitor's
              information regarding the domain or email address. SWA may collect
              the email or postal addresses the person who register to the
              Website or communicate with us via email or another medium. We
              keep a record of registration details, name, address, telephone
              number, etc. We use the same for communication and other official
              purposes.
            </li>
            <li className="li-p">
              We collect and retain personal information of all SWA members,
              like name, pen/screen name, date of birth, father/mother’s name,
              permanent address, temporary address, email ID, mobile number,
              secondary mobile number, Photo ID proof, Address ID proof,
              Eligibility Proof (to be admitted to Associate/Regular/Life
              category), education qualification; which they submit at the time
              of applying for SWA membership. We keep the information submitted
              to us confidential and maintain it as membership details of our
              members. However, as per our Constitution and Byelaws, we do not
              share any personal details of members, including phone numbers,
              e-mail ids, and addresses with other members or with any other
              persons or agencies, without written permission of the member
              whose details have been requested. Provided, in matters of
              dispute, with the express permission of the General Secretary, a
              member's details can be shared with a legitimate agency.
            </li>
            <li className="li-p">
              We store details and records of Online Script Registration when a
              member registers his or her script/work on our website. This
              record contains Title, Type, Number of Pages, Date of
              registration, Author’s name, Author’s SWA Membership Number,
              Author’s email ID and/or phone number, Co-author/s, Co-author/s
              SWA Membership Number, Co-author’s email ID and/or phone number,
              Payment Gateway reference number, Order ID, Transaction ID in
              relation with a particular transaction. However, our website,
              online system, office staff, developers as well as the Executive
              Committee or anyone working for and with SWA do not READ, STORE or
              ASSESS the registered script/work. We provide the member his or
              her registered copy and keep it on online server for temporary
              period so that the member can download it. During this period, the
              file is in encrypted form and the human eye can’t read its
              content. Later, for secrecy and safety purpose, this file is
              deleted from our server on a permanent basis. We do not store any
              physical or soft copy of your work in our system. It’s entirely
              the member’s responsibility to save the registered copy with him
              or her, as a safeguard against Copyright infringement.
            </li>
            <li className="li-p">
              We store data of members and non-members who register to attend
              our events. This is the data which they submit while registration
              or buying an event ticket, like name, email ID, phone number,
              billing address, special remarks and system generated data
              including Transaction ID, Payment ID and Payment Gateway Reference
              number.
            </li>
          </ul>
        </section>

        {/* Add more sections as needed */}
      </div>
      <SwaFooter />
    </>
  );
};

export default Policy;
