import React, { useState, useEffect } from "react";
import "./ScreenWriters.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faClock,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";
import footer from "../../img/footer.png";
import location from "../../img/location.png";
import mobile from "../../img/mobile.png";
import mail from "../../img/mail.png";

function SwaFooter() {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* footer start */}
      <div className="footer__screenwriters">
        {/* footer content here */}
        <div className="container">
          <div className="row">
            {/* 1 */}
            <div className="col-md-4">
              <div className="col">
                <Link className="a" to="/">
                  <img src={footer} alt="image" />
                </Link>
                <p className="footer_text_1">
                  The Screenwriters Association (SWA, formerly Film Writers'
                  Association - Regd. Under the Trade Union Act 1926, Regd. No.
                  3726) is a Trade Union of screenwriters and lyricists who work
                  for Films, TV and Digital Media in India.
                </p>
              </div>
            </div>

            {/* 2 */}
            <div className="col-md-2">
              <div className="footer_text_header">SITE LINKS</div>
              <ul
                style={{
                  listStyleType: "disc",
                  paddingLeft: "20px",
                  color: "white",
                }}
              >
                {" "}
                {/* Adjust padding to align bullets as needed */}
                <li>
                  <Link className="a" to="/mission">
                    OUR MISSION
                  </Link>
                </li>
                <li>
                  <a
                    className="a"
                    href="https://swaindia.org/swa_account/register"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    BECOME A MEMBER
                  </a>
                </li>
                <li>
                  <a
                    className="a"
                    href="https://swaindia.org/swa_account/login"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    REGISTER YOUR WORK
                  </a>
                </li>
                <li>
                  <Link className="a" to="/askourlawyer">
                    ASK SWA
                  </Link>
                </li>
                <li>
                  <Link className="a" to="/contact">
                    CONTACT US
                  </Link>
                </li>
              </ul>
            </div>

            <div className="col-md-3">
              <div className="footer_text_header">OTHER LINKS</div>
              <ul
                style={{
                  listStyleType: "disc",
                  paddingLeft: "20px",
                  color: "white",
                }}
              >
                {" "}
                {/* Adjust padding to align bullets as needed */}
                {/* <li><Link className="a" to="/writercharacter">THE WRITERS CHARTER</Link></li> */}
                <li>
                  <Link className="a" to="/dodont">
                    DOS & DON'TS FOR WRITERS
                  </Link>
                </li>
                <li>
                  <Link className="a" to="/faq">
                    FREQUENTLY ASKED QUESTIONS
                  </Link>
                </li>
                <li>
                  <Link className="a" to="/downloads">
                    ALL DOWNLOADS
                  </Link>
                </li>
                <li>
                  <Link className="a" to="/archive">
                    ARCHIVE
                  </Link>
                </li>
                <li>
                  <Link className="a" to="/terms">
                    TERMS & CONDITIONS
                  </Link>
                </li>
                <li>
                  <Link className="a" to="/refund">
                    REFUND & CANCELLATION POLICY
                  </Link>
                </li>
                <li>
                  <Link className="a" to="/policy">
                    PRIVACY POLICY
                  </Link>
                </li>
              </ul>
            </div>

            {/* 4 */}
            <div className="col-md-3">
              <div className="col">
                <Link className="a" to="/contact">
                  <div className="footer_text_header">CONTACT US</div>
                </Link>

                <p style={{ marginBottom: "0.5rem" }}>
                  <a
                    href="https://maps.app.goo.gl/6wtZA3HwptwJnhfm8"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <img
                      src={location}
                      alt="location"
                      style={{ marginRight: "1rem" }}
                    />
                    View On Map
                  </a>
                </p>
                <p style={{ marginBottom: "0.5rem" }}>
                  <a
                    href="https://maps.app.goo.gl/6wtZA3HwptwJnhfm8"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <FontAwesomeIcon
                      icon={faBuilding}
                      style={{ marginRight: "1rem" }}
                    />
                    201 - 204, Richa Building,
                    <br />
                    Plot No - B/29,
                    <br />
                    Off New Link Road, Opposite Citi Mall,
                    <br />
                    Andheri (West) Mumbai - 400053,
                    <br />
                    {/* Maharashtra - 400 053,
                    <br /> */}
                    India.
                    <br />
                  </a>
                </p>

                <p style={{ marginBottom: "0.5rem" }}>
                  <img
                    src={mobile}
                    alt="Phone"
                    style={{ marginRight: "1rem" }}
                  />
                  022-46032584 | 9022107700 | www.swaindia.org
                </p>
                {/* <p style={{marginBottom:'0.5rem'}}>
                                  <img src="./mobile.png" alt="Phone" style={{ marginRight: '1rem' }} />
                                  +91 90221 07700
                              </p> */}
                <p>
                  <img src={mail} alt="Phone" style={{ marginRight: "1rem" }} />
                  contact@swaindia.org
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* footer end */}

      {/* copyright start */}
      <div
        className="copyright__screenwriters"
        style={{
          width: "100%",
          minHeight: "78px",
          backgroundColor: "#d3a221",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* Add your footer content here */}
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <p className="copyright">
                Copyrights © 2024 swaindia.org <br /> All rights reserved.
              </p>
            </div>
            <div className="col-md-6">
              {/* <p style={{color:"white"}}>Be Social with Us</p> */}
              <div
                className="social-icons"
                style={{
                  textAlign: "right",
                  marginLeft: "1rem",
                  marginRight: "1rem",
                }}
              >
                <a
                  href="https://www.facebook.com/swaindiaorg"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ margin: "0 10px" }}
                >
                  <img
                    src={require("../../img/fb-removebg-preview(1).png")}
                    alt="Facebook"
                    style={{ width: "40px", height: "40px" }}
                  />
                </a>
                <a
                  href="https://www.youtube.com/screenwritersassociation"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ margin: "0 10px" }}
                >
                  <img
                    src={require("../../img/youtube-removebg-preview.png")}
                    alt="YouTube"
                    style={{ width: "30px", height: "30px" }}
                  />
                </a>
                <a
                  href="https://www.instagram.com/swaindiaorg/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ margin: "0 10px" }}
                >
                  <img
                    src={require("../../img/Untitled-removebg-preview.png")}
                    alt="Blog"
                    style={{ width: "30px", height: "30px" }}
                  />
                </a>
                <a
                  href="https://twitter.com/i/flow/login?redirect_after_login=%2Fswaindiaorg"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ margin: "0 10px" }}
                >
                  <img
                    src={require("../../img/icons8-twitter-50.png")}
                    alt="Twitter"
                    style={{ width: "30px", height: "30px" }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* copyright end */}
    </>
  );
}

export default SwaFooter;
