import React, { useState, useEffect, useRef } from "react";
import SwaNavbar from "../Screenwriters/SwaNavbar";
import SwaFooter from "../Screenwriters/SwaFooter";
import MobileNavbar from "../Screenwriters/MobileNavbar"; // Importing CSS for styling
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faClock,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";

const ContactPage = () => {
  // State for form inputs, add more as needed
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [membershipNumber, setMembershipNumber] = useState("");
  const [subject, setSubject] = useState("");
  const [comments, setComments] = useState("");
  const [isMember, setIsMember] = useState(true);
  const [isMobile, setIsMobile] = useState(() => {
    const initialValue = localStorage.getItem("isMobile");
    return initialValue ? JSON.parse(initialValue) : false;
  });
  useEffect(() => {
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth < 970);
      localStorage.setItem("isMobile", JSON.stringify(window.innerWidth < 970));
    };

    checkScreenWidth();

    window.addEventListener("resize", checkScreenWidth);

    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);

  // Add other state and handlers as needed

  return (
    <>
      {isMobile ? <MobileNavbar /> : <SwaNavbar />}
      <section className="content-container">
        <div className="breadcumb-wrapper">
          <h1 className="page-title headings">Conatct Us</h1>
        </div>
        <div
          className="container"
          // style={{
          //   display: "flex",
          //   // maxWidth: "1400px",
          //   margin: "20px auto",
          //   fontFamily: "Sans-serif",
          // }}
        >
          <div className="row">
            {/* <div style={{ flex: 1, padding: "20px" }}> */}
            <div className="col-md-6">
              <h1 className="headings" style={{ paddingBottom: "5px" }}>
                Write to SWA
              </h1>
              {/* Contact form Start */}
              <form>
                {/* Name */}
                <input
                  type="text"
                  placeholder="Name*"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  style={{
                    width: "100%",
                    padding: "10px",
                    margin: "10px 0",
                    boxSizing: "border-box",
                  }}
                />
                {/* E-mail */}
                <input
                  type="email"
                  placeholder="Email*"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{
                    width: "100%",
                    padding: "10px",
                    margin: "10px 0",
                    boxSizing: "border-box",
                  }}
                />
                {/* SWA Membership Number */}
                <input
                  type="text"
                  placeholder="SWA Membership Number*"
                  value={membershipNumber}
                  onChange={(e) => setMembershipNumber(e.target.value)}
                  style={{
                    width: "100%",
                    padding: "10px",
                    margin: "10px 0",
                    boxSizing: "border-box",
                  }}
                />
                {/* Not a Member */}

                <input
                  type="checkbox"
                  id="isMemberCheckbox"
                  checked={!isMember}
                  onChange={(e) => setIsMember(!e.target.checked)}
                  style={{ marginRight: "5px" }}
                />

                <label htmlFor="isMemberCheckbox">Not A Member</label>
                {/* <input
                  type="checkbox"
                  id="isMemberCheckbox"
                  checked={isMember}
                  onChange={(e) => setIsMember(e.target.checked)}
                  style={{ marginRight: "5px" }}
                /> */}

                {/* Select Subject */}
                <select
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  style={{
                    width: "100%",
                    padding: "10px",
                    margin: "10px 0",
                    boxSizing: "border-box",
                  }}
                >
                  <option value="">Select a subject</option>
                  <option value="query">Query</option>
                  <option value="feedback">Feedback</option>
                  <option value="other">Other</option>
                  <option value="legal_consultation">
                    Legal Officer - Consultation
                  </option>
                  <option value="legal_appointment">
                    Legal Officer - Appointment
                  </option>
                  <option value="dsc">To the DSC</option>
                  <option value="tech_support_signin">
                    TECHNICAL SUPPORT - NOT ABLE TO SIGN IN/SIGN UP
                  </option>
                  <option value="tech_support_registration">
                    TECHNICAL SUPPORT - ONLINE SCRIPT REGISTRATION
                  </option>
                </select>
                {/* Comments */}
                <textarea
                  placeholder="Comments*"
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                  style={{
                    width: "100%",
                    padding: "10px",
                    margin: "10px 0",
                    height: "100px",
                    boxSizing: "border-box",
                  }}
                />
                {/* Submit Button */}
                <button
                  type="submit"
                  style={{
                    width: "100%",
                    padding: "10px",
                    backgroundColor: "black",
                    color: "white",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  Submit
                </button>
              </form>
              {/* Contact form End */}
            </div>
            {/* <div style={{ flex: 1, padding: "20px 50px" }}>
             */}
            <div className="col-md-6">
              <h1 className="headings" style={{ paddingBottom: "5px" }}>
                Address & Contact
              </h1>
              <p>
                <FontAwesomeIcon icon={faBuilding} /> 201 - 204, Richa Building,
                Plot No - B/29,
                <br />
                Off New Link Road, Opposite Citi Mall,
                <br />
                Andheri (West) Mumbai - 400053,
                <br />
                {/* Maharashtra - 400 053,
                <br /> */}
                India.
                <br />
              </p>
              <br />
              <p>
                <FontAwesomeIcon icon={faPhone} /> 022-46032584 | 9022107700 |
                www.swaindia.org
                <br />
              </p>
              <br />
              <p>
                <FontAwesomeIcon icon={faEnvelope} /> contact@swaindia.org
                <br />
              </p>
              <br />
              <p>
                <FontAwesomeIcon icon={faClock} />
                <strong> Office Time*:</strong> Monday - Saturday | 11.00 am -
                7.00 pm
                <br />
              </p>
              <br />
              <p>
                <FontAwesomeIcon icon={faClock} />
                <strong> Membership (New/Renewal):</strong> Monday - Saturday |
                11.30 am - 6.00 pm
                <br />
              </p>
              <br />
              <p>
                <FontAwesomeIcon icon={faClock} />
                <strong> Offline Script Registration Days:</strong> Tuesday and
                Friday | 2.00 pm - 5.30 pm
                <br />
              </p>
              <br />
              <p>*Closed on Sunday and Bank Holidays.</p>
              <br />
            </div>
          </div>
        </div>
      </section>
      <SwaFooter />
    </>
  );
};

export default ContactPage;
