/* eslint-disable jsx-a11y/no-distracting-elements */
import React, { useEffect, useState } from "react";
import SwaFooter from "../Screenwriters/SwaFooter";
import MobileNavbar from "../Screenwriters/MobileNavbar";
import SwaNavbar from "../Screenwriters/SwaNavbar";
import AwardsHeader from "./AwardsHeader";
import "./Awards.css";
import AwardSection from "./AwardSection";

function Awards() {
  const [isMobile, setIsMobile] = useState(() => {
    const initialValue = localStorage.getItem("isMobile");
    return initialValue ? JSON.parse(initialValue) : false;
  });

  // Mobile device
  useEffect(() => {
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth < 970);
      localStorage.setItem("isMobile", JSON.stringify(window.innerWidth < 970));
    };

    checkScreenWidth();
    window.scrollTo(0, 0);

    window.addEventListener("resize", checkScreenWidth);

    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);

  return (
    <>
      {isMobile ? <MobileNavbar /> : <SwaNavbar />}

      <div style={{ position: "relative", zIndex: 1 }}>
        {/* header of Award Page */}
        <AwardsHeader />

        <div className="breadcumb-wrapper">
          <div className="container">
            <div className="row justify-content-center justify-content-lg-between align-items-center">
              <div
                className="col-lg-10 text-end"
                // style={{marginLeft:"23rem"}}
              >
                <div
                  className="header-ads"
                  //  style={{marginLeft:"1rem"}}
                >
                  <p className="mission-statement">
                    The greatest motivation for any writer is recognition for
                    their work. The awards subcommittee ensures that writers
                    receive proper recognition through SWA awards, which are
                    conferred by fellow writers. This process follows a thorough
                    procedure to ensure fairness and transparency. Over time,
                    these awards have gained significant popularity within our
                    industry, serving as esteemed markers of accomplishment.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* In Haeder when Award page open*/}
        <AwardSection />
      </div>

      <SwaFooter />
    </>
  );
}

export default Awards;
