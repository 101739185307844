import React, { useEffect, useState } from "react";
import SwaFooter from "../Screenwriters/SwaFooter";
import ReactPaginate from "react-paginate";
import { Link, useParams } from "react-router-dom";
import EducationHeader from "./EducationHeader";
import MobileNavbar from "../Screenwriters/MobileNavbar";
import SwaNavbar from "../Screenwriters/SwaNavbar";
import axios from "axios";

function EducationBlog() {
  const { id } = useParams();
  const [articleList, setArticleList] = useState([]);
  const [offset, setOffset] = useState(0);
  const [perPage] = useState(12);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [status, setStatus] = useState("");
  const [isMobile, setIsMobile] = useState(() => {
    const initialValue = localStorage.getItem("isMobile");
    return initialValue ? JSON.parse(initialValue) : false;
  });

  useEffect(() => {
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth < 970);
      localStorage.setItem("isMobile", JSON.stringify(window.innerWidth < 970));
    };

    checkScreenWidth();
    window.scrollTo(0, 0);

    window.addEventListener("resize", checkScreenWidth);

    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);

  const image_path = "https://cms.swaindia.org/storage/Blog";
  const thumbnail_images_path = "https://cms.swaindia.org/storage/Blog/thumbnail";
  // API call for education subcategory details
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://cms.swaindia.org/api/blog/list/6/${id}`
        );
        console.log("API educatiion detail response", response.data);
        setStatus(response.data.status);
        const sortedArticleList = response.data.data.blog_list.sort(
          (a, b) => a.subcategory_id - b.subcategory_id
        );
        if (response.data.status === true) {
          setArticleList(sortedArticleList);
        }
        // const slice = sortedArticleList.slice(offset, offset + perPage);

        setPageCount(Math.ceil(sortedArticleList.length / perPage));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id]);

  const handlePageClick = (selectedPage) => {
    const newOffset = selectedPage * perPage;
    setCurrentPage(selectedPage);
    setOffset(newOffset);
  };

  // date formatting
  // const formatDate = (dateString) => {
  //   const options = { day: "2-digit", month: "short", year: "numeric" };
  //   return new Date(dateString).toLocaleDateString("en-US", options);
  // };

  return (
    <div>
      {isMobile ? <MobileNavbar /> : <SwaNavbar />}
      {/* {isMobile ? <AwardMobileNavbar /> : <AwardSwaNavbar />} */}
      <div style={{ position: "relative", zIndex: 1 }}>
        <EducationHeader />
        <section className="space-top space-extra-bottom">
          <div className="container">
            <div className="row gy-30 mb-30">
              {/* Mapping Education data accrding subcategory */}
              {status === false ? (
                <div>No data available.</div>
              ) : (
                articleList
                  .slice(currentPage * perPage, (currentPage + 1) * perPage)
                  .map((article) => (
                    <div
                      key={article.id}
                      className="col-xl-4 col-sm-6"
                    >
                      <div className="blog-style1">
                        <div className="blog-img">
                          <Link
                            to={`/educationDetail/${article.id}`}
                          >
                            <img
                              // src={`${image_path}/${article.image}`}
                              src={`${thumbnail_images_path}/${article.thumbnail_image}`}
                              alt="blogimage"
                            />
                          </Link>

                          {/* <Link
                            to={`/educationDetail/${article.subcategory_id}`}
                            className="category"
                            data-theme-color="#4E4BD0"
                          >
                            {article.title}
                          </Link> */}
                        </div>
                        <h3 className="box-title-24">
                          <Link
                            to={`/educationDetail/${article.id}`}
                            className="hover-line"
                          >
                            {article.title}
                          </Link>
                        </h3>
                        {/* <div className="blog-meta">
                          <div>
                            <i className="fal fa-calendar-days"></i>
                            {formatDate(article.created_at)}
                          </div>
                        </div> */}
                      </div>
                    </div>
                  ))
              )}
            </div>
            {pageCount > 1 && (
              <div className="th-pagination mt-40 text-center">
                <ReactPaginate
                  previousLabel={"prev"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={({ selected }) => handlePageClick(selected)}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </div>
            )}
          </div>
        </section>
        <SwaFooter />
      </div>
    </div>
  );
}

export default EducationBlog;
