import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faBan } from "@fortawesome/free-solid-svg-icons";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import "./DoDont.css";
import SwaNavbar from "../Screenwriters/SwaNavbar";
import SwaFooter from "../Screenwriters/SwaFooter";
import MobileNavbar from "../Screenwriters/MobileNavbar"; // Importing CSS for styling

// Unique CSS class names
const styles = {
  page: "dos-page",
  header: "dos-header",
  list: "dos-list",
  listItem: "dos-list-item",
  icon: "dos-icon",
};
// useEffect(() => {
//   window.scrollTo(0, 0);
// }, []);
const DosAndDontsPage = () => {
  const [isMobile, setIsMobile] = useState(() => {
    const initialValue = localStorage.getItem("isMobile");
    return initialValue ? JSON.parse(initialValue) : false;
  });
  useEffect(() => {
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth < 970);
      localStorage.setItem("isMobile", JSON.stringify(window.innerWidth < 970));
    };

    checkScreenWidth();
    // window.scrollTo(0, 0);

    window.addEventListener("resize", checkScreenWidth);

    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);
  return (
    <>
      {isMobile ? <MobileNavbar /> : <SwaNavbar />}
      <div className="content-container">
        <div className="breadcumb-wrapper">
          <h1 className="page-title headings">DOs & DON'Ts FOR WRITERS</h1>
        </div>
        <div className="container">
          <div className="dosdonts-container">
            <div className="dos-container">
              <h2 className="section-title">
                <faCheckCircle className="icon" /> DO's
              </h2>
              {/* Add your DO's list here */}
              <div className="dos-list">
                {/* Each DO */}
                <div className="dos-item">
                  <h3 className="item-title">Deserve Before You Desire</h3>
                  <p>
                    {" "}
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className={styles.icon}
                    />{" "}
                    Write well. Draft a 'production & reader friendly'
                    screenplay which ensures a smooth read and is ready to go
                    into production stage.
                  </p>
                  <br />
                  <p>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className={styles.icon}
                    />{" "}
                    Complete at least a couple of drafts of a screenplay and
                    have at least 3-4 screenplays in different categories in
                    your kitty before you start knocking on producers' doors.
                  </p>
                  <br />
                  <p>
                    {" "}
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className={styles.icon}
                    />{" "}
                    Practice narration often so you are prepared to pitch.
                    Narrate to your friends, family and confidants and re-work
                    on their suggestions.{" "}
                  </p>
                  <br />
                </div>
                <div className="dos-item">
                  <h3 className="item-title">Be A Professional</h3>
                  <p>
                    {" "}
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className={styles.icon}
                    />{" "}
                    Become a SWA member as soon as you have something to get
                    registered.
                  </p>
                  <br />
                  <p>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className={styles.icon}
                    />{" "}
                    Register ALL the drafts of
                    synopsis/story/script/screenplay/dialogue, at every stage of
                    rewrite, BEFORE sharing it professionally.
                  </p>
                  <br />
                  <p>
                    {" "}
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className={styles.icon}
                    />{" "}
                    Keep the registered copies of your script/work with utmost
                    safety.{" "}
                  </p>
                  <br />
                  <p>
                    {" "}
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className={styles.icon}
                    />{" "}
                    Prefer using the Online Registration facility at SWA
                    website, as much as possible. The online registered soft
                    copies can easily be shared via emails.
                  </p>
                  <br />
                  <p>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className={styles.icon}
                    />{" "}
                    Email soft-copies of your registered script/work to
                    Producers/Production Houses, unless they insist on a hard
                    copy.{" "}
                  </p>
                  <br />
                  <p>
                    {" "}
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className={styles.icon}
                    />{" "}
                    If submitting a hard copy is necessary, share a Xerox copy
                    of your registered script/work.{" "}
                  </p>
                  <br />
                  <p>
                    {" "}
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className={styles.icon}
                    />{" "}
                    Learn to negotiate with Producers/Production Houses for our
                    price and rights.
                  </p>
                  <br />
                  <p>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className={styles.icon}
                    />{" "}
                    Try writing for Television. TV is truly a medium of writers,
                    as it not only offers financial security, but also an
                    opportunity to whet your craft by writing under pressure.
                  </p>
                  <br />
                  <p>
                    {" "}
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className={styles.icon}
                    />{" "}
                    Initially, having a side job to support yourself till you
                    make it big actually helps. Consider having one while
                    looking for writing assignments and perfecting your
                    speculative screenplays.
                  </p>
                  <br />
                </div>
                <div className="dos-item">
                  <h3 className="item-title">Narrate it Right</h3>
                  <p>
                    {" "}
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className={styles.icon}
                    />{" "}
                    Insist on getting a ‘Non-Discloser Agreement (NDA)’ OR any
                    other kind of acknowledgement, from Producers/Production
                    Houses/Channels for narrations. If possible, also insist on
                    recording the meeting.
                  </p>
                  <br />
                  <p>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className={styles.icon}
                    />{" "}
                    Before, during or right after the narration, email
                    soft-copies to Producer/Production Houses/Channels with a
                    note that you narrated that particular script/work to them.
                    Attach the soft copy of the mentioned script/work with this
                    email. It’s an evidence of the exchange and will also help
                    you keep a record.
                  </p>
                  <br />
                  <p>
                    {" "}
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className={styles.icon}
                    />{" "}
                    Keep a diary and note down all relevant dates and times when
                    you have met and narrated a script/work.{" "}
                  </p>
                  <br />
                </div>
                <div className="dos-item">
                  <h3 className="item-title">Sign The Right Contract</h3>
                  <p>
                    {" "}
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className={styles.icon}
                    />{" "}
                    ALWAYS sign a contract before commencing a project. Do not
                    work without a Contract.
                  </p>
                  <br />
                  <p>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className={styles.icon}
                    />{" "}
                    While signing a contract insist that a copy be given to you
                    immediately.
                  </p>
                  <br />
                  <p>
                    {" "}
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className={styles.icon}
                    />{" "}
                    Do not sign away your rights in a Contract. Sometimes it is
                    better to refuse an offer rather than regret it at a later
                    date.{" "}
                  </p>
                  <br />
                  <p>
                    {" "}
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className={styles.icon}
                    />{" "}
                    Insist on the model Minimum Basic Contract proposed by the
                    Screenwriters Association. (The producers’ bodies are yet to
                    approve the model contract but it’s strongly recommended by
                    SWA. Download it here.)
                  </p>
                  <br />
                  <p>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className={styles.icon}
                    />{" "}
                    If provided by the producer/production houses, don't sign
                    the contract before you take legal advice on it. You can
                    contact SWA’s lawyer if you have any doubts about any clause
                    of the contract or stages of signing it. (Only for SWA
                    members. Non-members can read the FAQs related to legal
                    advice and our Ask Our Lawyer column.){" "}
                  </p>
                  <br />
                  <p>
                    {" "}
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className={styles.icon}
                    />{" "}
                    Insist upon inserting a clause in your contract whereby is
                    clearly stated that in case of any dispute/s they will take
                    up the matter to their association’s forum.{" "}
                  </p>
                  <br />
                  <p>
                    {" "}
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className={styles.icon}
                    />{" "}
                    Do not sign contracts that insist that you cannot revert to
                    SWA in case of a dispute and/or ask you to sign away any
                    other right/s.
                  </p>
                  <br />
                </div>
                {/* Repeat for other DO's */}
              </div>
            </div>

            <div className="donts-container">
              <h2 className="section-title">
                <faTimesCircle className="icon" /> DON'Ts
              </h2>
              {/* Add your DON'Ts list here */}
              <div className="donts-list">
                {/* Each DON'T */}
                <div className="donts-item">
                  {/* <h3 className="item-title">Don't copy or plagiarize.</h3> */}
                  <p>
                    {" "}
                    <FontAwesomeIcon
                      icon={faBan}
                      className={styles.icon}
                    />{" "}
                    Don't copy or plagiarize.
                  </p>
                  <br />
                  <p>
                    <FontAwesomeIcon icon={faBan} className={styles.icon} />{" "}
                    Don't encourage or work for ‘inspired’ remakes of films
                    unless proper rights to do the same have been procured by
                    the Producer.
                  </p>
                  <br />
                  <p>
                    {" "}
                    <FontAwesomeIcon
                      icon={faBan}
                      className={styles.icon}
                    />{" "}
                    Don't be in a hurry to sell the first draft of your
                    screenplay. Give it time and rework on more drafts,
                    fine-tuning your craft.{" "}
                  </p>
                  <br />
                  <p>
                    {" "}
                    <FontAwesomeIcon
                      icon={faBan}
                      className={styles.icon}
                    />{" "}
                    Don't share unregistered work with Producers/Production
                    Houses/Channels/filmmakers.{" "}
                  </p>
                  <br />
                  <p>
                    <FontAwesomeIcon icon={faBan} className={styles.icon} />{" "}
                    DON'T WRITE WITHOUT A CONTRACT. Doing so is unprofessional
                    and gives you no protection - legal, monetary or otherwise.
                  </p>
                  <br />
                  <p>
                    {" "}
                    <FontAwesomeIcon
                      icon={faBan}
                      className={styles.icon}
                    />{" "}
                    Look out if there's a clause in your agreement/contract that
                    prohibits you from approaching SWA in case of a dispute. If
                    so, DON'T sign it.{" "}
                  </p>
                  <br />
                  <p>
                    <FontAwesomeIcon icon={faBan} className={styles.icon} />{" "}
                    Don't wait for your scheduled payments. As and when the
                    producer starts delaying payments, take the help of SWA’s
                    DSC.{" "}
                  </p>
                  <br />
                  <p>
                    {" "}
                    <FontAwesomeIcon
                      icon={faBan}
                      className={styles.icon}
                    />{" "}
                    Don't be afraid to name your price and to say 'NO' to
                    Producer/Production houses if something doesn't suit you in
                    the contract.{" "}
                  </p>
                  <br />
                </div>
                {/* Repeat for other DON'Ts */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <SwaFooter />
    </>
  );
};

export default DosAndDontsPage;
