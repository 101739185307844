import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import bg from "../../img/bg-welfare.jpeg";
import "../DSC/DSC.css"; // Your CSS file for styling
import MobileNavbar from "../Screenwriters/MobileNavbar";
import SwaNavbar from "../Screenwriters/SwaNavbar";
import { Card, Table, ListGroup } from "react-bootstrap";
import {
  FaTimes,
  FaCheck,
  FaCreditCard,
  FaRegCalendarAlt,
} from "react-icons/fa";
import SwaFooter from "../Screenwriters/SwaFooter";
import backgroundImage from "../../img/bg-welfare.jpeg";
import bhavnex_DSC from "../../img/bhavnex_DCS.png";
const Welfarem = () => {
  const [isMobile, setIsMobile] = useState(() => {
    const initialValue = localStorage.getItem("isMobile");
    return initialValue ? JSON.parse(initialValue) : false;
  });
  useEffect(() => {
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth < 970);
      localStorage.setItem("isMobile", JSON.stringify(window.innerWidth < 970));
    };

    checkScreenWidth();
    window.scrollTo(0, 0);

    window.addEventListener("resize", checkScreenWidth);

    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);

  const items = [
    {
      title: "Welfare Scheme",
      link: "/welfare-scheme", // You'll need to convert the PDF to an HTML page or component and route it here
      description:
        "Welfare committee provides medical aid, educational aid, and pension to members. ",
    },
    {
      title: "Scholarship",
      link: "/scholarship", // Link to your mediation form page or component
      description:
        "Education committee offers scholarships for screenwriting students.",
    },
    {
      title: "Legal Aid",
      link: "/legal-aid",
      description:
        "Legal aid committee offers legal assistance within set limits and in accordance with applicable Bye-Laws.",
    },
  ];
  function WelfareCard({ item, index }) {
    return (
      <div className="serviceBox">
        <div className="service-icon">
          <span>
            <img
              src={bhavnex_DSC}
              style={{ height: "6rem" }}
              alt="welfare_img"
            />
          </span>
        </div>
        <h3 className="title">
          {/* <a href={item.link} target={item.target} className="read-more">
            {item.title}
          </a> */}
          <Link to={item.link} target={item.target} className="read-more">
            {item.title}
          </Link>
        </h3>
        <p className="description">{item.description}</p>

        {/* <a href={item.link} target={item.target} className="read-more">
            Read More
          </a> */}
      </div>
    );
  }
  const backgroundStyle = {
    width: "100%",
    height: "100%",
    backgroundImage: `url(${backgroundImage})`,
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed", // This can be 'scroll' if you want it to scroll away with the page
    backgroundSize: "cover", // Ensure that the background covers the entire element
    filter: "blur(8px)",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1,
  };
  return (
    <>
      {isMobile ? <MobileNavbar /> : <SwaNavbar />}
      {/* <div style={backgroundStyle}></div> */}

      {/* <div className="dsc-page  margin-bt"> */}
      <div className="col-md-12"></div>
      <div className="breadcumb-wrapper">
        <div className="container">
          <h1 className="title_download bold text-center mbc">
            {" "}
            Welfare{" "}
          </h1>
          <p className="mission-statement" style={{ textAlign: "justify" }}>
            SWA (Screen Writers Association) offers vital support to its members
            through various initiatives. We provide medical aid, scholarships
            for students, and legal assistance, ensuring members' well-being and
            success. This commitment reflects our dedication to fostering a
            supportive community within the industry. Through these initiatives,
            the association stands as a pillar of care and empowerment for
            screenwriters, embodying solidarity and support.
          </p>
        </div>
      </div>

      <div className="dsc-page">
        <div className="container">
          {/* First Row */}
          <div
            className="row"
            style={{ marginTop: "0.5rem", justifyContent: "end" }}
          >
            {items.map((item, index) => (
              <div key={index} className="col-md-4 col-sm-6">
                <WelfareCard item={item} />
              </div>
            ))}
          </div>
        </div>
        {/* {items.map((item, index) => (
            <Link
              to={item.link}
              className="dsc-card-link"
              key={index}
              style={{ textDecoration: "none" }}
            >
              <Card
                className="dsc-card2"
                hoverable
                style={{
                  width: "300px",
                  height: "173px", // Height is sqrt(3)/2 * width to maintain the hexagon aspect ratio
                  // backgroundColor: "#f0f2f5",
                  backgroundImage: `url(${bg})`,
                  backgroundRepeat: "round",
                  marginBottom: "20px",
                  // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  transition:
                    "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
                  clipPath:
                    "polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%)",
                  display: "flex",
                  color: "#000",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onMouseOver={(e) => {
                  const card = e.currentTarget;
                  card.style.transform = "scale(1.05)";
                  card.style.boxShadow =
                    "inset 0 4px 6px rgba(255, 255, 255, 0.6), inset 0 -4px 6px rgba(0, 0, 0, 0.2)";
                  card.style.backgroundColor = "rgb(211, 162, 33)";
                  card.style.cursor = "pointer"; // Change background color on hover
                  const textElements = card.querySelectorAll(
                    ".card-title_welfare"
                  ); // Use the correct selector for your text elements
                  textElements.forEach((el) => (el.style.color = "#000"));
                }}
                onMouseOut={(e) => {
                  const card = e.currentTarget;
                  const textElements = card.querySelectorAll(
                    ".card-title_welfare"
                  ); // Use the correct selector for your text elements
                  textElements.forEach((el) => (el.style.color = "#000"));

                  card.style.transform = "scale(1)";
                  card.style.backgroundColor = "#f0f2f5"; // Change background color on hover
                  card.style.boxShadow =
                    "inset 0 4px 6px rgba(255, 255, 255, 0.6), inset 0 -4px 6px rgba(0, 0, 0, 0.2)";
                }}
              >
                <div className="card-content">
                  <h2 className="card-title_welfare">{item.title}</h2>
                </div>
              </Card>
            </Link>
          ))} */}
      </div>
      {/* </div> */}
      <div class="mbc-container">
        <Link to="/">
          <button class="nice-blue-button scroll-button">
            {" "}
            <i
              class="fa-solid fa-house"
              style={{
                color: " #ffffff",
                marginLeft: "0rem",
                marginRight: "1rem",
              }}
            >
              {" "}
            </i>
            Back to Home
          </button>
        </Link>
      </div>
      <SwaFooter />
    </>
  );
};

export default Welfarem;
