import React, { useState, useEffect, useRef } from "react";
import {
  FaPiggyBank,
  FaHeartbeat,
  FaBookOpen,
  FaQuestionCircle,
  FaHandHoldingUsd,
  FaRegSadTear,
} from "react-icons/fa";
import "./Welfare.css"; // Make sure to create this CSS file for styling
import SwaNavbar from "../Screenwriters/SwaNavbar";
import { Link } from "react-router-dom";

import SwaFooter from "../Screenwriters/SwaFooter";
import MobileNavbar from "../Screenwriters/MobileNavbar";
function LegalAid() {
  const [isMobile, setIsMobile] = useState(() => {
    const initialValue = localStorage.getItem("isMobile");
    return initialValue ? JSON.parse(initialValue) : false;
  });
  useEffect(() => {
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth < 970);
      localStorage.setItem("isMobile", JSON.stringify(window.innerWidth < 970));
    };

    checkScreenWidth();
    window.scrollTo(0, 0);

    window.addEventListener("resize", checkScreenWidth);

    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);
  return (
    <>
      {isMobile ? <MobileNavbar /> : <SwaNavbar />}
      {/* <div className="welfare-container mb-cont"> */}
      <div className="breadcumb-wrapper">
        <div className="container">
          <h2 className="headings">Welfare Schemes and Support</h2>
        </div>
      </div>
      <div className="container" style={{textAlign:'justify'}}>
        <h3 style={{ fontWeight: "bold" }}>LEGAL AID: For members</h3>

        {/* <h3>LEGAL AID: For members</h3> */}
        <p>
          <strong>AUTHORITY:</strong> Legal Aid to the members shall be managed
          by the Legal-Aid subcommittee, subject to a maximum limit, and as per
          the applicable Bye-Laws in force as permissible at the relevant time.
        </p>
        <p>
          This scheme is for the benefit of the eligible members whose disputes
          before the Dispute Resolution Committee (DSC) require resolution via
          intervention of the court of law and shall be limited to legal
          expenses incurred on the case.
        </p>
        <p>
          This scheme is meant for eligible members (and their legal heirs, if
          deceased) only, basis the recommendation of the DSC. However, mere
          recommendation by the DSC shall not automatically determine the
          eligibility for legal aid, which shall be assessed independently by
          the Legal Aid Subcommittee upon receiving an application from an
          eligible member, basis detailed reports and its own evaluation in
          consultation with the Legal Officer and/or other legal experts in its
          panel. Once satisfied of the merits of the case, the Legal Aid
          Subcommittee shall recommend the application to the Executive
          Committee, the final authority to accept or reject the application,
          who shall basis its own evaluation, accept or reject the Legal Aid
          Subcommittee’s recommendation. No appeal or revision of Executive
          Committee’s decision of rejection of any application shall be allowed.
        </p>
        <h4>AMOUNT:</h4>
        <p>
          An amount equal to 50% of the legal expenses, or Rs. 5,00,000/-,
          whichever is lower, shall be allocated by SWA, which shall be paid
          directly to the lawyer(s) appointed on the case, after the eligible
          member has cleared his/her/their share of legal expenses in full. In
          case of failure by the eligible member to pay his/her/their dues, SWA
          reserves the right to revoke the legal aid.
        </p>
        {/* New Legal Aid Content Ends Here */}
        {/* New Scholarship Content Ends Here */}

        <p className="contact-info">
          For any further queries on welfare schemes, please contact{" "}
          <a href="mailto:contact@swaindia.org">contact@swaindia.org</a>.
        </p>
      </div>
      {/* </div> */}
      <div class="mbc-container">
        <Link to="/welfare">
          <button class="nice-blue-button scroll-button">
            {" "}
            <i
              class="fa-solid fa-house"
              style={{
                color: " #ffffff",
                marginLeft: "0rem",
                marginRight: "1rem",
              }}
            >
              {" "}
            </i>
            Back to Home
          </button>
        </Link>
      </div>
      <SwaFooter />
    </>
  );
}

export default LegalAid;
